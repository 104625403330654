import type { Config } from '../types';

const config: Config = {
  runMode: 'development',
  allowedDomains: ['ridi.io'],
  domain: 'dev.ridi.io',
  ridiComDomain: 'dev.ridi.io',

  endpoints: {
    webViewer: {
      default: 'https://view.dev.ridi.io',
    },
  },
};

export default config;
