import { createAction, createAsyncThunk, createSelector, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { hydrateAction } from '@/features/hydrate';
import type { ActionRequest, RootState } from '@/features/store';
import { SectionLayoutType, View } from '@/models/backendsApi/v2/Views/ViewsType';
import { sectionItemsResource } from '@/services/backendsApi/v2/sectionItemsResource/sectionItemsResourceService';
import { ViewsResourceRequest } from '@/services/backendsApi/v2/viewsResource/interfaces/ViewsResourceRequest';
import { viewsResource } from '@/services/backendsApi/v2/viewsResource/viewsResourceService';
import type { RequestError } from '@/utils/request';

import {
  MapSection,
  ReduceSectionResource,
  ReduceSectionResourcePayload,
  SECTION_MAPPERS,
  SECTION_RESOURCE_REDUCERS,
  SectionResource,
  ViewSectionFrameBase,
  ViewSectionItems,
} from './utils/viewDataUtils';

const generateRandomId = () => Math.random().toString(36).slice(2);

export type ViewSectionItemsExtended<T> = ViewSectionItems<T> & {
  isFetching: boolean;
  requestId?: string;
  viewSessionId: string;
};

export type ViewFrame = Omit<View, 'resources'>;

interface ViewsStateSections {
  sectionFrames: (ViewSectionFrameBase & Record<string, unknown>)[];
  sectionItems: Record<number, ViewSectionItemsExtended<unknown>>;
  sectionContents: Record<number, unknown>;
}

export interface ViewsState extends ViewsStateSections {
  view: ViewFrame | null;
}

export const initialState = {
  view: null,
  sectionFrames: [],
  sectionItems: {},
  sectionContents: {},
} as ViewsState;

export const viewsRootSelector = createSelector(
  (state: RootState) => state.genreHome,
  state => state.views,
);

export const viewSelector = createSelector(viewsRootSelector, state => state.view);
export const sectionFramesSelector = createSelector(viewsRootSelector, state => state.sectionFrames);
export const sectionItemsSelector = createSelector(viewsRootSelector, state => state.sectionItems);
export const sectionContentsSelector = createSelector(viewsRootSelector, state => state.sectionContents);

export const sectionFrameByIdSelector = createSelector(
  [sectionFramesSelector, (_state: RootState, id: number) => id],
  (frames, id) => frames.find(frame => frame.id === id),
);

export const sectionItemsByIdSelector = createSelector(
  [sectionItemsSelector, (_state: RootState, id: number) => id],
  (items, id) => items[id],
);

export const sectionContentsByIdSelector = createSelector(
  [sectionContentsSelector, (_state: RootState, id: number) => id],
  (contents, id) => contents[id],
);

export const refetchSectionItemsAction = createAction<{
  sectionId: number;
}>('genreHome/views/refetchSectionItemsAction');

export const removeSectionItemsAction = createAction<{
  sectionId: number;
}>('genreHome/views/removeSectionItemsAction');

export const reduceSectionItemsAction = createAction<{
  sectionId: number;
  resource: SectionResource;
  payload?: ReduceSectionResourcePayload;
}>('genreHome/views/reduceSectionItemsAction');

export const fetchViewsAction = createAsyncThunk<
  ViewsState,
  ActionRequest<ViewsResourceRequest>,
  {
    rejectValue: RequestError<void>;
  }
>('genreHome/views/fetchViewsAction', async ({ reqParams, req, cache }, thunkAPI) => {
  const [error, model] = await viewsResource(reqParams, req, { cache });

  if (error) {
    return thunkAPI.rejectWithValue(error);
  }

  const { resources, ...view } = model.Data.data.view;
  const sectionsState = resources
    .filter(resource => Object.values(SectionLayoutType).includes(resource.layout))
    .reduce<ViewsStateSections>(
      (state, resource) => {
        const mapSection = SECTION_MAPPERS[resource.layout] as MapSection;
        const { frame, items, contents } = mapSection(resource);

        state.sectionFrames.push(frame);
        state.sectionItems[resource.id] = { ...items, isFetching: false, viewSessionId: generateRandomId() };
        state.sectionContents[resource.id] = contents;
        return state;
      },
      { sectionFrames: [], sectionContents: {}, sectionItems: {} },
    );

  return { view, ...sectionsState };
});

export const fetchSectionItemsResourceAction = createAsyncThunk<
  { resource: SectionResource },
  ActionRequest<{ sectionId: number; endPoint: string; payload?: ReduceSectionResourcePayload }>,
  { state: RootState }
>('genreHome/views/fetchSectionItemsResourceAction', async ({ reqParams, req }, thunkAPI) => {
  const [error, model] = await sectionItemsResource(
    { body: { endpoint: reqParams.endPoint }, featureFlag: req?.FeatureFlag },
    req,
  );
  if (error) {
    return thunkAPI.rejectWithValue(error);
  }

  thunkAPI.dispatch(
    reduceSectionItemsAction({
      sectionId: reqParams.sectionId,
      resource: model.Data,
      payload: reqParams.payload,
    }),
  );

  return { resource: model.Data };
});

export const viewsSlice = createSlice({
  name: 'genreHome/views',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(hydrateAction, (state, action) => {
      const rootState = viewsRootSelector(action.payload);
      const { view, sectionFrames, sectionItems, sectionContents } = rootState;

      if (view) {
        state.view = view;
        state.sectionFrames = sectionFrames;
        state.sectionItems = sectionItems;
        state.sectionContents = sectionContents;
      }
    });

    builder.addCase(reduceSectionItemsAction, (state, action) => {
      const { sectionId, payload, resource } = action.payload;
      const targetSectionFrame = state.sectionFrames.find(({ id }) => id === sectionId);
      const targetSectionContents = state.sectionContents[sectionId];
      const targetSectionItems = state.sectionItems[sectionId];
      if (!targetSectionFrame) {
        throw new Error(`Invalid SectionId ${sectionId}`);
      }

      const targetSection = {
        frame: targetSectionFrame,
        contents: targetSectionContents,
        items: targetSectionItems,
      };

      const reduceSection = SECTION_RESOURCE_REDUCERS[targetSectionFrame.layout] as unknown as ReduceSectionResource;
      const { contents, items } = reduceSection(targetSection, resource, payload);
      state.sectionItems[sectionId].items = items.items;
      state.sectionContents[sectionId] = contents;
    });

    builder.addCase(refetchSectionItemsAction, (state, action) => {
      const { sectionId } = action.payload;
      state.sectionItems[sectionId].hasItemsToFetch = true;
      state.sectionItems[sectionId].viewSessionId = generateRandomId();
    });

    builder.addCase(removeSectionItemsAction, (state, action) => {
      const { sectionId } = action.payload;
      state.sectionItems[sectionId].items = null;
      state.sectionItems[sectionId].isFetching = false;
      state.sectionItems[sectionId].hasItemsToFetch = false;
    });

    builder.addCase(fetchViewsAction.fulfilled, (state, action) => {
      state.view = action.payload.view;
      state.sectionFrames = action.payload.sectionFrames;
      state.sectionContents = action.payload.sectionContents;
      state.sectionItems = action.payload.sectionItems;
    });

    builder
      .addCase(fetchSectionItemsResourceAction.pending, (state, action) => {
        const { sectionId } = action.meta.arg.reqParams;
        state.sectionItems[sectionId].requestId = action.meta.requestId;
        state.sectionItems[sectionId].isFetching = true;
      })
      .addMatcher(
        isAnyOf(fetchSectionItemsResourceAction.fulfilled, fetchSectionItemsResourceAction.rejected),
        (state, action) => {
          const { sectionId } = action.meta.arg.reqParams;

          if (state.sectionItems[sectionId]?.requestId === action.meta.requestId) {
            state.sectionItems[sectionId].isFetching = false;
            state.sectionItems[sectionId].hasItemsToFetch = false;
          }
        },
      );
  },
});
