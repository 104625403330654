import { AnyAction, combineReducers } from '@reduxjs/toolkit';

import { checkoutSlice, CheckoutState } from './checkout/checkoutSlice';
import { checkoutFormSlice, CheckoutFormState } from './checkoutForm/checkoutFormSlice';
import { checkoutStateSlice, CheckoutStateState } from './checkoutState/checkoutStateSlice';
import { paymentRequestSlice, PaymentRequestState } from './paymentRequest/paymentRequestSlice';
import { paymentRouteRootReducer, PaymentRouteRootState } from './paymentRoute';

export interface CheckoutRootState {
  paymentRequest: PaymentRequestState;
  paymentRoute: PaymentRouteRootState;
  checkout: CheckoutState;
  checkoutForm: CheckoutFormState;
  checkoutState: CheckoutStateState;
}

export const checkoutRootReducer = (state: CheckoutRootState | undefined, action: AnyAction): CheckoutRootState => {
  const combinedReducers = combineReducers({
    paymentRequest: paymentRequestSlice.reducer,
    paymentRoute: paymentRouteRootReducer,
    checkout: checkoutSlice.reducer,
    checkoutForm: checkoutFormSlice.reducer,
    checkoutState: checkoutStateSlice.reducer,
  });

  return combinedReducers(state, action);
};

export type CheckoutRootReducerType = ReturnType<typeof checkoutRootReducer>;
