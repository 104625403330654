import { reconstate } from '@ridi-web/reconstate';
import { useCallback, useState } from 'react';
import { useQueryClient } from 'react-query';

import { useCsrfTokenMutation } from '@/queries/globals/useCsrfToken';

const useCsrfTokenProvider = ({ initialToken }: { initialToken?: string }) => {
  const queryClient = useQueryClient();
  const csrfToken = queryClient.getQueryData<string>('globals/csrfToken') ?? initialToken ?? '';

  const [token, setToken] = useState(csrfToken);
  const csrfTokenMutation = useCsrfTokenMutation();

  const fetchCsrfToken = useCallback(async () => {
    const result = await csrfTokenMutation({});

    if (result.csrfToken) {
      setToken(result.csrfToken);
    }

    return result.csrfToken;
  }, [csrfTokenMutation]);

  return { csrfToken: token, fetchCsrfToken };
};

const draft = reconstate(useCsrfTokenProvider);
export const useCsrfToken = draft.add(({ csrfToken }) => csrfToken);
export const useFetchCsrfToken = draft.add(({ fetchCsrfToken }) => fetchCsrfToken);

export const CsrfTokenProvider = draft.finalize();
