import { AccountValidationModel } from '@/models/backendsApi/v1/AccountValidation/AccountValidationModel';
import { ServiceMethod } from '@/services/baseService';
import { getFeatureFlagValueByKey } from '@/utils/featureFlag';

import { makeCallAPIMethod } from '../../backendsApiService';
import { AccountValidationError } from './interfaces/AccountValidationError';
import { AccountValidationNameRequest } from './interfaces/AccountValidationNameRequest';

export const accountValidationName: ServiceMethod<
  AccountValidationNameRequest,
  AccountValidationModel,
  AccountValidationError
> = async (reqParams, req) => {
  const { name } = reqParams.body;
  const call = makeCallAPIMethod(
    !!getFeatureFlagValueByKey({
      featureFlag: req?.FeatureFlag || reqParams?.featureFlag,
      featureFlagKey: 'web-internal-backendsapi-request-20240710',
    }),
  )(AccountValidationModel);

  return call<AccountValidationNameRequest, AccountValidationError, AccountValidationModel>(
    {
      method: 'POST',
      pathname: '/v1/account/validation/name',
      params: {
        body: { name },
      },
      preferNonProxied: true,
    },
    req,
  );
};
