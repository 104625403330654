export enum GenreType {
  GENRE_GENERAL = 'general',
  GENRE_ROMANCE_SERIAL = 'romance_serial',
  GENRE_ROMANCE = 'romance',
  GENRE_ROMANCE_WEBTOON = 'romance_webtoon',
  GENRE_FANTASY_SERIAL = 'fantasy_serial',
  GENRE_FANTASY = 'fantasy',
  GENRE_COMIC = 'comic',
  GENRE_WEBTOON = 'webtoon',
  GENRE_BL_NOVEL = 'bl-novel',
  GENRE_BL_WEBNOVEL = 'bl-webnovel',
  GENRE_BL_COMIC = 'bl-comic',
  GENRE_BL_WEBTOON = 'bl-webtoon',
  GENRE_ROMANCE_FANTASY = 'romance_fantasy',
  GENRE_ROMANCE_FANTASY_SERIAL = 'romance_fantasy_serial',
  GENRE_COMIC_SERIAL = 'comic_serial',
  GENRE_GEUKHWA = 'geukhwa',
}

export const GenreTitleMap: Map<GenreType, string> = new Map([
  [GenreType.GENRE_BL_COMIC, 'BL 만화 e북'],
  [GenreType.GENRE_BL_NOVEL, 'BL 소설 e북'],
  [GenreType.GENRE_BL_WEBNOVEL, 'BL 웹소설'],
  [GenreType.GENRE_BL_WEBTOON, 'BL 웹툰'],
  [GenreType.GENRE_COMIC, '만화 e북'],
  [GenreType.GENRE_COMIC_SERIAL, '만화 연재'],
  [GenreType.GENRE_FANTASY, '판타지 e북'],
  [GenreType.GENRE_FANTASY_SERIAL, '판타지 웹소설'],
  [GenreType.GENRE_GENERAL, '일반도서'],
  [GenreType.GENRE_ROMANCE, '로맨스 e북'],
  [GenreType.GENRE_ROMANCE_FANTASY_SERIAL, '로판 웹소설'],
  [GenreType.GENRE_ROMANCE_FANTASY, '로판 e북'],
  [GenreType.GENRE_ROMANCE_SERIAL, '로맨스 웹소설'],
  [GenreType.GENRE_ROMANCE_WEBTOON, '로맨스 웹툰'],
  [GenreType.GENRE_WEBTOON, '웹툰'],
  [GenreType.GENRE_GEUKHWA, '극화'],
]);

export const BestSellerCategoryCode = {
  [GenreType.GENRE_GENERAL]: [
    100, 110, 200, 300, 400, 500, 600, 800, 900, 1000, 1100, 1200, 1300, 1400, 1900, 2000, 2200,
  ],
  [GenreType.GENRE_ROMANCE]: [1700, 1701, 1702, 1704, 1705, 1706, 1708],
  [GenreType.GENRE_ROMANCE_SERIAL]: [1650, 1651, 1652],
  [GenreType.GENRE_FANTASY]: [1710, 1711, 1712, 1713, 1714, 1715, 1716, 1720, 1721, 1722],
  [GenreType.GENRE_FANTASY_SERIAL]: [1750],
  [GenreType.GENRE_COMIC]: [1500],
  [GenreType.GENRE_WEBTOON]: [1600],
  [GenreType.GENRE_ROMANCE_WEBTOON]: [1613, 1609],
  [GenreType.GENRE_BL_NOVEL]: [4100, 4101, 4102, 4103, 4104],
  [GenreType.GENRE_BL_COMIC]: [4200, 4201, 4202],
  [GenreType.GENRE_BL_WEBNOVEL]: [4150, 4151, 4152, 4153],
  [GenreType.GENRE_BL_WEBTOON]: [4250],
  [GenreType.GENRE_ROMANCE_FANTASY]: [6000],
  [GenreType.GENRE_ROMANCE_FANTASY_SERIAL]: [6050],
  [GenreType.GENRE_COMIC_SERIAL]: [6100],
  [GenreType.GENRE_GEUKHWA]: [1552],
};

export const NewReleasesCategoryCode = {
  [GenreType.GENRE_GENERAL]: [
    100, 101, 102, 103, 104, 107, 110, 111, 112, 114, 115, 116, 117, 118, 119, 120, 121, 122, 123, 124, 125, 200, 210,
    220, 230, 240, 250, 300, 310, 320, 330, 340, 350, 360, 400, 410, 420, 430, 440, 500, 510, 520, 530, 600, 610, 620,
    630, 700, 710, 720, 730, 740, 750, 800, 810, 820, 1000, 1010, 1020, 1030, 1040, 1100, 1110, 1120, 1130, 1140, 1300,
    1310, 1320, 1330, 1400, 1900, 1901, 1902, 1903, 1904, 1905, 1906, 1907, 1908, 2000, 2001, 2002, 2003, 2004, 2005,
    2006, 2007, 2008, 2009, 2200, 2210, 2220, 2230, 2240, 2250,
  ],
  [GenreType.GENRE_COMIC]: [
    1500, 1509, 1513, 1515, 1517, 1519, 1521, 1522, 1523, 1525, 1527, 1531, 1535, 1551, 1552, 1537,
  ],
  [GenreType.GENRE_COMIC_SERIAL]: [6100, 6101, 6102, 6103, 6105, 6106, 6107, 6108, 6109, 6110, 6111, 6112, 6114],
  [GenreType.GENRE_WEBTOON]: [1600, 1602, 1603, 1604, 1605, 1606, 1607, 1608, 1609, 1610, 1612, 1613],
  [GenreType.GENRE_ROMANCE_SERIAL]: [1650, 1651, 1652],

  [GenreType.GENRE_ROMANCE]: [1700, 1701, 1702, 1704, 1705, 1706, 1708],
  [GenreType.GENRE_ROMANCE_WEBTOON]: [1612, 1613],
  [GenreType.GENRE_FANTASY_SERIAL]: [1750, 1751, 1752, 1753, 1754],
  [GenreType.GENRE_FANTASY]: [1710, 1711, 1712, 1713, 1714, 1715, 1716, 1721, 1722],
  [GenreType.GENRE_ROMANCE_FANTASY]: [6000, 6001, 6002, 6003, 6004],
  [GenreType.GENRE_ROMANCE_FANTASY_SERIAL]: [6050, 6051, 6052, 6053],
  [GenreType.GENRE_BL_NOVEL]: [4100, 4101, 4102, 4103, 4104],
  [GenreType.GENRE_BL_WEBNOVEL]: [4150, 4151, 4152, 4153],
  [GenreType.GENRE_BL_COMIC]: [4200, 4201, 4202],
  [GenreType.GENRE_BL_WEBTOON]: [4250, 4251],
  [GenreType.GENRE_GEUKHWA]: [1552],
};
