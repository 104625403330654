import * as Sentry from '@sentry/nextjs';

export const initSentry = (sampleRate: number): void => {
  if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
    const environment = process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT || 'local';

    Sentry.init({
      debug: environment === 'local',
      enabled: process.env.NODE_ENV === 'production',
      dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
      release: process.env.RELEASE_ID,
      maxBreadcrumbs: 30,
      environment,
      attachStacktrace: true,
      denyUrls: [
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Google translate
        /^https:\/\/translate-(.+)\.googleapis\.com/i,
      ],
      ignoreErrors: [
        'ERR_BLOCKED_BY_CLIENT',
        'fb_xd_fragment',
        'Failed to fetch',
        'NetworkError when attempting to fetch resource',
        '__naverapp__',
        '__gCrWeb.autofill.extractForms',
        'There is no clipping info for given tab', // Evernote extension error
        "Unexpected token 'else'",
        'Let/Const 다시 선언',
        'ReferenceError: Let/Const 다시 선언',
        'TypeError: 특정 호스트 이름을 가진\n서버를 찾을 수 없습니다.',
        '특정 호스트 이름을 가진\n서버를 찾을 수 없습니다.\n',
        'Unexpected identifier',
        'Unexpected end of input',
        'TypeError: WebKit에 내부 오류 발생',
        'Software caused connection abort',
        '소프트웨어에 의한 연결 중단',
        'No identifiers allowed directly after numeric literal',
        '특정 호스트 이름을 가진 서버를 찾을 수 없습니다',
        'TypeError: cancelled',
        'TypeError: 취소됨',
        'TypeError: 서버에 연결할 수 없습니다.',
        "TypeError: undefined is not an object (evaluating 'whale.pip.setup')",
        'TypeError: annulé',
        'The Internet connection appears to be offline.',
        '서버에 안전하게 연결할 수 없습니다',
        'SSL',
        'A server with the specified hostname could not be found.',
        'The network connection was lost.',
        'kCFErrorDomainCFNetwork',
        '网络连接已中断。',
        '错误的 URL',
        '已取消',
        '未能找到使用指定主机名的服务器。',
        '指定されたホスト名のサーバが見つかりませんでした。',
        '未能完成该操作。软件导致连接中止',
        '找不到使用指定主机名的服务器。', // 지정된 호스트 이름으로 서버를 찾을 수 없습니다.
        'TypeError: 似乎已断开与互联网的连接。', // 인터넷 연결이 끊어진 것 같습니다.
        'TypeError: 无法连接到服务器。', // 서버에 연결할 수 없습니다.
        'TypeError: 错误的URL', // 잘못된 URL
        'TypeError: 操作を完了できませんでした。ソフトウェアにより接続が中止されました', // 소프트웨어에 의한 연결 중단
        'TypeError: Es besteht anscheinend keine Verbindung zum Internet.',
        'TypeError: 中止', // 중
        'TypeError: скасовано', // 취소
        'TypeError: Der Vorgang konnte nicht abgeschlossen werden. Die Software hat einen Verbindungsabbruch verursacht', // 소프트웨어 연결이 끊어졌습니다.
        'Type error',
        'キャンセルしました',
        'TypeError: ネットワーク接続が切れました。', // 네트워크 연결이 끊어졌습니다.
        'TypeError: サーバに接続できませんでした。', // 서버에 연결할 수 없습니다.
        'TypeError: đã huỷ',
        'TypeError: ถูกยกเลิก',
        'TypeError: отменено',
        'supported-color-schemes',
        '请求超时。',
        'Abgebrochen',
        'Non-error was thrown: "Cancel". You should only throw errors.',
        'TypeError: Non-error was thrown: "Cancel". You should only throw errors.',
        'TypeError: anulowane',
        'Error: Request aborted',
        'cancelado',
        'AdBlock',
        'mttLongPressVar',
        'Blocked a frame with origin',
        "has no method 'checkDomStatus'",
        '이 서버에 대한 인증서가 유효하지 않습니다',
        "TypeError: null is not an object (evaluating 'document.querySelector('meta[name=\"twitter:description\"]').content')",
        "TypeError: Object [object Object] has no method 'checkLanguage'",
        "ReferenceError: Can't find variable: _sess_",
        'TypeError: a[b].target.className.indexOf is not a function.',
        "TypeError: undefined is not an object (evaluating 'a.M')", // google translate 를 사용할경우 발생하는것으로 추정
      ],
      sampleRate,
    });
  }
};
