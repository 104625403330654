import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';

import { hydrateAction } from '@/features/hydrate';
import type { ActionRequest, RootState } from '@/features/store';
import { UnreadCount } from '@/models/notificationApi/Notification/UnreadCountType';
import { unreadCount } from '@/services/notificationApi/notification/unreadCountService';

export interface NotificationState {
  unreadCount: number | null;
  isItemsLoading: boolean;
}

export const fetchUnreadCountAction = createAsyncThunk<UnreadCount, ActionRequest<void>>(
  'notification/notification/fetchUnreadCount',
  async ({ req }, thunkAPI) => {
    const [error, model] = await unreadCount(undefined, req);

    if (error) {
      return thunkAPI.rejectWithValue({
        message: error.message,
        data: error.response?.data,
      });
    }

    return model.Data;
  },
);

export const notificationSelector = (state: RootState): NotificationState => state.notification;

export const unreadCountSelector = createSelector(
  notificationSelector,
  (state: NotificationState) => state.unreadCount,
);

export const notificationSlice = createSlice({
  name: 'notification/notification',

  initialState: {
    unreadCount: null,
  } as NotificationState,

  reducers: {},

  extraReducers: builder => {
    builder.addCase(fetchUnreadCountAction.fulfilled, (state, action) => {
      state.unreadCount = action.payload.count;
    });

    builder.addCase(hydrateAction, (state, action) => {
      const nextState = {
        ...state,
        ...action.payload.notification,
      };

      if (state.unreadCount !== null && nextState.unreadCount === null) {
        nextState.unreadCount = state.unreadCount;
      }

      return nextState;
    });
  },
});

export const notificationReducer = notificationSlice.reducer;
