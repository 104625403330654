import {
  Array as RArray,
  Literal as RLiteral,
  Record as RRecord,
  Static as RStatic,
  String as RString,
  Union as RUnion,
} from 'runtypes';

const RAccountActionValidateResultSuccess = RRecord({
  success: RLiteral(true),
});

const RAccountActionValidateResultFailure = RUnion(
  RRecord({
    success: RLiteral(false),
    // id, name, email 검증 실패 시 사용되는 에러 메시지
    message: RString,
  }),
  RRecord({
    success: RLiteral(false),
    // password 검증 실패 시 사용되는 에러 메시지 배열
    messages: RArray(RString),
  }),
);

export const RAccountActionValidateResult = RUnion(
  RAccountActionValidateResultSuccess,
  RAccountActionValidateResultFailure,
);

export type AccountActionValidateResult = RStatic<typeof RAccountActionValidateResult>;
export type AccountActionValidateResultFailure = RStatic<typeof RAccountActionValidateResultFailure>;
