export const getShortRunMode = (): string => {
  switch (process.env.RUN_MODE) {
    case 'production':
      return 'prod';
    case 'stage':
      return 'stage';
    case 'development':
      return 'dev';
    default:
      return 'local';
  }
};
