import { debug } from '@/utils/debug';

import { checkoutAction, checkoutInitializeAction } from '../../checkout/checkoutSlice';
import { fetchPaymentRouteBookAction } from '../../paymentRoute/paymentRouteBook/paymentRouteBookSlice';
import { fetchPaymentRouteEpisodeAction } from '../../paymentRoute/paymentRouteEpisode/paymentRouteEpisodeSlice';
import { checkoutStateInfoSelector } from '../selectors';
import { CheckoutStateTypes } from '../types';
import { createTransitionThunk } from '../utils/createTransitionThunk';

const log = debug('fetchStartTransitionAction');
export const fetchStartTransitionAction = createTransitionThunk(
  'fetchStartTransition',
  CheckoutStateTypes.INITIAL,
  (_previousState, _args, { dispatch, getState }) => {
    const info = checkoutStateInfoSelector(getState());
    if (!info) {
      log('CheckoutInfo not given');
      return { state: CheckoutStateTypes.FAILURE, reason: null };
    }

    dispatch(checkoutInitializeAction(info));
    dispatch(checkoutAction({ reqParams: { orderType: info.orderType } }));

    if (!info.isPointback && !info.isFreePayment) {
      if (info.requestInfo.orderItemType === 'book') {
        dispatch(
          fetchPaymentRouteBookAction({
            reqParams: { ...info.requestInfo, orderType: info.orderType },
          }),
        );
      } else {
        dispatch(
          fetchPaymentRouteEpisodeAction({
            reqParams: { ...info.requestInfo, orderType: info.orderType },
          }),
        );
      }
    }

    return { state: CheckoutStateTypes.FETCH_START };
  },
);
