import { Number as RNumber, Record as RRecord, Static as RStatic, String as RString } from 'runtypes';

import { RPaymentRouteItem } from '../PaymentRouteBook/PaymentRouteBookType';

export const REpisodePaymentRouteItem = RPaymentRouteItem.And(
  RRecord({
    information: RRecord({
      csrf_token_expiration_time: RNumber,
      free_pass_coupon_available_count: RNumber,
      free_pass_coupon_available_first_book_title: RString,
      remain_book_count_for_purchase: RNumber,
    }),
  }),
);

export const RPaymentRouteGetFreePassCouponAvailableBooks = RRecord({
  message: RString,
  result: REpisodePaymentRouteItem,
});

export type PaymentRouteGetFreePassCouponAvailableBooks = RStatic<typeof RPaymentRouteGetFreePassCouponAvailableBooks>;
