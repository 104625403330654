import { CheckoutCouponType } from '@/models/backendsApi/v1/Checkout/CheckoutType';

import {
  checkoutAppliedCouponsSelector,
  checkoutBookIdsSelector,
  checkoutCSRFTokenSelector,
  checkoutOrderSelector,
  checkoutOrderTypeSelector,
  checkoutPaymentSelector,
  checkoutPointbackSelector,
  checkoutRidipointSelector,
} from '../../checkout/checkoutSlice';
import { needsOpenBookSelector } from '../../checkoutForm/checkoutFormSlice';
import { paymentRequestAction } from '../../paymentRequest/paymentRequestSlice';
import { addPurchaseFinishedBooksAction } from '../actions';
import { CheckoutStateTypes } from '../types';
import { createTransitionThunk } from '../utils/createTransitionThunk';

export const paymentRequestTransitionAction = createTransitionThunk(
  'paymentRequestTransition',
  CheckoutStateTypes.CHECKOUT,
  async (_previousState, _args, { dispatch, getState }) => {
    const initialState = getState();

    const appliedCoupons = checkoutAppliedCouponsSelector(initialState);
    const bookIds = checkoutBookIdsSelector(initialState);
    const csrfToken = checkoutCSRFTokenSelector(initialState);
    const needsOpenBook = needsOpenBookSelector(initialState);
    const order = checkoutOrderSelector(initialState);
    const orderType = checkoutOrderTypeSelector(initialState);
    const payment = checkoutPaymentSelector(initialState);
    const pointback = checkoutPointbackSelector(initialState);
    const ridipoint = checkoutRidipointSelector(initialState);
    const seriesId = order?.serial?.serial_id;

    if (!payment || !order || !ridipoint || !csrfToken) {
      return { state: CheckoutStateTypes.FAILURE, reason: null };
    }

    if (!payment.is_possible) {
      return { state: CheckoutStateTypes.FAILURE, reason: null };
    }

    const baseParams = {
      orderType,
      checkout: {
        ridipointBalance: ridipoint.balance,
        totalPrice: payment.amount,
        isPointback: !!pointback,
        coupons: {
          [CheckoutCouponType.Discount]: appliedCoupons[CheckoutCouponType.Discount]?.id ?? null,
          [CheckoutCouponType.PointGiftCard]: appliedCoupons[CheckoutCouponType.PointGiftCard]?.id ?? null,
        },
      },
      csrfToken,
    };

    const paymentResult = await dispatch(
      paymentRequestAction(
        seriesId && order.is_entire_episode
          ? { reqParams: { ...baseParams, seriesId } }
          : { reqParams: { ...baseParams, bookIds } },
      ),
    );

    if (paymentRequestAction.rejected.match(paymentResult)) {
      return { state: CheckoutStateTypes.FAILURE, reason: null };
    }

    const { payload } = paymentResult;
    if (!payload.paymentFinished) {
      return null;
    }

    const purchasedBooks = order.items.map(item => ({
      bookId: item.book_id,
      isFreeBook: item.purchase?.sale_price === 0 || item.rental?.sale_price === 0,
      setBookId: item.set?.set_id ?? null,
    }));

    dispatch(addPurchaseFinishedBooksAction(purchasedBooks));

    return {
      state: CheckoutStateTypes.SUCCESS,
      message: '결제가 완료되었습니다.',
      isWaitFree: false,
      needsOpenBook: needsOpenBook.defaultByApp || needsOpenBook[orderType],
      eventType: orderType,
      transactionId: payload.data.t_id,
    };
  },
);
