import { Navigation } from '@/models/backendsApi/v2/Navigation/NavigationType';

export const iterateAllNavigations = (rootNavigation: Navigation): Navigation[] => [
  rootNavigation,
  ...rootNavigation.children.flatMap(iterateAllNavigations),
];

export const findCurrentNavigationRoute = (findingPath: string, rootNavigation: Navigation): Navigation[] | null => {
  const parentByNavigation = new Map<Navigation, Navigation>();
  const allNavigations = iterateAllNavigations(rootNavigation);
  allNavigations.forEach(navigation => navigation.children.forEach(child => parentByNavigation.set(child, navigation)));

  let currentNavigation = allNavigations.find(navigation => navigation.path === findingPath);
  const currentNavigationRoute: Navigation[] = [];
  while (currentNavigation) {
    currentNavigationRoute.push(currentNavigation);
    currentNavigation = parentByNavigation.get(currentNavigation);
  }

  return currentNavigationRoute.length > 0 ? currentNavigationRoute.reverse() : null;
};

export const findCurrentGlobalNavigation = (
  findingPath: string,
  globalNavigations: Navigation[] = [],
): Navigation | null =>
  globalNavigations.find(navigation => navigation.children.find(child => findingPath.startsWith(child.path))) || null;

export const findLeafNavigation = (currentNavigation: Navigation, visitedNavigationIds: Set<number>): Navigation => {
  if (currentNavigation.children.length === 0) {
    return currentNavigation;
  }

  const lastVisitedNavigation = currentNavigation.children.find(navigation => visitedNavigationIds.has(navigation.id));
  if (lastVisitedNavigation) {
    return findLeafNavigation(lastVisitedNavigation, visitedNavigationIds);
  }

  const defaultNavigation = currentNavigation.children.find(navigation => navigation.is_default);
  if (defaultNavigation) {
    return findLeafNavigation(defaultNavigation, visitedNavigationIds);
  }

  return findLeafNavigation(currentNavigation.children[0], visitedNavigationIds);
};

export const addToVisitedNavigationIds = (
  visitedNavigationIds: Set<number>,
  currentNavigation: Navigation[],
): Set<number> => {
  const newVisitedNavigationIds = new Set(visitedNavigationIds);

  currentNavigation.forEach(navigation => {
    navigation.children.forEach(childNavigation => {
      newVisitedNavigationIds.delete(childNavigation.id);
    });
  });

  currentNavigation.forEach(navigation => {
    newVisitedNavigationIds.add(navigation.id);
  });

  return newVisitedNavigationIds;
};

/** getNonExternalNavigationSubset
 * @desc children이 없고, resource_url이 null인 내비게이션을 제거한다
 *
 * 셀렉트와 같은 경우 내비게이션 바에서 따로 처리하고 있기 때문에 Navigation API 응답에서 제거한다
 * path가 select.ridibooks.com 으로 내려온다면 이를 그대로 사용할 수 있었겠으나,
 * DB 구조상 이것이 Hacky한 방법을 사용하지 않고는 불가능한 상황
 * 또한 DB 내에 호스트와 같은 바뀌었을 때 트래킹하기 어려운 값을 넣는 것도 피하고자 함
 *
 * Navigation API에서 셀렉트를 내려줘야 하는 이유는, 완전판 앱이 웹의 내비게이션을 참조하고 있기 때문
 */
export const getNonExternalNavigationSubset = (rootNavigation: Navigation): Navigation => {
  const iterate = (navigation: Navigation): Navigation | null => {
    const newNavigation = { ...navigation };
    newNavigation.children = newNavigation.children
      .map(iterate)
      .filter((child: Navigation | null): child is Navigation => !!child);

    if (newNavigation.children.length === 0 && newNavigation.resource_url === null) {
      return null;
    }

    return newNavigation;
  };

  const newRootNavigation = { ...rootNavigation };
  newRootNavigation.children = newRootNavigation.children
    .map(iterate)
    .filter((child: Navigation | null): child is Navigation => !!child);

  return newRootNavigation;
};

// Visited Navigation Encoding:
// > Varint와 같은 바이너리 인코딩까지 가지 않는 선에서 적당히 Set<number>의 serialize/deserialize를 구현
//
// 1. number[]를 오름차순 정렬 후에 delta로 맵핑
// 2. 36진수로 전환 후 '.'으로 join해서 인코딩

export const encodeVisitedNavigationIds = (visitedNavigationIds: Set<number>): string =>
  Array.from(visitedNavigationIds)
    .sort((a, b) => a - b)
    .reduce<{ previous: number; array: number[] }>(
      ({ previous, array }, current) => ({ previous: current, array: [...array, current - previous] }),
      { previous: 0, array: [] },
    )
    .array.map(value => value.toString(36))
    .join('.');

export const decodeVisitedNavigationIds = (encodedVisitedNavigationIds: string): Set<number> =>
  new Set(
    encodedVisitedNavigationIds
      .split('.')
      .map(char => parseInt(char, 36))
      .filter(number => !Number.isNaN(number))
      .reduce<{ previous: number; array: number[] }>(
        ({ previous, array }, current) => ({ previous: current + previous, array: [...array, current + previous] }),
        { previous: 0, array: [] },
      ).array,
  );
