import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { User } from '@/base/interfaces/User';
import { hydrateAction } from '@/features/hydrate';
import type { ActionRequest, AppDispatch, RootState } from '@/features/store';
import { accountsMe } from '@/services/account/accountsMe/accountsMeService';

import { hydrateAuthVariablesAction, hydrateTemporaryAuthAction } from '../actions';

export interface AuthState {
  loggedUser: User | null;
}

export const authSelector = (state: RootState): AuthState => state.global.auth;
export const loggedUserSelector = (state: RootState): User | null => authSelector(state).loggedUser;

export const setLoggedUserAction = createAction<{ loggedUser: User | null }>('global/auth/setLoggedUser');
export const refreshAuthAction = createAsyncThunk<
  User | null,
  ActionRequest<void>,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>('global/auth/refreshAuthAction', async ({ req }) => {
  const [accountsMeError, accountsMeModel] = await accountsMe(undefined, req);

  if (accountsMeError) {
    return null;
  }

  const user = accountsMeModel.Data.result;
  return {
    id: user.id,
    idx: user.idx,
    email: user.email,
    isVerifiedAdult: user.is_verified_adult,
    adultVerification: user.adult_verification,
  };
});

export const authSlice = createSlice({
  name: 'global/auth',
  initialState: {
    loggedUser: null,
  } as AuthState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(setLoggedUserAction, (state, action) => {
      state.loggedUser = action.payload.loggedUser;
    });

    builder.addCase(hydrateAction, (state, action) => {
      // 유저의 로그인 여부에 대한 Single Source of Truth는 쿠키이며,
      // 서버 사이드에서 체크한 쿠키의 값이 최신의 값이기 때문에 클라이언트 사이드의 값을 덮어 쓴다.

      state.loggedUser = action.payload.global.auth.loggedUser;
    });

    builder.addCase(hydrateAuthVariablesAction.fulfilled, (state, action) => {
      state.loggedUser = action.payload.loggedUser;
    });

    builder.addCase(hydrateTemporaryAuthAction, (state, action) => {
      state.loggedUser = action.payload.loggedUser;
    });

    builder.addCase(refreshAuthAction.fulfilled, (state, action) => {
      state.loggedUser = action.payload;
    });
  },
});
