import {
  Array as RArray,
  Boolean as RBoolean,
  Literal as RLiteral,
  Number as RNumber,
  Optional as ROptional,
  Partial as RPartial,
  Record as RRecord,
  Static as RStatic,
  String as RString,
  Union as RUnion,
} from 'runtypes';

import { RAuthorRole } from '@/base/interfaces/Author';

export const RSearchAuthor = RRecord({
  id: RNumber,
  name: RString,
  book_count: RNumber,
  popular_book_title: RString,
  highlight: RPartial({
    name: RString,
  }),
});
export type SearchAuthor = RStatic<typeof RSearchAuthor>;

export const RSearchAuthorResult = RRecord({
  authors: RArray(RSearchAuthor),
  total: ROptional(RNumber),
});
export type SearchAuthorResult = RStatic<typeof RSearchAuthorResult>;

export const RSearchBookAggregation = RRecord({
  category_id: RNumber,
  category_name: RString,
  doc_count: RNumber,
});
export type SearchBookAggregation = RStatic<typeof RSearchBookAggregation>;

export const RSearchBookAuthor = RRecord({
  author_id: RNumber,
  name: RString,
  role: RAuthorRole,
  order: RNumber,
  native_name: ROptional(RString),
  alias_name: ROptional(RString),
});
export type SearchBookAuthor = RStatic<typeof RSearchBookAuthor>;

export const RSearchBookEvent = RRecord({
  event_id: RNumber,
  event_name: RString,
  event_subgroup_id: RNumber,
  event_subgroup_name: RString,
});
export type SearchBookEvent = RStatic<typeof RSearchBookEvent>;

export const RSearchBookTag = RRecord({
  tag_id: RNumber,
  tag_name: RString,
});
export type SearchBookTag = RStatic<typeof RSearchBookTag>;

export const RSearchBookPriceType = RUnion(RLiteral('normal'), RLiteral('rent'));
export type SearchBookPriceType = RStatic<typeof RSearchBookPriceType>;

export const RSearchBookPrice = RRecord({
  price: RNumber,
  arg: RNumber,
  type: RSearchBookPriceType,
});
export type SearchBookPrice = RStatic<typeof RSearchBookPrice>;

export const RSearchBookSeriesPrice = RRecord({
  series_id: RString,
  type: RSearchBookPriceType,
  book_count: RNumber,
  free_book_count: RNumber,
  min_nonzero_price: RNumber,
  min_price: RNumber,
  regular_price: RNumber,
  max_price: RNumber,
  total_price: RNumber,
  total_regular_price: RNumber,
  series_discount_rate: RNumber,
  arg: RNumber,
  series_arg: RNumber,
});
export type SearchBookSeriesPrice = RStatic<typeof RSearchBookSeriesPrice>;

export const RSearchBookHighlight = RPartial({
  web_title_title: RString,
  sub_title: RString,
  author: RString,
  author2: RString,
  translator: RString,
  publisher: RString,
});
export type SearchBookHighlight = RStatic<typeof RSearchBookHighlight>;

export const RSearchBook = RRecord({
  b_id: RString,

  // Title
  title: RString,
  web_title: RString,
  web_title_title: RString,
  sub_title: RString,
  desc: RString,

  // Author
  authors_info: RArray(RSearchBookAuthor),
  author: RString,
  author2: RString,
  translator: RString,
  publisher: RString,

  // Price
  price: RNumber,
  prices_info: RArray(RSearchBookPrice),
  series_prices_info: RArray(RSearchBookSeriesPrice),

  // Category
  category: RNumber,
  category2: RNumber,
  category_name: ROptional(RString.nullable()),
  category_name2: ROptional(RString.nullable()),
  parent_category: RNumber,
  parent_category2: RNumber,
  parent_category_name: ROptional(RString.nullable()),
  parent_category_name2: ROptional(RString.nullable()),

  // Count
  book_count: RNumber,
  setbook_count: RNumber,

  // Properties
  age_limit: RNumber,
  is_setbook: RBoolean,
  is_serial: RNumber,
  is_series_complete: RBoolean,
  is_select: RBoolean,
  is_rental: RBoolean,
  is_wait_free: RBoolean,

  // Reviews
  buyer_review_count: RNumber,
  buyer_rating_count: RNumber,
  buyer_rating_score: RNumber,

  // Other Metadata
  tags_info: RArray(RSearchBookTag),
  event_info: ROptional(RArray(RSearchBookEvent)),
  opened_last_volume_id: RString,

  // Search Result
  highlight: RSearchBookHighlight,
});
export type SearchBook = RStatic<typeof RSearchBook>;

export const RSearchBookResult = RRecord({
  books: RArray(RSearchBook),
  total: ROptional(RNumber),
  aggregations: ROptional(RArray(RSearchBookAggregation)),
});
export type SearchBookResult = RStatic<typeof RSearchBookResult>;

export const RSearchResult = RRecord({
  book: RSearchBookResult,
  author: RSearchAuthorResult,
});
export type SearchResult = RStatic<typeof RSearchResult>;

export const RSearch = RUnion(RSearchResult, RSearchBookResult, RSearchAuthorResult);
export type Search = RStatic<typeof RSearch>;
