import { combineReducers } from '@reduxjs/toolkit';

import { viewsSlice, ViewsState } from './views/viewsSlice';

export interface GenreHomeState {
  views: ViewsState;
}

export const genreHomeReducer = combineReducers({
  views: viewsSlice.reducer,
});

export type GenreHomeReducerType = ReturnType<typeof genreHomeReducer>;
