import { AnyAction, combineReducers } from '@reduxjs/toolkit';

import { accountReducer, AccountState } from './account';
import { cartReducer, CartState } from './cart';
import { genreHomeReducer, GenreHomeState } from './genreHome';
import { globalReducer, GlobalState } from './global';
import { groupTabReducer, GroupTabState } from './groupTab';
import { inappRootReducer, InAppRootState } from './inapp';
import { newReleasesReducer, NewReleasesState } from './newReleases';
import { notificationReducer, NotificationState } from './notification';
import { searchReducer, SearchState } from './search';

export interface State {
  genreHome: GenreHomeState;
  global: GlobalState;
  account: AccountState;
  newReleases: NewReleasesState;
  notification: NotificationState;
  search: SearchState;
  inapp: InAppRootState;
  cart: CartState;
  groupTab: GroupTabState;
}

export const rootReducer = (state: State | undefined, action: AnyAction): State => {
  const combinedReducers = combineReducers({
    genreHome: genreHomeReducer,
    global: globalReducer,
    account: accountReducer,
    newReleases: newReleasesReducer,
    notification: notificationReducer,
    search: searchReducer,
    inapp: inappRootReducer,
    cart: cartReducer,
    groupTab: groupTabReducer,
  });

  return combinedReducers(state, action);
};

export type ReducerType = ReturnType<typeof rootReducer>;
