import {
  Boolean as RBoolean,
  Number as RNumber,
  Record as RRecord,
  Static as RStatic,
  String as RString,
} from 'runtypes';

import { RAccountsMeAdultVerification } from '@/models/account/AccountsMe/AccountsMeType';

export const RUser = RRecord({
  id: RString,
  idx: RNumber,
  email: RString,
  isVerifiedAdult: RBoolean,
  adultVerification: RAccountsMeAdultVerification,
});

export type User = RStatic<typeof RUser>;
