import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import type { State } from '@/features/rootReducer';
import type { ActionRequest } from '@/features/store';
import type { Checkout, OrderType } from '@/models/backendsApi/v1/Checkout/CheckoutType';
import { checkout, CheckoutError } from '@/services/backendsApi/v1/checkout/checkoutService';

import type { CheckoutRequestInfo } from './checkoutSlice';
import { checkoutCouponSelector } from './selectors';

/**
 * checkout api를 최초로 호출하기 전에 사용하는 action
 */
export const checkoutInitializeAction = createAction<{
  orderType: OrderType;
  requestInfo: CheckoutRequestInfo;
}>('inapp/checkout/checkout/checkoutInitializeAction');

/**
 * checkout api 호출 없이 캐싱된 다른 order type으로 바꿀 때 사용하는 액션
 */
const checkoutSetOrderTypeAction = createAction<{
  orderType: OrderType;
}>('inapp/checkout/checkout/checkoutSetOrderTypeAction');

/**
 * checkout api 호출 없이 이미 요청이 진행중인 다른 order type을
 * 유저가 보고 싶어하는 order type으로 설정할 때 사용하는 액션
 */
const checkoutSetDesiredOrderTypeAction = createAction<{
  orderType: OrderType;
}>('inapp/checkout/checkout/checkoutSetDesiredOrderTypeAction');

/**
 * checkout api가 호출될 때 요청 정보를 관리하기 위해 사용되는 액션
 */
const checkoutStartLoadingAction = createAction<{
  orderType: OrderType;
  timestamp: number;
}>('inapp/checkout/checkout/checkoutStartLoadingAction');

/**
 * checkout api로 불러온 정보가 특정 시점 이전이라면 전부 지우는 액션
 * checkoutRefreshAction에서 사용한다
 */
const checkoutInvalidateAction = createAction<{
  before: number;
}>('inapp/checkout/checkout/checkoutInvalidateAction');

/**
 * checkoutRefreshAction을 호출하기 전에 스피너를 돌리기 위한 액션
 */
export const checkoutRefreshStartAction = createAction<void>('inapp/checkout/checkout/checkoutRefreshStartAction');

/**
 * checkoutRefreshAction이 끝났을 때 호출하는 액션
 * 새로고침할 필요가 없다면 checkoutRefreshAction 호출하는 대신에 이 액션만 호출하면 된다.
 */
export const checkoutRefreshFinishAction = createAction<void>('inapp/checkout/checkout/checkoutRefreshFinishAction');

/**
 * checkoutInitializeAction 이후 checkout api를 호출할 때 사용하는 action creator
 * checkoutInitializeAction에서 저장한 book id를 그대로 사용한다
 */
export const checkoutAction = createAsyncThunk<
  { timestamp: number; orderType: OrderType; data: Checkout },
  ActionRequest<{ orderType: OrderType; couponIds?: string[] }>,
  {
    state: State;
    rejectValue: { timestamp: number; orderType: OrderType; data: CheckoutError | undefined; isLoginFailure: boolean };
  }
>(
  'inapp/checkout/checkout/checkoutAction',
  async ({ reqParams: { orderType, couponIds }, req }, { getState, dispatch, rejectWithValue }) => {
    const timestamp = Date.now();
    dispatch(checkoutStartLoadingAction({ timestamp, orderType }));

    const { requestInfo } = getState().inapp.checkout.checkout;
    if (!requestInfo) {
      return rejectWithValue({
        timestamp,
        orderType,
        isLoginFailure: false,
        data: undefined,
      });
    }

    const bodyBase = {
      order_type: orderType,
      coupon_ids: couponIds,
    };

    const body =
      requestInfo.orderItemType === 'book'
        ? { ...bodyBase, order_item_type: requestInfo.orderItemType, book_ids: requestInfo.bookIds }
        : {
            ...bodyBase,
            ...(requestInfo.episodeIds ? { episode_ids: requestInfo.episodeIds } : null),
            ...(requestInfo.excludedEpisodeIds ? { excluded_episode_ids: requestInfo.excludedEpisodeIds } : null),
            exclude_owned_episodes: requestInfo.excludeOwnedEpisodes,
            order_item_type: requestInfo.orderItemType,
            serial_id: requestInfo.serialId,
          };

    const [error, model] = await checkout({ body }, req);

    if (error) {
      return rejectWithValue({
        timestamp,
        orderType,
        isLoginFailure: error.response?.status === 401,
        data: error.response?.data,
      });
    }

    return {
      timestamp,
      orderType,
      data: model.Data,
    };
  },
);

/**
 * 주문 타입 바꾸는 action
 */
export const applyOrderTypeAction = createAsyncThunk<void, ActionRequest<{ orderType: OrderType }>, { state: State }>(
  'inapp/checkout/checkout/applyOrderTypeAction',
  async ({ reqParams: { orderType }, req }, { getState, dispatch, rejectWithValue }) => {
    const checkoutState = getState().inapp.checkout.checkout;

    // 캐싱된 결과가 있을 시, 다시 요청하지 않고 orderType만 바꾼다
    if (checkoutState[orderType] !== null) {
      dispatch(checkoutSetOrderTypeAction({ orderType }));
      return;
    }

    // 이미 로딩이 되고 있는 탭으로 바꿀 시, 다시 요청하지 않고 desiredOrderType만 바꾼다
    if (checkoutState.requestStatus[orderType].isLoading) {
      dispatch(checkoutSetDesiredOrderTypeAction({ orderType }));
      return;
    }

    const result = await dispatch(
      checkoutAction({
        reqParams: {
          orderType,
        },
        req,
      }),
    );

    if (checkoutAction.rejected.match(result)) {
      rejectWithValue(result.payload);
    }
  },
);

/**
 * 쿠폰 적용 action creator
 */
export const applyCouponAction = createAsyncThunk<void, ActionRequest<{ couponIds: string[] }>, { state: State }>(
  'inapp/checkout/checkout/applyCouponAction',
  async ({ reqParams: { couponIds }, req }, { getState, dispatch, rejectWithValue }) => {
    const { orderType } = getState().inapp.checkout.checkout;

    const result = await dispatch(
      checkoutAction({
        reqParams: {
          orderType,
          couponIds,
        },
        req,
      }),
    );

    if (checkoutAction.rejected.match(result)) {
      rejectWithValue(result.payload);
    }
  },
);

/**
 * 체크아웃 데이터가 리디캐시 충전 등의 이유로 바뀌었을 때 새로고침 하는 action creator
 *
 * 주의! applyCouponAction 과 레이스가 일어나 이전 쿠폰 적용값으로 초기화될 수 있음
 *       그러나, 이 액션이 호출될 때는 isLoading이 끝나고 CTA 버튼이 뜨고 나서이므로 괜찮을 듯..?
 *
 * 주의! 중간에 orderType이 바뀌면 Invalidation이 제대로 되지 않음
 *       그러나, 이 액션이 호출될 때에는 orderType 탭을 스피너가 막고 있을 것이므로 괜찮을 듯...?
 */
export const checkoutRefreshAction = createAsyncThunk<void, ActionRequest<void>, { state: State }>(
  'inapp/checkout/checkout/checkoutRefreshAction',
  async ({ req }, { getState, dispatch, rejectWithValue }) => {
    const timestamp = Date.now();
    const {
      orderType,
      requestStatus: { desiredOrderType },
    } = getState().inapp.checkout.checkout;

    let couponIds: string[] | undefined;

    // 다른 탭을 로딩 중에 있다면 선택한 쿠폰이 없기 때문에 패스
    if (orderType === desiredOrderType) {
      // coupon 데이터 자체가 없는 경우에는 undefined를 받아야 하기 때문에 checkoutAllCouponsSelector를 사용해서는 안됨
      couponIds = checkoutCouponSelector(getState())
        ?.coupons.filter(coupon => coupon.is_applied)
        .map(coupon => coupon.id);
    }

    const result = await dispatch(
      checkoutAction({
        reqParams: {
          orderType: desiredOrderType,
          couponIds,
        },
        req,
      }),
    );

    // 바텀시트 높이를 유지하기 위해서 부득이하게 Invalidation을 Fetch 이후에 행함
    dispatch(checkoutInvalidateAction({ before: timestamp }));

    if (checkoutAction.rejected.match(result)) {
      rejectWithValue(result.payload);
    }

    dispatch(checkoutRefreshFinishAction());
  },
);

/**
 * episode 형식의 결제일 때 몇몇 선결제된 책들을 제거하는 action creator
 *
 * 주의! 이 액션은 checkout에 진입하기 전까지만 사용해야함.
 * 이 후에는 orderType이 바뀌었을 때 이미 진행중인 요청이 있을 시 데이터 레이스 발생 가능
 */
export const checkoutExcludeEpisodesAction = createAsyncThunk<
  void,
  ActionRequest<{ excludingEpisodeIds: string[] }>,
  { state: State }
>(
  'inapp/checkout/checkout/checkoutExcludeEpisodesAction',
  async ({ reqParams: { excludingEpisodeIds }, req }, { getState, dispatch, rejectWithValue }) => {
    const timestamp = Date.now();
    const {
      requestStatus: { desiredOrderType },
      requestInfo,
    } = getState().inapp.checkout.checkout;

    if (requestInfo?.orderItemType !== 'episode') {
      return;
    }

    const newRequestInfo = { ...requestInfo };
    if (requestInfo.episodeIds) {
      // 화이트리스트 결제일 때에는 결제할 에피소드 중 제외되는 에피소드 제거
      const excludingSet = new Set(excludingEpisodeIds);
      newRequestInfo.episodeIds = requestInfo.episodeIds.filter(episodeId => !excludingSet.has(episodeId));
    } else {
      // 블랙리스트 결제일 때에는 결제하지 않을 에피소드에 추가
      const newExcludingSet = new Set([...excludingEpisodeIds, ...(requestInfo.excludedEpisodeIds ?? [])]);
      newRequestInfo.excludedEpisodeIds = Array.from(newExcludingSet);
    }

    dispatch(checkoutInitializeAction({ orderType: desiredOrderType, requestInfo: newRequestInfo }));
    dispatch(checkoutInvalidateAction({ before: timestamp }));

    const result = await dispatch(
      checkoutAction({
        reqParams: {
          orderType: desiredOrderType,
        },
        req,
      }),
    );

    if (checkoutAction.rejected.match(result)) {
      rejectWithValue(result.payload);
    }
  },
);

export const checkoutInternalActions = {
  checkoutSetOrderTypeAction,
  checkoutSetDesiredOrderTypeAction,
  checkoutStartLoadingAction,
  checkoutInvalidateAction,
};
