import { PaymentRouteBookModel } from '@/models/booksBackend/PaymentRouteBook/PaymentRouteBookModel';
import { ServiceMethod } from '@/services/baseService';
import { getFeatureFlagValueByKey } from '@/utils/featureFlag';

import { makeCallAPIMethod } from '../booksBackendService';
import { PaymentRouteBookRequest } from './interfaces/PaymentRouteBookRequest';

export interface PaymentRouteBookError {
  code: string;
  message: string;
}

export const paymentRouteBook: ServiceMethod<
  PaymentRouteBookRequest,
  PaymentRouteBookModel,
  PaymentRouteBookError
> = async (reqParams, req) => {
  const call = makeCallAPIMethod(
    !!getFeatureFlagValueByKey({
      featureFlag: req?.FeatureFlag || reqParams.featureFlag,
      featureFlagKey: 'web-internal-books-backendsapi-request-20240813',
    }),
  )(PaymentRouteBookModel);
  return call<void, PaymentRouteBookError, PaymentRouteBookModel>(
    {
      method: 'GET',
      pathname: '/api/payment/route/book',
      params: { query: reqParams.query },
      preferNonProxied: true,
    },
    req,
  );
};
