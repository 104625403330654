import type { Config } from '../types';

const config: Config = {
  runMode: 'production',
  allowedDomains: ['ridi.com', 'ridibooks.com'],
  domain: 'ridibooks.com',
  ridiComDomain: 'ridi.com',

  endpoints: {
    webViewer: {
      default: 'https://view.ridibooks.com',
    },
  },
};

export default config;
