import { jsx as c, Fragment as p } from "react/jsx-runtime";
import { createContext as f, useContext as v, useMemo as x } from "react";
const w = (a) => {
  const r = [], n = {
    add(t) {
      const e = f(null);
      return r.push([e.Provider, t]), () => v(e);
    },
    finalize() {
      const t = ({ children: e, ...d }) => {
        const s = a(d), l = x(() => r.map(([, o]) => o(s)), [s]), u = r.reduce(
          (o, [i], m) => /* @__PURE__ */ c(i, { value: l[m], children: o }),
          e
        );
        return /* @__PURE__ */ c(p, { children: u });
      };
      return n.add = () => {
        throw new Error("Cannot add selector after close");
      }, t;
    }
  };
  return n;
};
export {
  w as reconstate
};
