import { AuthVariablesModel } from '@/models/global/AuthVariables/AuthVariablesModel';
import { ServiceMethod } from '@/services/baseService';

import { makeCallAPIMethod } from '../globalService';

const call = makeCallAPIMethod(AuthVariablesModel);
export const authVariables: ServiceMethod<void, AuthVariablesModel, void> = async (_, req) =>
  call<void, void, AuthVariablesModel>(
    {
      method: 'GET',
      pathname: '/auth-variables',
      // bfcache 방지
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
      },
    },
    req,
  );
