import { AnyAction, combineReducers } from '@reduxjs/toolkit';

import { authSlice, AuthState } from './auth/authSlice';
import { featureFlagSlice, FeatureFlagState } from './featureFlag/featureFlagSlice';
import { globalNavigationBarReducer, GlobalNavigationBarState } from './globalNavigationBar';
import { isHydrateNeededSlice } from './isHydrateNeeded';
import { toastSlice, ToastState } from './toast/toastSlice';
import { variablesSlice, VariablesState } from './variables/variablesSlice';

export interface GlobalState {
  featureFlag: FeatureFlagState;
  globalNavigationBar: GlobalNavigationBarState;
  variables: VariablesState;
  toast: ToastState;
  auth: AuthState;
  isHydrateNeeded: boolean;
}

export const globalReducer = (state: GlobalState | undefined, action: AnyAction): GlobalState => {
  const combinedReducers = combineReducers({
    featureFlag: featureFlagSlice.reducer,
    globalNavigationBar: globalNavigationBarReducer,
    variables: variablesSlice.reducer,
    toast: toastSlice.reducer,
    auth: authSlice.reducer,
    isHydrateNeeded: isHydrateNeededSlice.reducer,
  });

  return combinedReducers(state, action);
};

export type GlobalReducerType = ReturnType<typeof globalReducer>;
