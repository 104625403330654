import { css } from '@emotion/react';

import expRidiRobotoBoldItalicWoff2 from '@/assets/fonts/exp-ridi-roboto-bold-italic-webfont.woff2';
import ridiRobotoBoldTtf from '@/assets/fonts/ridi-roboto-bold.ttf';
import ridiRobotoBoldWoff from '@/assets/fonts/ridi-roboto-bold-webfont.woff';
import ridiRobotoBoldWoff2 from '@/assets/fonts/ridi-roboto-bold-webfont.woff2';
import ridiRobotoMediumWoff2 from '@/assets/fonts/ridi-roboto-medium-webfont.woff2';
import ridiRobotoRegularTtf from '@/assets/fonts/ridi-roboto-regular.ttf';
import ridiRobotoRegularWoff from '@/assets/fonts/ridi-roboto-regular-webfont.woff';
import ridiRobotoRegularWoff2 from '@/assets/fonts/ridi-roboto-regular-webfont.woff2';
import ridiRobotoSemiboldWoff2 from '@/assets/fonts/ridi-roboto-semibold-webfont.woff2';

export const fonts = css`
  @font-face {
    font-family: 'ridi-roboto';
    src:
      url(${ridiRobotoRegularWoff2}) format('woff2'),
      url(${ridiRobotoRegularWoff}) format('woff'),
      url(${ridiRobotoRegularTtf}) format('truetype');
    font-display: swap;
    font-weight: normal;
  }

  @font-face {
    font-family: 'ridi-roboto';
    src: url(${ridiRobotoMediumWoff2}) format('woff2');
    font-display: swap;
    font-weight: 500;
  }

  @font-face {
    font-family: 'ridi-roboto';
    src: url(${ridiRobotoSemiboldWoff2}) format('woff2');
    font-display: swap;
    font-weight: 600;
  }

  @font-face {
    font-family: 'ridi-roboto';
    src:
      url(${ridiRobotoBoldWoff2}) format('woff2'),
      url(${ridiRobotoBoldWoff}) format('woff'),
      url(${ridiRobotoBoldTtf}) format('truetype');
    font-display: swap;
    font-weight: bold;
  }

  @font-face {
    font-family: 'exp-ridi-roboto-bold-italic';
    src: url(${expRidiRobotoBoldItalicWoff2}) format('woff2');
    font-display: swap;
    font-weight: bold;
  }
`;
