import {
  ContentItem,
  createDefaultPresetClient,
  DeviceTypeHint,
  getDeviceTypeHintByUserAgent,
  PurchaseInfo,
} from '@ridi-web/tracking';

import { InAppBridge } from '../inappBridge';

export type EventActionType = 'select' | 'view' | 'submit';

export type EventParamsValueType =
  | string
  | number
  | boolean
  | null
  | undefined
  | EventParamsValueType[]
  | { [key: string]: EventParamsValueType };

export type EventParamsType = {
  [key: string]: EventParamsValueType;
};

export type EventItem = ContentItem;

export type EventTrackingData = {
  screenName: string;
  extraParams: EventParamsType;
};

export interface EventPurchaseItem extends EventItem {
  item_parent_category: number;
  price: number;
  value: number;
}

export interface EventPurchaseInfoType extends PurchaseInfo {
  readonly items: EventPurchaseItem[];
}

export interface EventInAppPageViewParam {
  userIdx?: number;
  location?: string;
  referrer?: string;
}

export interface EventClientType {
  initialize: () => void;
  isInitialized: () => boolean;
  getUId?: () => number | undefined;
  sendCustomEvent: (screenName: string, action: EventActionType, target: string, params: EventParamsType) => void;
  sendPurchase?: (transactionId: string, purchaseInfo: EventPurchaseInfoType) => void;
  sendPurchaseChargeAndPay?: (
    transactionId: string,
    purchaseInfo: EventPurchaseInfoType & {
      charge_value: number;
      charge_t_id: string;
      pay_value: number;
      pay_t_id: string;
    },
  ) => void;
  sendBeginCheckout?: (items: ContentItem[]) => void;
  sendLogin?: () => void;
  sendSignUp?: () => void;
  setFfid?: (ffid?: string) => void;
  setUId?: (uIdx?: number | null) => void;
  sendScrollEvent?: (screenName: string, target: string, params?: EventParamsType) => void;
  sendSignupInfoCompleted?: () => void;
  sendInAppPageView?: ({ userIdx, location, referrer }: EventInAppPageViewParam) => void;
  sendBrazeCustomEvent?: (eventName: string) => void;
}

let eventClient: EventClientType | null = null;

export const initializeEventClient = (client?: EventClientType): void => {
  if (typeof window === 'undefined') {
    return;
  }

  const newClient =
    client ??
    createDefaultPresetClient({
      deviceTypeHint: (() => {
        if (typeof window === 'undefined') {
          return DeviceTypeHint.PC;
        }

        if (InAppBridge.isInApp) {
          return DeviceTypeHint.APP;
        }

        return getDeviceTypeHintByUserAgent(window.navigator.userAgent) ?? DeviceTypeHint.PC;
      })(),
      inAppBridge: InAppBridge,
      trackingId: process.env.NEXT_PUBLIC_GTM_TRACKING_ID,
      debug: process.env.NODE_ENV === 'development' || process.env.RUN_MODE !== 'production',
    });

  newClient.initialize();
  eventClient = newClient;
};

export const isInitialized = (): boolean | undefined => eventClient?.isInitialized();

export const getUId = (): number | undefined => eventClient?.getUId?.();

export const sendInAppPageView = ({ userIdx, referrer }: EventInAppPageViewParam): void =>
  eventClient?.sendInAppPageView?.({ userIdx, referrer });

export const sendPurchase = (transactionId: string, purchaseInfo: EventPurchaseInfoType): void =>
  eventClient?.sendPurchase?.(transactionId, purchaseInfo);

export const sendPurchaseChargeAndPay = (
  transactionId: string,
  purchaseInfo: EventPurchaseInfoType & {
    charge_value: number;
    charge_t_id: string;
    pay_value: number;
    pay_t_id: string;
  },
): void => eventClient?.sendPurchaseChargeAndPay?.(transactionId, purchaseInfo);

export const sendBeginCheckout = (items: ContentItem[]): void => eventClient?.sendBeginCheckout?.(items);

export const sendCustomEvent = (
  screenName: string,
  action: EventActionType,
  target: string,
  params: EventParamsType,
): void => eventClient?.sendCustomEvent(screenName, action, target, params);

const makeSendEvent =
  (action: EventActionType) =>
  (screenName: string, target: string, params: EventParamsType = {}): void =>
    sendCustomEvent(screenName, action, target, params);

export const sendClickEvent = makeSendEvent('select');
export const sendViewEvent = makeSendEvent('view');
export const sendSubmitEvent = makeSendEvent('submit');
export const sendLogin = (): void => eventClient?.sendLogin?.();
export const sendSignUp = (): void => eventClient?.sendSignUp?.();
export const sendSignupInfoCompleted = (): void => eventClient?.sendSignupInfoCompleted?.();

export const setFfid = (ffid?: string): void => eventClient?.setFfid?.(ffid);
export const setUId = (uIdx?: number | null): void => eventClient?.setUId?.(uIdx);
export const sendScrollEvent = (screenName: string, target: string, params?: EventParamsType): void =>
  eventClient?.sendScrollEvent?.(screenName, target, params);
export const sendBrazeCustomEvent = (eventName: string): void => eventClient?.sendBrazeCustomEvent?.(eventName);
