import development from './environments/development';
import local from './environments/local';
import production from './environments/production';
import stage from './environments/stage';
import test from './environments/test';
import type { Config } from './types';

const runMode = process.env.RUN_MODE;

const load = (): Config => {
  if (runMode === 'production') {
    return production;
  }

  if (runMode === 'stage') {
    return stage;
  }

  if (runMode === 'development') {
    return development;
  }

  if (runMode === 'test') {
    return test;
  }

  return local;
};

export const config = load();
