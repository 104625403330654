import {
  Array as RArray,
  Boolean as RBoolean,
  Literal as RLiteral,
  Null as RNull,
  Number as RNumber,
  Record as RRecord,
  Static as RStatic,
  String as RString,
  Union as RUnion,
} from 'runtypes';

import { RViewBase, ViewLayoutType } from './types/ViewsBaseType';
import { RGenreHomeSection } from './types/ViewsSectionType';

export const RGenreHomeViewContents = RRecord({
  category_ids: RArray(RNumber),
  genre: RString.nullable(),
  set_id: RNumber.nullable(),
  is_adult_only: RBoolean.nullable(),
  show_search_bar: RBoolean.optional(),
  header_title: RString.optional(),
  disable_genre_home_tab: RBoolean.optional(),
});
export type GenreHomeViewContents = RStatic<typeof RGenreHomeViewContents>;

export const RGenreHomeView = RViewBase.extend({
  contents: RGenreHomeViewContents,
  layout: RLiteral(ViewLayoutType.GenreHome),
  resources: RArray(RGenreHomeSection),
});
export type GenreHomeView = RStatic<typeof RGenreHomeView>;

export const RView = RUnion(RGenreHomeView);
export type View = RStatic<typeof RView>;

export const RViews = RRecord({
  success: RLiteral(true),
  message: RNull,
  data: RRecord({
    view: RView,
  }),
});
export type Views = RStatic<typeof RViews>;

export * from './types/ViewsBaseType';
export * from './types/ViewsSectionType';
