import { GroupSectionsError } from '@/models/backendsApi/v2/GroupSections/GroupSectionsErrorType';
import { GroupSectionItemsModel } from '@/models/backendsApi/v2/GroupSections/Items/GroupSectionsItemsModel';
import { ServiceMethod } from '@/services/baseService';
import { getFeatureFlagValueByKey } from '@/utils/featureFlag';

import { makeCallAPIMethod } from '../../backendsApiService';
import { GroupSectionsItemsRequest } from './interfaces/GroupSectionsItemsRequest';

export const groupSectionsItems: ServiceMethod<
  GroupSectionsItemsRequest,
  GroupSectionItemsModel,
  GroupSectionsError
> = async (reqParams, req) => {
  const call = makeCallAPIMethod(
    !!getFeatureFlagValueByKey({
      featureFlag: req?.FeatureFlag || reqParams?.featureFlag,
      featureFlagKey: 'web-internal-backendsapi-request-20240710',
    }),
  )(GroupSectionItemsModel);

  const {
    params: { groupSectionId, groupUnitId },
    ...params
  } = reqParams;
  return call<GroupSectionsItemsRequest, GroupSectionsError, GroupSectionItemsModel>({
    method: 'GET',
    pathname: `/v2/group-sections/${groupSectionId}/groups/${groupUnitId}`,
    params: {
      ...params,
      query: {
        ...params.query,
        platform: 'web',
      },
    },
    preferNonProxied: true,
  });
};
