import Head from 'next/head';

import { defaultTheme } from '@/components/styles/themes';
import appleTouchIcon57 from '~/public/favicon/apple-touch-icon-57x57.png';
import appleTouchIcon60 from '~/public/favicon/apple-touch-icon-60x60.png';
import appleTouchIcon72 from '~/public/favicon/apple-touch-icon-72x72.png';
import appleTouchIcon76 from '~/public/favicon/apple-touch-icon-76x76.png';
import appleTouchIcon114 from '~/public/favicon/apple-touch-icon-114x114.png';
import appleTouchIcon120 from '~/public/favicon/apple-touch-icon-120x120.png';
import appleTouchIcon144 from '~/public/favicon/apple-touch-icon-144x144.png';
import appleTouchIcon152 from '~/public/favicon/apple-touch-icon-152x152.png';
import appleTouchIcon180 from '~/public/favicon/apple-touch-icon-180x180.png';
import favicon from '~/public/favicon/favicon.ico';
import favicon16 from '~/public/favicon/favicon-16x16.png';
import favicon32 from '~/public/favicon/favicon-32x32.png';
import favicon48 from '~/public/favicon/favicon-48x48.png';
import favicon96 from '~/public/favicon/favicon-96x96.png';
import favicon128 from '~/public/favicon/favicon-128x128.png';
import favicon192 from '~/public/favicon/favicon-192x192.png';
import favicon196 from '~/public/favicon/favicon-196x196.png';
import favicon256 from '~/public/favicon/favicon-256x256.png';
import favicon512 from '~/public/favicon/favicon-512x512.png';
import mstile70 from '~/public/favicon/mstile-70x70.png';
import mstile144 from '~/public/favicon/mstile-144x144.png';
import mstile150 from '~/public/favicon/mstile-150x150.png';
import mstile310Wide from '~/public/favicon/mstile-310x150.png';
import mstile310 from '~/public/favicon/mstile-310x310.png';

export const Favicon = (): ReactJSX.Element => (
  <Head>
    <meta name="msapplication-TileImage" content={mstile144.src} />
    <meta name="msapplication-TileColor" content={defaultTheme.colors.blue} />
    <meta name="msapplication-square70x70logo" content={mstile70.src} />
    <meta name="msapplication-square150x150logo" content={mstile150.src} />
    <meta name="msapplication-wide310x150logo" content={mstile310Wide.src} />
    <meta name="msapplication-square310x310logo" content={mstile310.src} />
    <link rel="shortcut icon" href={favicon.src} />

    <link rel="apple-touch-icon" sizes="57x57" href={appleTouchIcon57.src} />
    <link rel="apple-touch-icon" sizes="60x60" href={appleTouchIcon60.src} />
    <link rel="apple-touch-icon" sizes="72x72" href={appleTouchIcon72.src} />
    <link rel="apple-touch-icon" sizes="76x76" href={appleTouchIcon76.src} />
    <link rel="apple-touch-icon" sizes="114x114" href={appleTouchIcon114.src} />
    <link rel="apple-touch-icon" sizes="120x120" href={appleTouchIcon120.src} />
    <link rel="apple-touch-icon" sizes="144x144" href={appleTouchIcon144.src} />
    <link rel="apple-touch-icon" sizes="152x152" href={appleTouchIcon152.src} />
    <link rel="apple-touch-icon" sizes="180x180" href={appleTouchIcon180.src} />

    <link rel="icon" type="image/png" sizes="16x16" href={favicon16.src} />
    <link rel="icon" type="image/png" sizes="32x32" href={favicon32.src} />
    <link rel="icon" type="image/png" sizes="48x48" href={favicon48.src} />
    <link rel="icon" type="image/png" sizes="96x96" href={favicon96.src} />
    <link rel="icon" type="image/png" sizes="128x128" href={favicon128.src} />
    <link rel="icon" type="image/png" sizes="192x192" href={favicon192.src} />
    <link rel="icon" type="image/png" sizes="196x196" href={favicon196.src} />
    <link rel="icon" type="image/png" sizes="256x256" href={favicon256.src} />
    <link rel="icon" type="image/png" sizes="512x512" href={favicon512.src} />
  </Head>
);
