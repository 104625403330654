import {
  Array as RArray,
  Boolean as RBoolean,
  Number as RNumber,
  Record as RRecord,
  Static as RStatic,
  String as RString,
  Union as RUnion,
} from 'runtypes';

export const RUseFreePassCouponMethod = RRecord({
  message: RString,
  result: RRecord({
    information: RRecord({
      t_id: RString,
      free_pass_coupon_applied_b_ids: RArray(RString),
      remain_book_count_for_purchase: RNumber,
      remain_books_price_for_purchase: RNumber,
    }),
    is_api: RBoolean,
    link: RString,
    method: RString,
    parameters: RString,
  }),
});
export type UseFreePassCouponMethod = RStatic<typeof RUseFreePassCouponMethod>;

export const RWaitFreeMethod = RRecord({
  t_id: RString,
  b_ids: RArray(RString),
});
export type WaitFreeMethod = RStatic<typeof RWaitFreeMethod>;

export const RFreeRentCouponMethod = RRecord({
  t_id: RString,
  b_ids: RArray(RString),
});
export type FreeRentCouponMethod = RStatic<typeof RFreeRentCouponMethod>;

export const RPaymentRouteMethod = RUnion(RUseFreePassCouponMethod, RWaitFreeMethod, RFreeRentCouponMethod);
export type PaymentRouteMethod = RStatic<typeof RPaymentRouteMethod>;
