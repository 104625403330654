import { PaymentRouteGetFreePassCouponAvailableBooksModel } from '@/models/booksBackend/PaymentRouteGetFreePassCouponAvailableBooks/PaymentRouteGetFreePassCouponAvailableBooksModel';
import { ServiceMethod, stringifyRequestParams } from '@/services/baseService';
import { getFeatureFlagValueByKey } from '@/utils/featureFlag';

import { makeCallAPIMethod } from '../booksBackendService';
import { PaymentRouteGetFreePassCouponAvailableBooksRequest } from './interfaces/PaymentRouteGetFreePassCouponAvailableBooksRequest';

export interface PaymentRouteGetFreePassCouponAvailableBooksError {
  message: string;
}

export const paymentRouteGetFreePassCouponAvailableBooks: ServiceMethod<
  PaymentRouteGetFreePassCouponAvailableBooksRequest,
  PaymentRouteGetFreePassCouponAvailableBooksModel,
  PaymentRouteGetFreePassCouponAvailableBooksError
> = async (reqParams, req) => {
  const call = makeCallAPIMethod(
    !!getFeatureFlagValueByKey({
      featureFlag: req?.FeatureFlag || reqParams.featureFlag,
      featureFlagKey: 'web-internal-books-backendsapi-request-20240813',
    }),
  )(PaymentRouteGetFreePassCouponAvailableBooksModel);
  return call<void, PaymentRouteGetFreePassCouponAvailableBooksError, PaymentRouteGetFreePassCouponAvailableBooksModel>(
    {
      method: 'POST',
      pathname: '/api/payment/route/get-free-pass-coupon-available-books',
      params: { body: reqParams.body },
      transformRequest: [stringifyRequestParams],
      through: {
        method: 'POST',
        pathname: '/api/payment/route/get-free-pass-coupon-available-books',
        params: { body: reqParams.body },
      },
    },
    req,
  );
};
