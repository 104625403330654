import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';

import { hydrateAction } from '@/features/hydrate';
import type { ActionRequest, AppDispatch, RootState } from '@/features/store';
import { CartCount } from '@/models/booksBackend/Cart/CartCountType';
import { cartCount } from '@/services/booksBackend/cart/cartCountService';

export interface CartState {
  cartCount: null | number;
}

export const fetchCartCountAction = createAsyncThunk<
  CartCount,
  ActionRequest<void>,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>('cart/fetchCartCountAction', async ({ req }, thunkAPI) => {
  const [error, model] = await cartCount(undefined, req);

  if (error) {
    return thunkAPI.rejectWithValue({
      message: error.message,
      data: error.response?.data,
    });
  }

  return model.Data;
});

export const cartSelector = (state: RootState): CartState => state.cart;
export const cartCountSelector = createSelector(cartSelector, (state: CartState) => state.cartCount);

export const cartSlice = createSlice({
  name: 'cart',

  initialState: {
    cartCount: null,
  } as CartState,

  reducers: {},

  extraReducers: builder => {
    builder.addCase(fetchCartCountAction.fulfilled, (state, action) => {
      state.cartCount = action.payload.count;
    });

    builder.addCase(hydrateAction, (state, action) => {
      const nextState = {
        ...state,
        ...action.payload.cart,
      };

      if (typeof state.cartCount === 'number' && nextState.cartCount === null) {
        nextState.cartCount = state.cartCount;
      }

      return nextState;
    });
  },
});
