import { ScheduleEventsModel } from '@/models/booksBackend/ScheduleEvents/ScheduleEventsModel';
import { ServiceMethod } from '@/services/baseService';
import { getFeatureFlagValueByKey } from '@/utils/featureFlag';

import { makeCallAPIMethod } from '../booksBackendService';

export const scheduleEvents: ServiceMethod<void, ScheduleEventsModel, void> = async (_, req) => {
  const call = makeCallAPIMethod(
    !!getFeatureFlagValueByKey({
      featureFlag: req?.FeatureFlag,
      featureFlagKey: 'web-internal-books-backendsapi-request-20240813',
    }),
  )(ScheduleEventsModel);

  return call<void, void, ScheduleEventsModel>(
    {
      method: 'GET',
      pathname: '/api/schedule/events',
      preferNonProxied: true,
      timeout: 5000,
    },
    req,
  );
};
