import {
  Array as RArray,
  Boolean as RBoolean,
  Literal as RLiteral,
  Null as RNull,
  Number as RNumber,
  Record as RRecord,
  Static as RStatic,
  String as RString,
  Union as RUnion,
} from 'runtypes';

const RAccountVerificationSendEmailSuccess = RRecord({
  success: RLiteral(true),
  message: RNull,
  data: RRecord({
    valid: RLiteral(true),
    messages: RArray(RString),
    user_idx: RNumber,
    email: RString,
    email_valid_hours: RNumber,
    email_provider_url: RString,
    is_already_verified: RLiteral(false),
  }),
});

const RAccountVerificationSendEmailFailure = RRecord({
  success: RLiteral(true),
  message: RNull,
  data: RRecord({
    valid: RLiteral(false),
    messages: RArray(RString),
    user_idx: RNull,
    email: RNull,
    email_valid_hours: RNull,
    email_provider_url: RNull,
    is_already_verified: RBoolean,
  }),
});

export const RAccountVerificationSendEmail = RUnion(
  RAccountVerificationSendEmailSuccess,
  RAccountVerificationSendEmailFailure,
);
export type AccountVerificationSendEmail = RStatic<typeof RAccountVerificationSendEmail>;
export type AccountVerificationSendEmailSuccess = RStatic<typeof RAccountVerificationSendEmailSuccess>;
