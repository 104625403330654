import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';

import { hydrateAction } from '@/features/hydrate';
import type { ActionRequest, AppDispatch, RootState } from '@/features/store';
import { PaymentRouteGetFreePassCouponAvailableBooks } from '@/models/booksBackend/PaymentRouteGetFreePassCouponAvailableBooks/PaymentRouteGetFreePassCouponAvailableBooksType';
import { paymentRouteGetFreePassCouponAvailableBooks } from '@/services/booksBackend/paymentRouteGetFreePassCouponAvailableBooks/paymentRouteGetFreePassCouponAvailableBooksService';
import { debug } from '@/utils/debug';

import type { PaymentRouteRootState } from '..';

const log = debug('paymentRouteEpisode');

export interface PaymentRouteEpisodeState {
  paymentRouteEpisode: PaymentRouteGetFreePassCouponAvailableBooks | null;
  lastError: string | true | null;
  isLoading: boolean;
}

export const fetchPaymentRouteEpisodeAction = createAsyncThunk<
  PaymentRouteGetFreePassCouponAvailableBooks,
  ActionRequest<{
    orderType: 'purchase' | 'rental';
    serialId: string;
    episodeIds?: string[];
    excludedEpisodeIds?: string[];
  }>,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: { message?: string; isLoginFailure: boolean };
  }
>('inapp/checkout/paymentRouteBook/fetchPaymentRouteEpisodeAction', async ({ reqParams, req }, thunkAPI) => {
  const [error, model] = await paymentRouteGetFreePassCouponAvailableBooks(
    {
      body: {
        pay_object: reqParams.orderType === 'purchase' ? 'buy' : 'rent',
        order_item_type: 'episode',
        serial_id: reqParams.serialId,
        episode_ids: reqParams.episodeIds,
        excluded_episode_ids: reqParams.excludedEpisodeIds,
      },
    },
    req,
  );

  if (error) {
    log('fetchPaymentRouteEpisodeAction Failed', error.response?.status, error.response?.data);

    return thunkAPI.rejectWithValue({
      message: error.response?.data.message,
      isLoginFailure: error.response?.status === 401,
    });
  }

  return model.Data;
});

export const paymentRouteEpisodeSelector = createSelector(
  (state: RootState) => state.inapp.checkout.paymentRoute,
  (state: PaymentRouteRootState) => state.paymentRouteEpisode,
);

export const paymentRouteEpisodeLastErrorSelector = createSelector(
  paymentRouteEpisodeSelector,
  state => state.lastError,
);

export const paymentRouteEpisodeInfoSelector = createSelector(
  paymentRouteEpisodeSelector,
  state => state.paymentRouteEpisode?.result.information,
);

export const paymentRouteEpisodeSlice = createSlice({
  name: 'inapp/checkout/paymentRoute/paymentRouteEpisode',

  initialState: {
    paymentRouteEpisode: null,
    lastError: null,
    isLoading: false,
  } as PaymentRouteEpisodeState,

  reducers: {},

  extraReducers: builder => {
    builder
      .addCase(fetchPaymentRouteEpisodeAction.fulfilled, (state, action) => {
        state.paymentRouteEpisode = action.payload;
      })
      .addCase(fetchPaymentRouteEpisodeAction.rejected, (state, action) => {
        const message = action.payload?.message;
        state.lastError = message ?? true;
      });

    builder.addCase(hydrateAction, (state, action) => {
      const nextState = {
        ...state,
        ...action.payload.inapp.checkout.paymentRoute.paymentRouteEpisode,
      };

      if (state.paymentRouteEpisode !== null && nextState.paymentRouteEpisode === null) {
        nextState.paymentRouteEpisode = state.paymentRouteEpisode;
        nextState.isLoading = state.isLoading;
      }

      return nextState;
    });
  },
});
