import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';

import { hydrateAction } from '@/features/hydrate';
import type { ActionRequest, RootState } from '@/features/store';
import { BookPaymentRouteItem, PaymentRouteBook } from '@/models/booksBackend/PaymentRouteBook/PaymentRouteBookType';
import { paymentRouteBook } from '@/services/booksBackend/paymentRouteBook/paymentRouteBookService';
import { debug } from '@/utils/debug';

import type { PaymentRouteRootState } from '..';

const log = debug('paymentRouteBook');

export interface PaymentRouteBookState {
  paymentRouteBook: PaymentRouteBook | null;
  lastRequestBookIds: string[] | null;
  lastError: string | true | null;
  isLoading: boolean;
}

export const fetchPaymentRouteBookAction = createAsyncThunk<
  PaymentRouteBook,
  ActionRequest<{
    bookIds: string[];
    orderType: 'purchase' | 'rental';
  }>,
  {
    rejectValue: { message?: string; isLoginFailure: boolean };
    state: RootState;
  }
>('inapp/checkout/paymentRouteBook/fetchPaymentRouteBookAction', async ({ reqParams, req }, thunkAPI) => {
  const { featureFlag } = thunkAPI.getState().global;
  const [error, model] = await paymentRouteBook(
    {
      query: {
        b_ids: reqParams.bookIds,
        pay_object: reqParams.orderType === 'purchase' ? 'buy' : 'rent',
        is_prefer_return_api_endpoint: 'true',
      },
      featureFlag,
    },
    req,
  );

  if (error) {
    log('fetchPaymentRouteBookAction Failed', error.response?.status, error.response?.data);

    return thunkAPI.rejectWithValue({
      message: error.response?.data.message,
      isLoginFailure: error.response?.status === 401,
    });
  }

  return model.Data;
});

export const paymentRouteBookSelector = createSelector(
  (state: RootState) => state.inapp.checkout.paymentRoute,
  (state: PaymentRouteRootState) => state.paymentRouteBook,
);

export const paymentRouteBookLastRequestBookIdsSelector = createSelector(
  paymentRouteBookSelector,
  state => state.lastRequestBookIds,
);

export const paymentRouteBookLastErrorSelector = createSelector(paymentRouteBookSelector, state => state.lastError);

export const paymentRouteBookKeyArraySelector = createSelector(
  (state: RootState) => state.inapp.checkout.paymentRoute,
  (state: PaymentRouteRootState) => {
    if (!state.paymentRouteBook.paymentRouteBook) {
      return [] as (BookPaymentRouteItem & { key: keyof PaymentRouteBook })[];
    }

    const keys = Object.keys(
      state.paymentRouteBook.paymentRouteBook,
    ) as (keyof typeof state.paymentRouteBook.paymentRouteBook)[];

    return keys.reduce(
      (paymentRoutes, key) => {
        const paymentRoute = state.paymentRouteBook.paymentRouteBook?.[key];

        if (paymentRoute && paymentRoute.is_api) {
          paymentRoutes.push({
            key,
            ...paymentRoute,
          });
        }

        return paymentRoutes;
      },
      [] as (BookPaymentRouteItem & { key: keyof PaymentRouteBook })[],
    );
  },
);

export const paymentRouteBookSlice = createSlice({
  name: 'inapp/checkout/paymentRoute/paymentRouteBook',

  initialState: {
    paymentRouteBook: null,
    lastRequestBookIds: null,
    lastError: null,
    isLoading: false,
  } as PaymentRouteBookState,

  reducers: {},

  extraReducers: builder => {
    builder
      .addCase(fetchPaymentRouteBookAction.fulfilled, (state, action) => {
        state.paymentRouteBook = action.payload;
        state.lastRequestBookIds = action.meta.arg.reqParams.bookIds;
      })
      .addCase(fetchPaymentRouteBookAction.rejected, (state, action) => {
        const message = action.payload?.message;
        state.lastError = message ?? true;
      });

    builder.addCase(hydrateAction, (state, action) => {
      const nextState = {
        ...state,
        ...action.payload.inapp.checkout.paymentRoute.paymentRouteBook,
      };

      if (state.paymentRouteBook !== null && nextState.paymentRouteBook === null) {
        nextState.paymentRouteBook = state.paymentRouteBook;
        nextState.lastRequestBookIds = state.lastRequestBookIds;
        nextState.isLoading = state.isLoading;
      }

      return nextState;
    });
  },
});
