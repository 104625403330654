import {
  Array as RArray,
  Partial as RPartial,
  Record as RRecord,
  Static as RStatic,
  String as RString,
} from 'runtypes';

export const RBooksDescriptions = RPartial({
  // 작가 설명
  author: RString.optional(),
  // 목차
  toc: RString.optional(),
  // 책 소개
  intro: RString,
  // 출판사 서평
  publisher_review: RString.optional(),
  // 책 소개 이미지 주소
  intro_image_url: RString.optional(),
  // 책 소개 영상 주소
  intro_video_url: RString.optional(),
});
export type BooksDescriptions = RStatic<typeof RBooksDescriptions>;

export const RBooksDescriptionsResponse = RArray(
  RRecord({
    b_id: RString,
    descriptions: RBooksDescriptions,
  }),
);
export type BooksDescriptionsResponse = RStatic<typeof RBooksDescriptionsResponse>;
