import { BooksDescriptionsModel } from '@/models/bookApi/BooksDescriptions/BooksDescriptionsModel';
import { ServiceMethod } from '@/services/baseService';

import { makeCallAPIMethod } from '../bookApiService';
import { BooksDescriptionsRequest } from './interfaces/BooksDescriptionsRequest';

const call = makeCallAPIMethod(BooksDescriptionsModel);

export type BookError = {
  code: string;
  message: string;
};

export const fetchBooksDescriptions: ServiceMethod<
  BooksDescriptionsRequest,
  BooksDescriptionsModel,
  BookError
> = async (reqParams, req) =>
  call<BooksDescriptionsRequest, BookError, BooksDescriptionsModel>(
    {
      method: 'GET',
      pathname: '/books/descriptions',
      params: {
        query: reqParams.query,
      },
      preferNonProxied: true,
    },
    req,
  );
