import { Null as RNull, Record as RRecord, Static as RStatic } from 'runtypes';

import { RUser } from '@/base/interfaces/User';
import { RVariables } from '@/base/interfaces/Variables';

export const RAuthVariables = RRecord({
  auth: RRecord({ loggedUser: RUser.Or(RNull) }),
  variables: RRecord({ variables: RVariables.Or(RNull) }),
});
export type AuthVariables = RStatic<typeof RAuthVariables>;
