import { RUseFreePassCouponMethod } from '@/models/booksBackend/PaymentRouteMethod/PaymentRouteMethodType';

import { checkoutExcludeEpisodesAction, checkoutSetBookIdByBookIdSelector } from '../../checkout/checkoutSlice';
import { paymentRouteEpisodeSelector } from '../../paymentRoute/paymentRouteEpisode/paymentRouteEpisodeSlice';
import { fetchPaymentRouteMethodAction } from '../../paymentRoute/paymentRouteMethod/paymentRouteMethodSlice';
import { addPurchaseFinishedBooksAction } from '../actions';
import { CheckoutStateTypes, FreeCouponsSuccessContinuation } from '../types';
import { createTransitionThunk } from '../utils/createTransitionThunk';

export const episodeFreeCouponsTransitionAction = createTransitionThunk(
  'episodeFreeCouponsTransition',
  CheckoutStateTypes.FETCH_PAYMENTROUTEEPISODE_WAITING,
  (_previousState, _args, { getState }) => {
    const paymentRouteEpisode = paymentRouteEpisodeSelector(getState());
    const hasFreePassCoupon =
      !!paymentRouteEpisode.paymentRouteEpisode?.result.information?.free_pass_coupon_available_count;

    return { state: CheckoutStateTypes.EPISODE_FREECOUPONS, hasFreeCoupons: hasFreePassCoupon };
  },
);

export const episodeFreeCouponsUseTransitionAction = createTransitionThunk(
  'episodeFreeCouponsUseTransition',
  CheckoutStateTypes.EPISODE_FREECOUPONS,
  async (_previousState, _args, { dispatch, getState }) => {
    const initialState = getState();
    const paymentRouteEpisode = paymentRouteEpisodeSelector(initialState);
    const episodeFreeCoupons = paymentRouteEpisode.paymentRouteEpisode?.result;

    if (!episodeFreeCoupons) {
      return { state: CheckoutStateTypes.FAILURE, reason: null };
    }

    const result = await dispatch(
      fetchPaymentRouteMethodAction({
        reqParams: {
          body: {
            method: episodeFreeCoupons.method,
            endpoint: episodeFreeCoupons.link,
            params: episodeFreeCoupons.parameters,
          },
        },
      }),
    );

    if (fetchPaymentRouteMethodAction.rejected.match(result) || !RUseFreePassCouponMethod.guard(result.payload)) {
      return { state: CheckoutStateTypes.FAILURE, reason: null };
    }

    const appliedBookIds = result.payload.result.information.free_pass_coupon_applied_b_ids;
    const transactionId = result.payload.result.information.t_id;

    const setBookIdByBookId = checkoutSetBookIdByBookIdSelector(initialState);
    const purchasedBooks = appliedBookIds.map(bookId => ({
      bookId,
      setBookId: setBookIdByBookId?.get(bookId) ?? null,
      isFreeBook: false,
    }));

    dispatch(addPurchaseFinishedBooksAction(purchasedBooks));

    if (episodeFreeCoupons.information.remain_book_count_for_purchase === 0) {
      return {
        state: CheckoutStateTypes.FREECOUPONS_SUCCESS,
        continuation: FreeCouponsSuccessContinuation.SUCCESS,
        isWaitFree: false,
        transactionId,
      };
    }

    dispatch(checkoutExcludeEpisodesAction({ reqParams: { excludingEpisodeIds: appliedBookIds } }));

    return {
      state: CheckoutStateTypes.FREECOUPONS_SUCCESS,
      continuation: FreeCouponsSuccessContinuation.CHECKOUT,
      isWaitFree: false,
      transactionId,
    };
  },
);
