import type { BookRenderData } from '@/components/common/Book';
import { BackendsBadge } from '@/models/backendsApi/BackendsBadgeType';
import { BackendsBookPrice, BackendsSerialPriceRange } from '@/models/backendsApi/BackendsBookType';

import type { BackendsBookData } from './types';

export const getPriceInfo = (
  price?: BackendsBookPrice | null,
  serialPrice?: BackendsSerialPriceRange | null,
): { minSellingPrice: number; originalPrice?: number; discountPercentage?: number } | undefined => {
  if (!serialPrice && !price) {
    return undefined;
  }

  const serialMinPrice = serialPrice?.min_price_non_zero ?? serialPrice?.min_price_non_zero;
  if (
    (price?.sale_price !== undefined && serialMinPrice === undefined) ||
    (price?.sale_price && price.sale_price < (serialMinPrice as number))
  ) {
    return {
      minSellingPrice: price.sale_price,
      originalPrice: price.full_price,
      discountPercentage: Math.round((1 - price.sale_price / price.full_price) * 100),
    };
  }
  if (serialPrice) {
    return { minSellingPrice: serialPrice.min_price_non_zero || serialPrice.min_price };
  }

  return undefined;
};

export const getShouldUseSeriesInfo = (book: BackendsBookData): boolean =>
  !!book.categories?.find(category => category.use_series === 'Y');

type RenderDataFrame = BookRenderData & {
  cover: Record<string, unknown>;
  metadata: { extra: Record<string, unknown> };
};

// Serializable하게 유지하기 위해 undefined를 사용하지 않아야 함!
// -> 없는 값은 undefined 대신 키를 아예 넣지 않는 쪽으로 작성되어야 함
export const convertToRenderData = ({
  book,
  badges,
}: {
  book: BackendsBookData;
  badges?: BackendsBadge[];
}): BookRenderData => {
  const data: RenderDataFrame = {
    bookId: book.book_id,
    title: book.title,
    cover: {
      isAdultOnly: book.adults_only,
      original: book.is_original,

      thumbnail: book.cover,
    },
    metadata: {
      title: { showTitle: true },
      extra: {
        ...(!!book.introduction?.description && { description: book.introduction.description }),
        ...(!!book.publisher?.name && { publisher: book.publisher.name }),
        ...(!!book.categories?.length && { genre: book.categories.map(category => category.genre)[0] }),
      },
    },
  };

  if (badges) {
    data.cover.badges = {
      image: badges.map(badge => ({
        alt: badge.badge_text,
        color: badge.badge_background,
        images: badge.badge_urls,
      })),
    };
  }

  if (book.serial?.free) {
    data.cover.freeBook = {
      count: Math.max(book.serial.free.purchase, book.serial.free.rental),
      unit: book.serial.unit ?? '화',
    };
  }

  if (book.set) {
    data.cover.setBook = {
      count: book.set.total,
    };
  }

  if (book.authors) {
    data.metadata.authors = book.authors.map(author => ({
      name: author.name,
      ...(typeof author.author_id === 'number' && { id: author.author_id }),
      ...(author.role && { role: author.role }),
    }));
  }

  if (book.ratings) {
    const ratings = book.ratings.reduce<{ sum: number; score: number; count: number }>(
      ({ sum, count }, rating) => {
        const newSum = sum + rating.rating * rating.count;
        const newCount = count + rating.count;

        return { sum: newSum, count: newCount, score: newCount === 0 ? 0 : newSum / newCount };
      },
      { sum: 0, score: 0, count: 0 },
    );

    data.metadata.starRate = { count: ratings.count, score: ratings.score };
  }

  if (book.serial) {
    data.metadata.extra.series = {
      completion: book.serial.completion ?? false,
      total: book.serial.total ?? 0,
      unit: book.serial.unit ?? '화',
    };
  }

  const rentalPriceInfo = getPriceInfo(book.rental);
  const purchasePriceInfo = getPriceInfo(book.purchase);
  data.metadata.extra.price = {
    ...(rentalPriceInfo && { rental: rentalPriceInfo }),
    ...(purchasePriceInfo && { purchase: purchasePriceInfo }),
  };

  return data;
};

export const convertToSerialRenderData = ({
  book,
  badges,
}: {
  book?: BackendsBookData;
  badges?: BackendsBadge[];
}): BookRenderData => {
  if (!book) {
    return {};
  }

  const data = convertToRenderData({ book, badges }) as RenderDataFrame;
  if (!book.serial) {
    return data;
  }

  const shouldUseSeriesInfo = getShouldUseSeriesInfo(book);

  data.title = (shouldUseSeriesInfo && book.serial.title) || book.title;
  data.cover = {
    ...data.cover,
    thumbnail: (shouldUseSeriesInfo && book.serial.cover) || book.cover,
  };

  const rentalPriceInfo = getPriceInfo(book.rental, book.serial.rental);
  const purchasePriceInfo = getPriceInfo(book.purchase, book.serial.purchase);
  data.metadata.extra.price = {
    ...(rentalPriceInfo && { rental: rentalPriceInfo }),
    ...(purchasePriceInfo && { purchase: purchasePriceInfo }),
  };

  return data;
};
