import Head from 'next/head';
import { ReactNode } from 'react';
import { useSelector } from 'react-redux';

import { isRidiAppSelector, variablesSelector } from '@/features/global/variables/variablesSlice';
import { createWebSiteJsonLd } from '@/utils/meta';

import { Meta, MetaProps } from './Meta';
import { Favicon } from './Meta/Favicon';

interface PageHeadProps {
  canonicalPath?: string;
  disableTitleSuffix?: boolean;
  extraHead?: ReactNode;
  title: string;
  meta?: Omit<MetaProps, 'ogTitle'>;
  createJsonLd?: () => object;
}

const SAME_AS_URLS = [
  'https://www.instagram.com/ridi_official_/',
  'https://twitter.com/RIDI_twt',
  'https://www.youtube.com/@RIDI_official/',
  'https://apps.apple.com/kr/app/%EB%A6%AC%EB%94%94-%EC%9B%B9%ED%88%B0-%EC%9B%B9%EC%86%8C%EC%84%A4-%EC%A0%84%EC%9E%90%EC%B1%85-%EB%AA%A8%EB%91%90-%EC%97%AC%EA%B8%B0%EC%97%90/id338813698',
  'https://play.google.com/store/apps/details?id=com.initialcoms.ridi',
  'https://m.onestore.co.kr/mobilepoc/apps/appsDetail.omp?prodId=0000764476',
];

/**
 * page 컴포넌트에서 사용하는 head 태그 영역 컴포넌트
 *
 * @param props
 * @param props.title 페이지 타이틀 태그에 들어갈 제목
 * @param props.disableTitleSuffix 타이틀 뒤에 ` - 리디`를 붙일지 여부
 * @param props.extraHead head 태그에 추가할 엘리먼트. 반드시 next/head 컴포넌트로 감싸줘야한다.
 * @returns {ReactJSX.Element}
 */
export const PageHead = ({
  canonicalPath,
  disableTitleSuffix = false,
  extraHead,
  title,
  meta,
  createJsonLd = () => createWebSiteJsonLd(SAME_AS_URLS),
}: PageHeadProps): ReactJSX.Element => {
  const { variables } = useSelector(variablesSelector);
  const titlePrefix = variables?.isDevelopment ? '[DEV] ' : '';
  const isRidiApp = useSelector(isRidiAppSelector);
  const titleSuffix = disableTitleSuffix || isRidiApp ? '' : ' - 리디';
  const pageTitle = `${titlePrefix}${title}${titleSuffix}`;

  const jsonld = createJsonLd();

  return (
    <>
      <Meta ogTitle={pageTitle} {...meta} />
      <Favicon />
      <Head>
        <title>{pageTitle}</title>
        <link
          title="리디 검색"
          rel="search"
          type="application/opensearchdescription+xml"
          href="/open_search_description.xml"
        />
        {canonicalPath && <link rel="canonical" href={`https://ridibooks.com${canonicalPath}`} />}
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
        <script
          type="application/ld+json"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(jsonld),
          }}
        />
      </Head>
      {extraHead}
    </>
  );
};
