"use strict";const o=require("@emotion/react");var d=(e=>(e[e.MobileSmall=319]="MobileSmall",e[e.MobileDefault=374]="MobileDefault",e[e.DesktopSmall=767]="DesktopSmall",e[e.DesktopMedium=1169]="DesktopMedium",e[e.DesktopDefault=1279]="DesktopDefault",e[e.DesktopLarge=1439]="DesktopLarge",e[e.GridLarge=1169]="GridLarge",e[e.GridMedium=999]="GridMedium",e[e.GridSmall=599]="GridSmall",e))(d||{});const r=(e,s)=>o.css`
  @media (max-width: ${e}px) {
    ${s};
  }
`,t=(e,s,l)=>o.css`
  @media (min-width: ${e+1}px) and (max-width: ${s}px) {
    ${l};
  }
`,c=(e,s)=>o.css`
  @media (min-width: ${e+1}px) {
    ${s};
  }
`,i=e=>o.css`
  @media (hover: none), (pointer: coarse), (hover: none) and (pointer: coarse), (hover: none) and (pointer: fine) {
    ${e};
  }
`,m=e=>o.css`
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      ${e};
    }
  }
`;exports.BreakPoint=d;exports.between=t;exports.greaterThan=c;exports.hover=m;exports.orBelow=r;exports.touchDevice=i;
