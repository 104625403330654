import { BookPaymentRouteItem } from '@/models/booksBackend/PaymentRouteBook/PaymentRouteBookType';

import { checkoutSetBookIdByBookIdSelector } from '../../checkout/selectors';
import { needsOpenBookSelector } from '../../checkoutForm/checkoutFormSlice';
import {
  paymentRouteBookLastRequestBookIdsSelector,
  paymentRouteBookSelector,
  PaymentRouteBookState,
} from '../../paymentRoute/paymentRouteBook/paymentRouteBookSlice';
import { fetchPaymentRouteMethodAction } from '../../paymentRoute/paymentRouteMethod/paymentRouteMethodSlice';
import { addPurchaseFinishedBooksAction } from '../actions';
import { CheckoutStateTypes, FreeCouponsSuccessContinuation } from '../types';
import { createTransitionThunk } from '../utils/createTransitionThunk';

const hasFreeCoupons = (paymentRouteBook: PaymentRouteBookState['paymentRouteBook']): boolean =>
  !!(
    paymentRouteBook?.wait_free ||
    paymentRouteBook?.free_rent_in_category_coupon ||
    paymentRouteBook?.free_rent_in_series_coupon
  );

export const freeCouponsTransitionAction = createTransitionThunk(
  'freeCouponsTransition',
  CheckoutStateTypes.FETCH_PAYMENTROUTEBOOK_WAITING,
  (_previousState, _args, { getState }) => {
    const paymentRouteBook = paymentRouteBookSelector(getState());

    return {
      state: CheckoutStateTypes.FREECOUPONS,
      hasFreeCoupons: hasFreeCoupons(paymentRouteBook.paymentRouteBook),
    };
  },
);

export const freeCouponsUseTransitionAction = createTransitionThunk(
  'freeCouponsUseTransition',
  CheckoutStateTypes.FREECOUPONS,
  async (
    _previousState,
    { freeCoupon, freeCouponType }: { freeCoupon: BookPaymentRouteItem; freeCouponType: string },
    { getState, dispatch },
  ) => {
    const initialState = getState();
    const lastRequestBookIds = paymentRouteBookLastRequestBookIdsSelector(initialState);

    if (!lastRequestBookIds) {
      return { state: CheckoutStateTypes.FAILURE, reason: null };
    }

    const result = await dispatch(
      fetchPaymentRouteMethodAction({
        reqParams: {
          body: {
            method: freeCoupon.method,
            endpoint: freeCoupon.link,
            params: freeCoupon.parameters,
          },
        },
      }),
    );

    if (fetchPaymentRouteMethodAction.rejected.match(result)) {
      return { state: CheckoutStateTypes.FAILURE, reason: null };
    }

    const setBookIdByBookId = checkoutSetBookIdByBookIdSelector(initialState);
    const purchasedBooks = lastRequestBookIds.map(bookId => ({
      bookId,
      setBookId: setBookIdByBookId?.get(bookId) ?? null,
      isFreeBook: false,
    }));

    dispatch(addPurchaseFinishedBooksAction(purchasedBooks));

    const transactionId = 't_id' in result.payload ? result.payload.t_id : result.payload.result?.information?.t_id;

    return {
      state: CheckoutStateTypes.FREECOUPONS_SUCCESS,
      continuation: FreeCouponsSuccessContinuation.SUCCESS,
      isWaitFree: freeCouponType === 'wait_free',
      transactionId,
    };
  },
);

export const freeCouponsFinalizeSuccessTransitionAction = createTransitionThunk(
  'freeCouponsFinalizeSuccessTransition',
  CheckoutStateTypes.FREECOUPONS_SUCCESS,
  async (previousState, _args, { getState }) => {
    const needsOpenBook = needsOpenBookSelector(getState());

    return {
      state: CheckoutStateTypes.SUCCESS,
      message: '결제가 완료되었습니다.',
      isWaitFree: previousState.isWaitFree,
      needsOpenBook: needsOpenBook.defaultByApp,
      eventType: 'free_coupons',
      transactionId: previousState.transactionId,
    };
  },
);
