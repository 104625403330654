// Rendered in books-backend base twig
export const PartialDropoffIds = {
  FOOTER: '__RIDIPARTIAL_FOOTER_DROPOFF',
  PACKAGES: '__RIDIPARTIAL_PACKAGES_DROPOFF',
};

// Rendered in next.js document, page
export const PartialTemplateIds = {
  FOOTER: '__RIDIPARTIAL_FOOTER',
  PACKAGES: '__RIDIPARTIAL_PACKAGES',
  SCRIPTS_ISOLATION: '__RIDIPARTIAL_SCRIPTS_ISOLATION',
};
