import { createAction, createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';

import { hydrateAction } from '@/features/hydrate';
import type { ActionRequest, RootState } from '@/features/store';
import { GroupSectionsError } from '@/models/backendsApi/v2/GroupSections/GroupSectionsErrorType';
import { GroupSectionsItem } from '@/models/backendsApi/v2/GroupSections/Items/GroupSectionsItemsType';
import { GroupUnit } from '@/models/backendsApi/v2/Views/ViewsType';
import { groupSectionsItemsCount } from '@/services/backendsApi/v2/groupSections/groupSectionsItemsCountService';
import { groupSectionsItems } from '@/services/backendsApi/v2/groupSections/groupSectionsItemsService';
import { GroupSectionsItemsRequest } from '@/services/backendsApi/v2/groupSections/interfaces/GroupSectionsItemsRequest';

export interface Group extends GroupUnit {
  items: GroupSectionsItem[] | null;
  itemsCount: number | null;
}

export interface GroupTabState {
  groups: Group[];
}

export const initGroupTabAction = createAction<GroupUnit[]>('gropTab/setGroupUnits');

export const fetchItemsAction = createAsyncThunk<
  { items: GroupSectionsItem[]; itemsCount: number },
  ActionRequest<GroupSectionsItemsRequest>,
  { rejectValue: GroupSectionsError }
>('groupTab/fetchItems', async ({ reqParams }, thunkAPI) => {
  const [itemsError, itemsModel] = await groupSectionsItems(reqParams);
  const [itemsCountError, itemsCountModel] = await groupSectionsItemsCount(reqParams);

  if (itemsError || itemsCountError) {
    const apiErrorMessage = itemsError?.response?.data.message || itemsCountError?.response?.data.message;
    const errorMessage = itemsError?.message || itemsCountError?.message;

    return thunkAPI.rejectWithValue({
      success: false,
      message: apiErrorMessage || errorMessage,
      data: null,
    });
  }

  return {
    items: itemsModel.Data.data.items,
    itemsCount: itemsCountModel.Data.data.count,
  };
});

export const groupTabSelector = (state: RootState): GroupTabState => state.groupTab;
export const groupTabGroupsSelector = createSelector(groupTabSelector, state => state.groups);
export const groupTabGroupSelector = createSelector(
  groupTabGroupsSelector,
  (_: RootState, groupUnitId: number) => groupUnitId,
  (groups, groupUnitId) => groups.find(group => group.id === groupUnitId) || groups[0],
);

export const groupTabSlice = createSlice({
  name: 'groupTab',

  initialState: {
    groups: [],
  } as GroupTabState,

  reducers: {},

  extraReducers: builder => {
    builder.addCase(initGroupTabAction, (state, action) => {
      state.groups = action.payload.map(groupUnit => ({
        ...groupUnit,
        items: null,
        itemsCount: null,
      }));
    });

    builder.addCase(fetchItemsAction.fulfilled, (state, action) => {
      const { params, query } = action.meta.arg.reqParams;
      const { groupUnitId } = params;
      const { offset: offsetRaw, limit: limitRaw } = query;
      const offset = Number(offsetRaw);
      const limit = Number(limitRaw);
      const { items, itemsCount } = action.payload;
      const targetGroup = state.groups.find(group => group.id === groupUnitId);

      if (!targetGroup) {
        return;
      }

      targetGroup.items = targetGroup.items || [];
      if (targetGroup.items.length < offset) {
        targetGroup.items = Array.from(
          { length: offset },
          (_, index) => (targetGroup.items as GroupSectionsItem[])[index] ?? null,
        );
      }
      targetGroup.items.splice(offset, limit, ...items);
      targetGroup.itemsCount = itemsCount;
    });

    builder.addCase(hydrateAction, (state, action) => {
      const groupTabState = groupTabSelector(action.payload);

      state.groups = groupTabState.groups;
    });
  },
});

export const groupTabReducer = groupTabSlice.reducer;
