import { AccountsMeModel } from '@/models/account/AccountsMe/AccountsMeModel';
import { ServiceMethod } from '@/services/baseService';

import { makeCallAPIMethod } from '../accountService';

type AccountsMeError = {
  detail: string;
};

export const accountsMe: ServiceMethod<void, AccountsMeModel, AccountsMeError> = async (_, req) => {
  const call = makeCallAPIMethod(AccountsMeModel);

  return call<void, AccountsMeError, AccountsMeModel>(
    {
      method: 'GET',
      pathname: '/accounts/me',
      params: {
        query: {
          t: String(Date.now()),
        },
      },
      preferNonProxied: true,
    },
    req,
  );
};
