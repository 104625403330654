import {
  Array as RArray,
  Boolean as RBoolean,
  Literal as RLiteral,
  Null as RNull,
  Number as RNumber,
  Record as RRecord,
  Static as RStatic,
  String as RString,
  Union as RUnion,
} from 'runtypes';

const RAccountSignupCreateAccountSuccess = RRecord({
  success: RLiteral(true),
  message: RNull,
  data: RRecord({
    valid: RLiteral(true),
    messages: RArray(RString),
    user: RRecord({
      id: RString,
      idx: RNumber,
      email: RString,
    }),
    agreement: RRecord({
      is_email_enabled: RBoolean,
      is_app_push_enabled: RBoolean,
      recognized_date: RString,
    }),
  }),
});

const RAccountSignupCreateAccountFailure = RRecord({
  success: RLiteral(true),
  message: RNull,
  data: RRecord({
    valid: RLiteral(false),
    messages: RArray(RString),
    email: RNull,
    u_idx: RNull,
    u_id: RNull,
  }),
});

export const RAccountSignupCreateAccount = RUnion(
  RAccountSignupCreateAccountSuccess,
  RAccountSignupCreateAccountFailure,
);
export type AccountSignupCreateAccount = RStatic<typeof RAccountSignupCreateAccount>;
export type AccountSignupCreateAccountSuccess = RStatic<typeof RAccountSignupCreateAccountSuccess>;
