import { NewReleasesModel } from '@/models/backendsApi/v1/NewReleases/NewReleasesModel';
import { ServiceMethod } from '@/services/baseService';

import { makeLegacyCallAPIMethod } from '../../backendsApiService';
import { NewReleaseRequest } from './interfaces/NewReleasesRequest';

const call = makeLegacyCallAPIMethod(NewReleasesModel);

export type NewReleasesGenre =
  | 'general'
  | 'romance'
  | 'fantasy'
  | 'comic'
  | 'bl-comic'
  | 'bl-webtoon'
  | 'bl-webnovel'
  | 'bl-novel'
  | 'romance-serial'
  | 'webtoon'
  | 'fantasy-serial';
export type NewReleasesQuery = {
  category?: number[];
  '!category'?: number[];
  adults_only?: '0' | '1';
};

export const newReleasesQueryBuilder = (query: NewReleasesQuery): string => {
  const keys = Object.keys(query);
  return keys
    .reduce((acc, key) => {
      const item = query[key as keyof NewReleasesQuery];
      if (item && (key === 'category' || key === '!category')) {
        acc.push(
          (Array.isArray(item) ? item : [item])
            .map(Number)
            .filter((categoryId: number) => categoryId > 0)
            .map((categoryId: number) => `${key}:${categoryId}`)
            .join(' and '),
        );
      } else if (key === 'adults_only' && ['0', '1'].includes(item as '0' | '1')) {
        acc.push(`${key}:${String(item)}`);
      }
      return acc;
    }, [] as string[])
    .join(' and ');
};

export const newReleases: ServiceMethod<NewReleaseRequest, NewReleasesModel> = async reqParams =>
  call<NewReleaseRequest, Error, NewReleasesModel>({
    method: 'GET',
    pathname: '/v1/new-releases',
    params: reqParams,
  });
