import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';

import { hydrateAction } from '@/features/hydrate';
import type { State } from '@/features/rootReducer';
import type { ActionRequest, RootState } from '@/features/store';
import type { UserDevice } from '@/models/account/UserDevices/UserDeviceType';
import type {
  RemoveUserDeviceRequest,
  UpdateUserDeviceRequest,
} from '@/services/account/userDevices/interfaces/userDevicesRequest';
import {
  fetchUserDevices,
  FetchUserDevicesError,
  removeUserDevice,
  RemoveUserDeviceError,
  updateUserDevice,
  UpdateUserDeviceError,
} from '@/services/account/userDevices/userDevicesService';
import type { RequestError } from '@/utils/request';

export type UserDevicesState = UserDevice[];

const initialState: UserDevicesState = [];

export const userDevicesSelector = createSelector(
  (state: State) => state.account.userDevices,
  userDevices => userDevices,
);

export const fetchUserDevicesAction = createAsyncThunk<
  UserDevice[],
  ActionRequest<void>,
  {
    rejectValue: RequestError<FetchUserDevicesError>;
  }
>('account/userDevices/fetchUserDevicesAction', async ({ req }, { rejectWithValue }) => {
  const [error, result] = await fetchUserDevices(undefined, req);

  if (error) {
    return rejectWithValue(error);
  }

  return result.Data.user_devices;
});

export const updateUserDeviceAction = createAsyncThunk<
  UserDevice,
  ActionRequest<UpdateUserDeviceRequest>,
  {
    rejectValue: RequestError<UpdateUserDeviceError>;
    state: RootState;
  }
>('account/userDevices/updateUserDeviceAction', async ({ reqParams, req }, { rejectWithValue }) => {
  const [error, model] = await updateUserDevice(reqParams, req);
  if (error) {
    return rejectWithValue(error);
  }
  return model.Data.user_device;
});

export const removeUserDeviceAction = createAsyncThunk<
  number,
  ActionRequest<RemoveUserDeviceRequest>,
  {
    rejectValue: RequestError<RemoveUserDeviceError>;
    state: RootState;
  }
>('account/userDevices/removeUserDeviceAction', async ({ reqParams, req }, { rejectWithValue }) => {
  const [error] = await removeUserDevice(reqParams, req);

  if (error) {
    return rejectWithValue(error);
  }

  return reqParams.params.id;
});

export const userDevicesSlice = createSlice({
  name: 'account/userDevices',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(hydrateAction, (_, action) => action.payload.account.userDevices)
      .addCase(fetchUserDevicesAction.fulfilled, (_, action) => action.payload)
      .addCase(updateUserDeviceAction.fulfilled, (state, action) => {
        const replaceTargetIndex = state.findIndex(device => device.id === action.payload.id);
        if (replaceTargetIndex !== -1) {
          state[replaceTargetIndex] = action.payload;
        }
      })
      .addCase(removeUserDeviceAction.fulfilled, (state, action) => {
        const nextState = state.filter(device => device.id !== action.payload);
        return nextState;
      });
  },
});
