import { AccountActionValidateResultModel } from '@/models/booksBackend/ValidateResultModel/AccountActionValidateResultModel';
import { ServiceMethod, stringifyRequestParams } from '@/services/baseService';
import { getFeatureFlagValueByKey } from '@/utils/featureFlag';

import { makeCallAPIMethod } from '../booksBackendService';
import { AccountActionIsCurrentPasswordRequest } from './interfaces/AccountActionIsCurrentPasswordRequest';

export const accountActionIsCurrentPassword: ServiceMethod<
  AccountActionIsCurrentPasswordRequest,
  AccountActionValidateResultModel
> = async (reqParams, req) => {
  const call = makeCallAPIMethod(
    !!getFeatureFlagValueByKey({
      featureFlag: req?.FeatureFlag || reqParams.featureFlag,
      featureFlagKey: 'web-internal-books-backendsapi-request-20240813',
    }),
  )(AccountActionValidateResultModel);
  return call<AccountActionIsCurrentPasswordRequest, Error, AccountActionValidateResultModel>(
    {
      method: 'POST',
      pathname: '/account/action/is-current-password',
      preferNonProxied: true,
      params: { body: reqParams.body },
      transformRequest: [stringifyRequestParams],
    },
    req,
  );
};
