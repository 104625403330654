import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';

import { hydrateAction } from '@/features/hydrate';
import type { ActionRequest, AppDispatch, RootState } from '@/features/store';
import type { PaymentRouteMethod } from '@/models/booksBackend/PaymentRouteMethod/PaymentRouteMethodType';
import type { PaymentRouteMethodRequest } from '@/services/booksBackend/paymentRouteMethod/interfaces/PaymentRouteMethodRequest';
import { paymentRouteMethod } from '@/services/booksBackend/paymentRouteMethod/paymentRouteMethodService';

import type { PaymentRouteRootState } from '..';

export interface PaymentRouteMethodState {
  paymentRouteMethod: PaymentRouteMethod | null;
  isLoading: boolean;
}

export const fetchPaymentRouteMethodAction = createAsyncThunk<
  PaymentRouteMethod,
  ActionRequest<PaymentRouteMethodRequest>,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: { message: string; isLoginFailure: boolean };
  }
>('inapp/checkout/paymentRoute/fetchPaymentRouteMethodAction', async ({ reqParams, req }, thunkAPI) => {
  const [error, model] = await paymentRouteMethod(reqParams, req);

  if (error) {
    return thunkAPI.rejectWithValue({
      message: error.message,
      isLoginFailure: error.response?.status === 401,
    });
  }

  return model.Data;
});

export const paymentRouteMethodSelector = createSelector(
  (state: RootState) => state.inapp.checkout.paymentRoute,
  (state: PaymentRouteRootState) => state.paymentRouteMethod,
);

export const paymentRouteMethodSlice = createSlice({
  name: 'inapp/checkout/paymentRoute/paymentRouteMethod',

  initialState: {
    paymentRouteMethod: null,
    isLoading: false,
  } as PaymentRouteMethodState,

  reducers: {},

  extraReducers: builder => {
    builder.addCase(fetchPaymentRouteMethodAction.fulfilled, (state, action) => {
      state.paymentRouteMethod = action.payload;
      state.isLoading = false;
    });
    builder.addCase(fetchPaymentRouteMethodAction.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(fetchPaymentRouteMethodAction.rejected, state => {
      state.isLoading = false;
    });

    builder.addCase(hydrateAction, (state, action) => {
      const nextState = {
        ...state,
        ...action.payload.inapp.checkout.paymentRoute.paymentRouteMethod,
      };

      if (state.paymentRouteMethod !== null && nextState.paymentRouteMethod === null) {
        nextState.paymentRouteMethod = state.paymentRouteMethod;
        nextState.isLoading = state.isLoading;
      }

      return nextState;
    });
  },
});
