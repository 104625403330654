import { AccountParentalConsentRequiredDataModel } from '@/models/backendsApi/v1/AccountParentalConsentRequiredData/AccountParentalConsentRequiredDataModel';
import { ServiceMethod } from '@/services/baseService';
import { getFeatureFlagValueByKey } from '@/utils/featureFlag';

import { makeCallAPIMethod } from '../../backendsApiService';
import { AccountParentalConsentRequiredDataRequest } from './interfaces/AccountParentalConsentRequiredDataRequest';

export interface AccountParentalConsentRequiredDataError {
  success: false;
  message: string;
  data: null;
}

export const accountParentalConsentRequiredData: ServiceMethod<
  AccountParentalConsentRequiredDataRequest,
  AccountParentalConsentRequiredDataModel,
  AccountParentalConsentRequiredDataError
> = async (reqParams?, req?, _?) => {
  const call = makeCallAPIMethod(
    !!getFeatureFlagValueByKey({
      featureFlag: req?.FeatureFlag || reqParams?.featureFlag,
      featureFlagKey: 'web-internal-backendsapi-request-20240710',
    }),
  )(AccountParentalConsentRequiredDataModel);

  return call<
    AccountParentalConsentRequiredDataRequest,
    AccountParentalConsentRequiredDataError,
    AccountParentalConsentRequiredDataModel
  >(
    {
      method: 'GET',
      pathname: '/v1/account/parental-consent/required-data',
      preferNonProxied: true,
    },
    req,
  );
};
