import {
  Array as RArray,
  Literal as RLiteral,
  Null as RNull,
  Record as RRecord,
  Static as RStatic,
  String as RString,
  Union as RUnion,
  Unknown as RUnknown,
} from 'runtypes';

import { RGroupSectionsItemsResponse } from '../GroupSections/Items/GroupSectionsItemsType';
import { RSectionItems } from '../SectionItems/SectionItemsType';
import { RSectionItemBadge, RSectionItemBook } from '../Views/ViewsType';

const RSectionItemsResourceBase = RRecord({
  success: RLiteral(true),
  message: RNull,
  data: RUnknown,
});
const RSectionItemsResourceData = RRecord({
  items: RArray(
    RRecord({
      book: RSectionItemBook,
      badges: RArray(RSectionItemBadge),
    }),
  ),
  pagination: RRecord({
    prev_page: RString,
    next_page: RString,
  }),
});

export const RAiRecommendations = RSectionItemsResourceBase.extend({
  data: RSectionItemsResourceData.extend({ title: RRecord({ user_id: RString, postfix: RString }) }),
});
export type AiRecommendations = RStatic<typeof RAiRecommendations>;
export const RBestSellers = RSectionItemsResourceBase.extend({
  data: RSectionItemsResourceData.extend({ recommended_bestseller: RNull }),
});
export type BestSellers = RStatic<typeof RBestSellers>;

export const RSectionItemsResource = RUnion(
  RAiRecommendations,
  RBestSellers,
  RSectionItems,
  RGroupSectionsItemsResponse,
);
export type SectionItemsResource = RStatic<typeof RSectionItemsResource>;

export const RSectionItemsResourceError = RRecord({
  success: RLiteral(false),
  message: RString,
  data: RNull,
});

export type SectionItemsResourceError = RStatic<typeof RSectionItemsResourceError>;
