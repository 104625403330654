import {
  Literal as RLiteral,
  Never as RNever,
  Number as RNumber,
  Runtype,
  String as RString,
  Tuple as RTuple,
} from 'runtypes';

const DEAUTH_WATCHDOG_TOKEN_VERSION = 2;

/*
 * Runtype Utils
 * =====
 */
type RDataValue<K extends string, T> = Runtype<T> & { dataKey: K };
const RData = <K extends string, T>(key: K, schema: Runtype<T>) =>
  Object.create(schema, { dataKey: { value: key } }) as RDataValue<K, T>;

/* eslint-disable @typescript-eslint/ban-types */
type Deserialized<T> =
  T extends RTuple<infer Components>
    ? Components extends [RDataValue<infer Key, infer Type>, ...infer Rest]
      ? Rest extends Runtype[]
        ? Deserialized<RTuple<Rest>> & { [K in Key]: Type }
        : {}
      : {}
    : {};
/* eslint-enable @typescript-eslint/ban-types */

const REnumObject = <K extends Record<string, string | boolean | number | null>>(enumObject: K) =>
  Object.values(enumObject).reduce<Runtype<K[keyof K]>>(
    (union, value) => union.Or(RLiteral(value as K[keyof K])),
    RNever,
  );

/*
 * Type Definitions
 * =====
 */
enum EventTypes {
  LIFECYCLE_START = 0,
  LIFECYCLE_LANDING = 1,
  LIFECYCLE_UPDATE = 2,
  LIFECYCLE_END = 3,
  LIFECYCLE_ANOMALY = 4,
}

enum AnomalyTypes {
  ANOMALY_REFRESH_DOWNGRADE = 0,
  ANOMALY_SOME_COOKIES_GONE = 1,
  ANOMALY_DEAUTHENTICATED = 2,
  ANOMALY_AUTOLOGIN_DOWNGRADE = 3,
  ANOMALY_PHPSESS_GONE = 4,
}

const RAnomalyTypes = REnumObject(AnomalyTypes);

enum CookieStatus {
  NOTHING = 0,
  AT = 1,
  RT = 2,
  AT_RT = 3,
  PHPSESS = 4,
  AT_PHPSESS = 5,
  RT_PHPSESS = 6,
  AT_RT_PHPSESS = 7,
}

const RCookieStatus = REnumObject(CookieStatus);

enum ServerTokenRefreshReason {
  NO_REFRESH = 0,
  REFRESH = 1,
  PHPSESS_GONE = 2,
}

const RServerTokenRefreshReason = REnumObject(ServerTokenRefreshReason);

const Bool = {
  FALSE: 0,
  TRUE: 1,
};

const RBool = REnumObject(Bool);

const RServerToken = RTuple(
  RData('timestamp', RNumber),
  RData('atExp', RNumber),
  RData('rtExp', RNumber),
  RData('phpsessExp', RNumber),
  RData('refreshReason', RServerTokenRefreshReason),
  RData('uidx', RNumber),
  RData('fingerprint', RString),
);

// Event Definitions
const REventLifecycleStart = RTuple(
  RData('timestamp', RNumber),
  RData('uidx', RNumber),
  RData('atExp', RNumber),
  RData('rtExp', RNumber),
);

const REventLifecycleLanding = RTuple(
  RData('timestamp', RNumber),
  RData('sessionId', RString),
  RData('urlId', RNumber),
  RData('uidx', RNumber),
);

const REventLifecycleUpdate = RTuple(
  RData('timestamp', RNumber),
  RData('reasonId', RNumber),
  RData('cookieStatus', RCookieStatus),
  RData('isAutoLogin', RBool),
  RData('lastRefresh', RNumber),
  RData('lastRefreshAtExp', RNumber),
  RData('lastRefreshRtExp', RNumber),
  RData('lastRefreshPhpsessExp', RNumber),
  RData('lastRefreshReason', RServerTokenRefreshReason),
  RData('isAutoLoginExist', RBool),
  RData('isAutoLoginActive', RBool),
  RData('issuedAt', RNumber),
  RData('ffidId', RNumber),
);

const REventLifecycleAnomaly = RTuple(RData('timestamp', RNumber), RData('type', RAnomalyTypes));

const EventSchemas = {
  [EventTypes.LIFECYCLE_START]: REventLifecycleStart,
  [EventTypes.LIFECYCLE_LANDING]: REventLifecycleLanding,
  [EventTypes.LIFECYCLE_UPDATE]: REventLifecycleUpdate,
  [EventTypes.LIFECYCLE_ANOMALY]: REventLifecycleAnomaly,

  [EventTypes.LIFECYCLE_END]: RTuple(),
};

type EventSchema<T extends EventTypes> = Deserialized<(typeof EventSchemas)[T]>;

export {
  AnomalyTypes,
  CookieStatus,
  DEAUTH_WATCHDOG_TOKEN_VERSION,
  EventSchemas,
  EventTypes,
  REventLifecycleAnomaly,
  REventLifecycleLanding,
  REventLifecycleStart,
  REventLifecycleUpdate,
  RServerToken,
  RServerTokenRefreshReason,
  ServerTokenRefreshReason,
};

export type { EventSchema };

/*
 * Utilities
 * ====
 */
export const deserializeToObject = <T extends RTuple<RDataValue<string, unknown>[]>>(
  schema: T,
  token: string,
  delimiter = ',',
): Deserialized<T> | null => {
  const value = token.split(delimiter).map(element => (/^-?\d+$/.test(element) ? parseInt(element, 10) : element));
  if (!schema.guard(value)) {
    return null;
  }

  return schema.components.reduce<Record<string, unknown>>((deserialized, component, index) => {
    // eslint-disable-next-line no-param-reassign
    deserialized[component.dataKey] = value[index];
    return deserialized;
  }, {}) as Deserialized<T>;
};

export const serializeToToken = <T extends RTuple<RDataValue<string, unknown>[]>>(
  schema: T,
  deserialized: Deserialized<T>,
  delimiter = ',',
): string =>
  schema.components.map(value => String(deserialized[value.dataKey as keyof Deserialized<T>])).join(delimiter);
