import { createSlice } from '@reduxjs/toolkit';

import { hydrateAction } from '@/features/hydrate';
import { Checkout, OrderType } from '@/models/backendsApi/v1/Checkout/CheckoutType';

import {
  checkoutAction,
  checkoutInitializeAction,
  checkoutInternalActions,
  checkoutRefreshFinishAction,
  checkoutRefreshStartAction,
} from './actions';

export type CheckoutRequestInfo =
  | {
      orderItemType: 'book';
      bookIds: string[];
    }
  | {
      orderItemType: 'episode';
      serialId: string;
      episodeIds?: string[];
      excludedEpisodeIds?: string[];
      excludeOwnedEpisodes: boolean;
    };

export interface CheckoutState {
  purchase: Checkout['data'] | null;
  rental: Checkout['data'] | null;
  orderType: OrderType;
  requestInfo: CheckoutRequestInfo | null;
  requestStatus: {
    desiredOrderType: OrderType;
    isRefreshing: boolean;
    lastError: string | true | null;
  } & {
    [K in OrderType]: {
      isLoading: boolean;
      lastRequest: number;
    };
  };
}

const initialState: CheckoutState = {
  purchase: null,
  rental: null,
  orderType: 'rental',
  requestInfo: null,
  requestStatus: {
    purchase: {
      lastRequest: 0,
      isLoading: false,
    },
    rental: {
      lastRequest: 0,
      isLoading: false,
    },
    desiredOrderType: 'rental',
    isRefreshing: false,
    lastError: null,
  },
};

export const checkoutSlice = createSlice({
  name: 'inapp/checkout/checkout',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(hydrateAction, (state, action) => {
      const checkoutState = action.payload.inapp.checkout.checkout;
      return { ...state, ...checkoutState };
    });

    builder.addCase(checkoutInitializeAction, (state, action) => {
      const { orderType, requestInfo } = action.payload;
      state.requestStatus[orderType].isLoading = true;
      state.requestStatus.desiredOrderType = orderType;
      state.orderType = orderType;
      state.requestInfo = requestInfo;
    });

    builder.addCase(checkoutInternalActions.checkoutStartLoadingAction, (state, action) => {
      const { orderType, timestamp } = action.payload;

      state.requestStatus.desiredOrderType = orderType;
      state.requestStatus[orderType].isLoading = true;
      state.requestStatus[orderType].lastRequest = timestamp;
    });

    builder.addCase(checkoutInternalActions.checkoutSetOrderTypeAction, (state, action) => {
      const { orderType } = action.payload;
      state.requestStatus.desiredOrderType = orderType;
      state.orderType = orderType;
    });

    builder.addCase(checkoutInternalActions.checkoutSetDesiredOrderTypeAction, (state, action) => {
      const { orderType } = action.payload;
      state.requestStatus.desiredOrderType = orderType;
    });

    builder.addCase(checkoutInternalActions.checkoutInvalidateAction, (state, action) => {
      const { before } = action.payload;
      (['purchase', 'rental'] as OrderType[]).forEach(orderType => {
        if (state.requestStatus[orderType].lastRequest < before) {
          state[orderType] = null;
        }
      });
    });

    builder
      .addCase(checkoutRefreshStartAction, state => {
        state.requestStatus.isRefreshing = true;
      })
      .addCase(checkoutRefreshFinishAction, state => {
        state.requestStatus.isRefreshing = false;
      });

    builder
      .addCase(checkoutAction.fulfilled, (state, action) => {
        const {
          timestamp,
          orderType,
          data: { data: checkoutData },
        } = action.payload;

        // 현재 요청보다 더 최근에, 같은 orderType에 대한 요청이 시작됐으면 가져온 값을 버린다.
        if (state.requestStatus[orderType].lastRequest > timestamp) {
          return;
        }

        // 이 요청이 이 orderType에 대해서는 가장 최신의 값이므로 값을 업데이트 한다.
        state[orderType] = checkoutData;
        state.requestStatus[orderType].isLoading = false;

        // 유저가 가장 최근에 선택한 orderType이 이 orderType이라면,
        // 이 요청이 유저의 가장 최신 요청이므로 orderType을 바꾼다.
        if (state.requestStatus.desiredOrderType === orderType) {
          state.orderType = orderType;
        }
      })
      .addCase(checkoutAction.rejected, (state, action) => {
        const message = action.payload?.data?.message;
        state.requestStatus.lastError = message ?? true;
      });
  },
});

export type CheckoutReducerType = ReturnType<typeof checkoutSlice.reducer>;

export * from './actions';
export * from './selectors';
