import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { hydrateAction } from '@/features/hydrate';
import type { ActionRequest, AppDispatch, RootState } from '@/features/store';
import { ScheduleEvents } from '@/models/booksBackend/ScheduleEvents/ScheduleEventsType';
import { scheduleEvents } from '@/services/booksBackend/scheduleEvents/scheduleEventsService';

export interface ScheduleEventsState {
  events: ScheduleEvents | null;
  isLoading: boolean;
}

export const fetchScheduleEventsAction = createAsyncThunk<
  ScheduleEvents,
  ActionRequest<void>,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>('global/globalNavigationBar/scheduleEvents/fetchScheduleEventsAction', async ({ req }, thunkAPI) => {
  const [error, model] = await scheduleEvents(undefined, req);

  if (error) {
    return thunkAPI.rejectWithValue({
      message: error.message,
      data: error.response?.data,
    });
  }

  return model.Data;
});

export const scheduleEventsSelector = (state: RootState): ScheduleEventsState =>
  state.global.globalNavigationBar.scheduleEvents;

export const scheduleEventsSlice = createSlice({
  name: 'global/globalNavigationBar/scheduleEvents',

  initialState: {
    events: null,
    isLoading: true,
  } as ScheduleEventsState,

  reducers: {},

  extraReducers: builder => {
    builder.addCase(fetchScheduleEventsAction.fulfilled, (state, action) => {
      state.events = action.payload;
      state.isLoading = false;
    });

    builder.addCase(hydrateAction, (state, action) => {
      const nextState = {
        ...state,
        ...scheduleEventsSelector(action.payload),
      };

      if (state.events !== null && nextState.events === null) {
        nextState.events = state.events;
        nextState.isLoading = state.isLoading;
      }

      return nextState;
    });
  },
});
