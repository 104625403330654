import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { User } from '@/base/interfaces/User';
import { isServer } from '@/services/baseService';
import { authVariables } from '@/services/global/authVariables/authVariablesService';
import { getParsedCookie } from '@/utils/cookie';

export const hydrateAuthVariablesAction = createAsyncThunk('global/hydrateAuthVariablesAction', async (_, thunkAPI) => {
  const [error, model] = await authVariables();
  if (error) {
    return thunkAPI.rejectWithValue(error);
  }

  return { loggedUser: model.Data.auth.loggedUser, variables: model.Data.variables.variables };
});

export const hydrateTemporaryAuthAction = createAction('global/hydrateTemporaryAuthVariablesAction', () => {
  if (isServer()) {
    return { payload: { loggedUser: null } };
  }

  const authData = getParsedCookie(window.document.cookie)?.ridi_auth;
  if (authData) {
    try {
      const user = JSON.parse(authData) as Omit<User, 'email'>;
      return { payload: { loggedUser: { ...user, email: '' } } };
    } catch {
      // Just treat as non-logged-in user
    }
  }

  return { payload: { loggedUser: null } };
});
