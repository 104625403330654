import { createContext } from 'react';

import {
  createIntersectionObserverProvider,
  createUseIntersectionObserverRef,
  IntersectionObserverContextType,
} from '@/utils/intersectionObserverProvider';

const ViewEventObserverContext = createContext<IntersectionObserverContextType>(null);
export const ViewEventObserverProvider = createIntersectionObserverProvider(ViewEventObserverContext, {
  threshold: 0.5,
});
export const useViewEventObserverRef = createUseIntersectionObserverRef(ViewEventObserverContext);

/**
 * 자동 장르홈을 위해 threshold 0로 설정한 임시 IntesectionObserverProvider
 * TODO: 추후 threshold를 사용처에서 자유롭게 설정할 수 있는 방법이 필요
 */
const ZeroThresholdViewEventObserverContext = createContext<IntersectionObserverContextType>(null);
export const ZeroThresholdViewEventObserverProvider = createIntersectionObserverProvider(
  ZeroThresholdViewEventObserverContext,
  {
    threshold: 0,
  },
);
export const useZeroThresholdViewEventObserverRef = createUseIntersectionObserverRef(
  ZeroThresholdViewEventObserverContext,
);
