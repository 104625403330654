import {
  Array as RArray,
  Boolean as RBoolean,
  Dictionary as RDictionary,
  Number as RNumber,
  Optional as ROptional,
  Partial as RPartial,
  Record as RRecord,
  Static as RStatic,
  String as RString,
} from 'runtypes';

import { RAuthor } from '@/base/interfaces/Author';

const RBookApiCategory = RRecord({
  ancestor_ids: RArray(RNumber),
  genre: RString,
  id: RNumber,
  is_series_category: RBoolean,
  name: RString,
  sub_genre: RString,
});

export type BookApiCategory = RStatic<typeof RBookApiCategory>;

const RBookApiPriceItem = RRecord({
  regular_price: RNumber,
  price: RNumber,
  discount_percentage: RNumber,
});

const RBookApiPaperPriceItem = RRecord({
  price: RNumber,
});

const RBookApiRentPriceItem = RBookApiPriceItem.And(
  RRecord({
    rent_days: RNumber,
  }),
);

const RBookApiSeriesPriceItem = RBookApiPriceItem.And(
  RRecord({
    total_book_count: RNumber,
    free_book_count: RNumber,
  }),
);

const RBookApiSeriesRentPriceItem = RRecord({
  regular_price: RNumber,
  rent_price: RNumber,
  discount_percentage: RNumber,
  rent_days: RNumber,
  total_book_count: RNumber,
  free_book_count: RNumber,
});

const RBookApiPriceInfo = RPartial({
  buy: RBookApiPriceItem,
  rent: RBookApiRentPriceItem,
  paper: RBookApiPaperPriceItem,
});

export type BookApiPriceInfo = RStatic<typeof RBookApiPriceInfo>;

const RBookApiSeriesPriceInfo = RPartial({
  buy: RBookApiSeriesPriceItem,
  rent: RBookApiSeriesRentPriceItem,
});

const RBookApiSeries = RRecord({
  id: RString,
  volume: RNumber,
  property: RRecord({
    last_volume_id: RString,
    opened_last_volume_id: RString,
    title: RString.nullable(),
    unit: RString.nullable(),
    opened_book_count: RNumber,
    total_book_count: RNumber,
    is_serial: RBoolean,
    is_completed: RBoolean,
    is_comic_hd: RBoolean,
    is_serial_complete: RBoolean,
    is_wait_free: RBoolean,
    next_preview_b_id: RString.optional(),
    prev_books: RArray(RBoolean).optional(),
    next_books: RDictionary(
      RRecord({
        b_id: RString,
        is_opened: RBoolean,
      }),
      RString,
    ).optional(),
  }),
  price_info: ROptional(RBookApiSeriesPriceInfo),
});

export type BookApiSeries = RStatic<typeof RBookApiSeries>;

export const RBookApiPublisher = RRecord({
  id: ROptional(RNumber),
  name: RString,
  cp_name: RString,
});

export type BookApiPublisher = RStatic<typeof RBookApiPublisher>;

export const RBookApiResponseUnit = RRecord({
  id: RString,
  title: RRecord({
    main: RString,
    sub: ROptional(RString),
    prefix: ROptional(RString),
  }),
  thumbnail: RRecord({
    small: RString,
    large: RString,
    xxlarge: RString,
  }),
  authors: RArray(RAuthor),
  categories: RArray(RBookApiCategory).withConstraint(arr => arr.length > 0),
  price_info: RBookApiPriceInfo,
  file: RRecord({
    size: RNumber,
    format: RString.nullable(),
    is_drm_free: RBoolean,
    is_comic: RBoolean,
    is_webtoon: RBoolean,
    is_manga: RBoolean,
    is_comic_hd: RBoolean,
    character_count: ROptional(RNumber),
    page_count: ROptional(RNumber),
  }),
  property: RRecord({
    is_novel: RBoolean,
    is_magazine: RBoolean,
    is_adult_only: RBoolean,
    is_new_book: RBoolean,
    is_open: RBoolean,
    is_somedeal: RBoolean,
    is_trial: RBoolean,
    preview_rate: RNumber,
    preview_max_characters: RNumber.optional(),
    preview_max_pages: RNumber.optional(),
    review_display_id: ROptional(RString),
  }),
  support: RRecord({
    android: RBoolean,
    ios: RBoolean,
    mac: RBoolean,
    paper: RBoolean,
    windows: RBoolean,
    web_viewer: RBoolean,
  }),
  publish: RRecord({
    ridibooks_register: RString,
    ebook_publish: RString,
    ridibooks_publish: RString,
    paper_book_publish: RString.optional(),
  }),
  publisher: RBookApiPublisher,
  series: ROptional(RBookApiSeries),
  is_deleted: ROptional(RBoolean),
  setbook: ROptional(
    RRecord({
      member_books_count: RNumber,
    }),
  ),
});

export type BookApiResponseUnit = RStatic<typeof RBookApiResponseUnit>;
export const RBookApiResponse = RArray(RBookApiResponseUnit);
export type BookApiResponse = RStatic<typeof RBookApiResponse>;
