import { captureEvent, withScope } from '@sentry/nextjs';
import axios from 'axios';

import { DEAUTH_WATCHDOG_SERVER_TOKEN_KEY } from '@/base/constants';
import { Oauth2Token } from '@/models/account/Oauth2Token/Oauth2TokenType';
import { isServiceFailure } from '@/services/baseService';
import { deauthWatchdog } from '@/services/labs/deauthWatchdog';

import { getParsedCookie } from '../cookie';
import {
  getStorageItemJSON,
  getStorageItemRaw,
  LOCAL_STORAGE_KEYS,
  SESSION_STORAGE_KEYS,
  setStorageItemJSON,
  setStorageItemRaw,
} from '../storage';
import {
  AnomalyTypes,
  CookieStatus,
  DEAUTH_WATCHDOG_TOKEN_VERSION,
  deserializeToObject,
  EventSchema,
  EventSchemas,
  EventTypes,
  RServerToken,
  serializeToToken,
  ServerTokenRefreshReason,
} from './serialization';

const MAX_TABLE_SIZE = 256;
const MAX_DEFAULT_STREAM_SIZE = 32;
const MAX_STREAM_SIZE: { [K in EventTypes]?: number } = {
  [EventTypes.LIFECYCLE_UPDATE]: 160,
};

type Storage = {
  streams: { key: number; tokens: string[] }[];
  table: { items: Map<string, number>; size: number };
};

const getDefaultStorage = () => ({
  version: DEAUTH_WATCHDOG_TOKEN_VERSION,
  streams: [],
  table: {},
  userAgent: typeof window !== 'undefined' ? window.navigator.userAgent : '',
});

const flushStorage = (storage: Storage) => {
  setStorageItemJSON('local', LOCAL_STORAGE_KEYS.DEAUTH_WATCHDOG, {
    version: DEAUTH_WATCHDOG_TOKEN_VERSION,
    streams: storage.streams,
    table: Object.fromEntries(storage.table.items),
    userAgent: window.navigator.userAgent,
  });
};

const purgeStorage = () => setStorageItemJSON('local', LOCAL_STORAGE_KEYS.DEAUTH_WATCHDOG, getDefaultStorage());

const openStorage = (): Storage => {
  let storage = getStorageItemJSON('local', LOCAL_STORAGE_KEYS.DEAUTH_WATCHDOG);
  if (storage?.version !== DEAUTH_WATCHDOG_TOKEN_VERSION) {
    storage = getDefaultStorage();
    purgeStorage();
  }

  const stringTable = new Map(Object.entries(storage.table));
  const maxStringId = Math.max(0, ...Array.from(stringTable.values()));

  return { streams: storage.streams, table: { items: stringTable, size: maxStringId } };
};

const addToEventStream = <T extends EventTypes>(storage: Storage, eventType: T, args: EventSchema<T>) => {
  const schema = EventSchemas[eventType];
  const token = serializeToToken(schema, args);

  const previousStreams = storage.streams;
  const previousStream =
    previousStreams.find(({ key }) => key === eventType) ??
    previousStreams[previousStreams.push({ key: eventType, tokens: [] }) - 1];

  previousStream.tokens.push(token);

  const maxTokens = MAX_STREAM_SIZE[eventType] ?? MAX_DEFAULT_STREAM_SIZE;
  previousStream.tokens = previousStream.tokens.slice(-maxTokens);
};

const registerToStringTable = (storage: Storage, value: string): number => {
  if (storage.table.items.has(value)) {
    // Update item ordering to make it LRU
    const id = storage.table.items.get(value) as number;
    storage.table.items.delete(value);
    storage.table.items.set(value, id);
    return id;
  }

  const nextId = storage.table.size + 1;
  storage.table.items.set(value, nextId);

  if (storage.table.items.size > MAX_TABLE_SIZE) {
    const { value: lruKey } = storage.table.items.keys().next() as { value: string };
    storage.table.items.delete(lruKey);
  }

  // eslint-disable-next-line no-param-reassign
  storage.table.size = nextId;

  return nextId;
};

const getLast = <T>(array?: T[]): T | undefined => array && array[array.length - 1];
const getLastEvent = <T extends EventTypes>(storage: Storage, eventType: T): EventSchema<T> | null =>
  deserializeToObject(
    EventSchemas[eventType],
    getLast(storage.streams.find(({ key }) => key === eventType)?.tokens) ?? '',
  );

export const normalizeUrl = (url: URL | Location) => {
  const host = url.origin.replace(/^https?:\/\/(?:([a-z.]*)\.)?[a-z-]+\.[a-z]+(?::\d+)?$/, '$1');
  const path = url.pathname.replace(/\/\d+(?:\/|$)/g, '').replace(/\//g, ':');

  return `${host}:${path}`;
};

/*
 * Anomalies
 * ====
 */
const checkAnomaliesOnUpdate = (
  storage: Storage,
  event: EventSchema<EventTypes.LIFECYCLE_UPDATE>,
): AnomalyTypes | null => {
  /* eslint-disable no-bitwise */
  const lastEvent = getLastEvent(storage, EventTypes.LIFECYCLE_UPDATE);
  const isPhpsessHasGone = (cookieStatus: CookieStatus) =>
    !!(cookieStatus & CookieStatus.AT) && !(cookieStatus & CookieStatus.PHPSESS);

  if (isPhpsessHasGone(event.cookieStatus)) {
    if (!(lastEvent && isPhpsessHasGone(lastEvent.cookieStatus))) {
      return AnomalyTypes.ANOMALY_PHPSESS_GONE;
    }
  }

  if (!lastEvent) {
    return null;
  }

  if (lastEvent.lastRefreshRtExp > event.lastRefreshRtExp) {
    return AnomalyTypes.ANOMALY_REFRESH_DOWNGRADE;
  }

  if (lastEvent.isAutoLogin && !event.isAutoLogin) {
    return AnomalyTypes.ANOMALY_AUTOLOGIN_DOWNGRADE;
  }

  /* OldFlag & ~NewFlag
   * ====
   * OldFlag = true, NewFlag = false -> true
   * Otherwise                       -> false
   */
  const isSomeCookieHasGone = (oldFlag: CookieStatus, newFlag: CookieStatus) => !!(oldFlag & ~newFlag);
  if (isSomeCookieHasGone(lastEvent.cookieStatus, event.cookieStatus)) {
    return AnomalyTypes.ANOMALY_SOME_COOKIES_GONE;
  }

  return null;
  /* eslint-enable no-bitwise */
};

const checkAnomaliesOnLanding = (
  storage: Storage,
  event: EventSchema<EventTypes.LIFECYCLE_LANDING>,
): AnomalyTypes | null => {
  const lastEvent = getLastEvent(storage, EventTypes.LIFECYCLE_LANDING);
  if (!lastEvent) {
    return null;
  }

  if (lastEvent.uidx >= 0 && event.uidx < 0) {
    return AnomalyTypes.ANOMALY_DEAUTHENTICATED;
  }

  return null;
};

/*
 * Watchdog
 * ====
 */
export const onLifecycleStart = async (oauth2Token: Oauth2Token) => {
  const storage = openStorage();
  addToEventStream(storage, EventTypes.LIFECYCLE_START, {
    timestamp: Date.now(),
    uidx: oauth2Token.user.idx,
    atExp: oauth2Token.expires_in,
    rtExp: oauth2Token.refresh_token_expires_in,
  });

  flushStorage(storage);
};

const onLifecycleAnomaly = async (type: AnomalyTypes) => {
  const storage = openStorage();
  addToEventStream(storage, EventTypes.LIFECYCLE_ANOMALY, { timestamp: Date.now(), type });
  flushStorage(storage);

  withScope(scope => {
    scope.setTag('type', 'DeauthWatchdog');
    scope.addAttachment({
      data: JSON.stringify(getStorageItemJSON('local', LOCAL_STORAGE_KEYS.DEAUTH_WATCHDOG)),
      filename: `WatchdogToken-${Date.now()}.json`,
    });

    captureEvent(new Error('Deauth Watchdog Bite'));
  });
};

export const onLifecycleLanding = async (uidx?: number) => {
  const storage = openStorage();
  const urlId = registerToStringTable(storage, normalizeUrl(window.location));

  const sessionId = (() => {
    const session = getStorageItemRaw('session', SESSION_STORAGE_KEYS.DEAUTH_WATCHDOG_SESSION);
    if (session) {
      return session;
    }

    const newSession = `_${Math.random().toString(36).slice(2, 7)}`;
    setStorageItemRaw('session', SESSION_STORAGE_KEYS.DEAUTH_WATCHDOG_SESSION, newSession);
    return newSession;
  })();

  const event: EventSchema<EventTypes.LIFECYCLE_LANDING> = {
    timestamp: Date.now(),
    urlId,
    uidx: uidx ?? -1,
    sessionId,
  };

  const anomalyType = checkAnomaliesOnLanding(storage, event);
  addToEventStream(storage, EventTypes.LIFECYCLE_LANDING, event);
  flushStorage(storage);

  if (anomalyType) {
    await onLifecycleAnomaly(anomalyType);
  }
};

const onLifecycleUpdate = async (reason: string) => {
  const serverToken = (() => {
    const serverTokenCookie = getParsedCookie(window.document.cookie)?.[DEAUTH_WATCHDOG_SERVER_TOKEN_KEY];
    return deserializeToObject(RServerToken, serverTokenCookie ?? '') ?? null;
  })();

  const deauthWatchdogResult = await deauthWatchdog();
  if (isServiceFailure(deauthWatchdogResult)) {
    return;
  }

  const { isAutoLogin, isAtValid, isRtValid, isPHPSessionValid, isAutoLoginExist, isAutoLoginActive, issuedAt, ffid } =
    deauthWatchdogResult[1].Data;

  /* eslint-disable no-bitwise */
  const cookieStatus =
    (isAtValid ? CookieStatus.AT : CookieStatus.NOTHING) |
    (isRtValid ? CookieStatus.RT : CookieStatus.NOTHING) |
    (isPHPSessionValid ? CookieStatus.PHPSESS : CookieStatus.NOTHING);
  /* eslint-enable no-bitwise */

  const storage = openStorage();
  const reasonId = registerToStringTable(storage, reason);
  const ffidId = registerToStringTable(storage, ffid);
  const event: EventSchema<EventTypes.LIFECYCLE_UPDATE> = {
    timestamp: Date.now(),
    reasonId,
    lastRefresh: serverToken?.timestamp ?? -1,
    lastRefreshAtExp: serverToken?.atExp ?? -1,
    lastRefreshRtExp: serverToken?.rtExp ?? -1,
    lastRefreshPhpsessExp: serverToken?.phpsessExp ?? -1,
    lastRefreshReason: serverToken?.refreshReason ?? ServerTokenRefreshReason.NO_REFRESH,
    isAutoLogin: isAutoLogin ? 1 : 0,
    cookieStatus: cookieStatus as CookieStatus,
    isAutoLoginExist: isAutoLoginExist ? 1 : 0,
    isAutoLoginActive: isAutoLoginActive ? 1 : 0,
    issuedAt,
    ffidId,
  };

  const anomalyType = checkAnomaliesOnUpdate(storage, event);
  addToEventStream(storage, EventTypes.LIFECYCLE_UPDATE, event);
  flushStorage(storage);

  if (anomalyType) {
    await onLifecycleAnomaly(anomalyType);
  }
};

const onLifecycleEnd = () => {
  purgeStorage();
};

const isWatchdogRequest = (requestUrl?: string): boolean => !requestUrl || requestUrl.includes('deauth-watchdog');

let isInterceptorsAdded = false;
export const HOOKS = [
  {
    name: 'onLogoutRunEnd',
    run() {
      window.document.querySelector('.btn_logout')?.addEventListener('click', onLifecycleEnd);
    },
  },
  {
    name: 'onRequestRunUpdate',
    run() {
      if (isInterceptorsAdded) {
        return;
      }

      isInterceptorsAdded = true;
      axios.interceptors.response.use(
        response => {
          if (!isWatchdogRequest(response.config.url)) {
            let reason = 'api-fetch';

            try {
              if (response.config.url) {
                const url = new URL(response.config.url, window.location.href);
                reason = normalizeUrl(url);
              }
            } catch {
              // eslint-disable-next-line no-empty
            }
            setTimeout(() => onLifecycleUpdate(reason), 1000);
          }

          return response;
        },
        error => {
          if (error && typeof error === 'object' && 'config' in error) {
            const { config } = error as { config: { url: string } };

            if (isWatchdogRequest(config.url)) {
              return Promise.reject(error);
            }
          }

          setTimeout(() => onLifecycleUpdate('api-error'), 1000);
          return Promise.reject(error);
        },
      );
    },
  },
];

export const initializeByLanding = async ({ uidx }: { uidx?: number }) => {
  HOOKS.forEach(({ run }) => run());
  return onLifecycleLanding(uidx);
};

export const initializeByOauth2Token = async (oauth2Token: Oauth2Token) => onLifecycleStart(oauth2Token);
