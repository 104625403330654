import {
  Array as RArray,
  Boolean as RBoolean,
  Lazy as RLazy,
  Number as RNumber,
  Record as RRecord,
  Runtype as RRuntype,
  Static as RStatic,
  String as RString,
} from 'runtypes';

export type Navigation = {
  id: number;
  title: string;
  path: string;
  image_url: string | null;
  resource_url: string | null;
  is_default: boolean;
  view_id: number | null;
  children: Navigation[];
  genre_change_sheet?: {
    web: {
      light: {
        title: string;
        image_url: string;
      }[];
      dark?: {
        title: string;
        image_url: string;
      }[];
    };
  };
};

export const RGenreChangeSheet = RRecord({
  web: RRecord({
    light: RArray(
      RRecord({
        title: RString,
        image_url: RString,
      }),
    ),
    dark: RArray(
      RRecord({
        title: RString,
        image_url: RString,
      }),
    ).optional(),
  }),
});

export const RNavigation: RRuntype<Navigation> = RLazy(() =>
  RRecord({
    id: RNumber,
    title: RString,
    path: RString,
    image_url: RString.nullable(),
    resource_url: RString.nullable(),
    view_id: RNumber.nullable(),
    is_default: RBoolean,
    children: RArray(RNavigation),
    genre_change_sheet: RGenreChangeSheet.optional(),
  }),
);

export const RNavigationResponse = RRecord({
  success: RBoolean,
  message: RString.nullable(),
  data: RRecord({
    navigation: RNavigation,
  }),
});

export type NavigationResponse = RStatic<typeof RNavigationResponse>;
export type GenreChangeSheet = RStatic<typeof RGenreChangeSheet>;
