import {
  Array as RArray,
  Boolean as RBoolean,
  Literal as RLiteral,
  Number as RNumber,
  Record as RRecord,
  Static as RStatic,
  String as RString,
  Union as RUnion,
  Unknown as RUnknown,
} from 'runtypes';

import { RBackendsBadge } from '@/models/backendsApi/BackendsBadgeType';

import { RBackendsBook, RBackendsSerial } from '../../../BackendsBookType';

export const RBannerBadgeType = RUnion(
  RLiteral('WaitFree'),
  RLiteral('WaitFreePeriod'),
  RLiteral('Original'),
  RLiteral('New'),
  RLiteral('Up'),
  RLiteral('Select'),
  RLiteral('EndToday'),
  RLiteral('EndIn3Days'),
);
export type BannerBadgeType = RStatic<typeof RBannerBadge>;

export const RBannerBadge = RRecord({
  badge_type: RBannerBadgeType,
  badge_text: RString,
  badge_url: RString,
});
export type BannerBadge = RStatic<typeof RBannerBadge>;

export const RQuickMenuType = RUnion(
  RLiteral('AdultsOnly'),
  RLiteral('AffiliateCreditcard'),
  RLiteral('AnimationOriginal'),
  RLiteral('Awards'),
  RLiteral('Calendar'),
  RLiteral('Coupon'),
  RLiteral('Discount'),
  RLiteral('Event'),
  RLiteral('Free'),
  RLiteral('FullMoonEvent'),
  RLiteral('KeywordSearch'),
  RLiteral('New'),
  RLiteral('Only'),
  RLiteral('Pin'),
  RLiteral('PromotionMap'),
  RLiteral('Rent'),
  RLiteral('RidiPaper'),
  RLiteral('SeriesEnd'),
  RLiteral('SeriesMultipleVersions'),
  RLiteral('Set'),
  RLiteral('WaitFree'),
  RLiteral('Bestseller'),
  RLiteral('TenTenFree'),
  RLiteral('KooKyoHwan'),
);
export type QuickMenuType = RStatic<typeof RQuickMenuType>;

export const RGroupSectionType = RUnion(RLiteral('DailyWebtoon'), RLiteral('Keyword'));
export type GroupSectionType = RStatic<typeof RGroupSectionType>;

export const RGroupUnit = RRecord({
  id: RNumber,
  name: RString,
  meta: RRecord({
    description: RString,
    metaTitle: RString,
    title: RString,
  }).optional(),
});
export type GroupUnit = RStatic<typeof RGroupUnit>;

export const RGroupFetchUrl = RRecord({
  adult_exclude: RString,
  adult_include: RString,
});
export type GroupFetchUrl = RStatic<typeof RGroupFetchUrl>;
export const RGroupMoreDetailUrl = RRecord({
  adult_exclude: RString,
  adult_include: RString,
});
export type GroupMoreDetailUrl = RStatic<typeof RGroupMoreDetailUrl>;

export enum SectionLayoutType {
  AiRecommendation = 'AiRecommendation',
  BestSeller = 'Bestseller',
  EventHorizontal = 'EventHorizontal',
  EventVertical = 'EventVertical',
  Group = 'Group',
  ImageGradientBanner = 'ImageGradientBanner',
  Keyword = 'Keyword',
  NewRelease = 'NewRelease',
  OnePick = 'OnePick',
  QuickMenu = 'QuickMenu',
  ReadingBook = 'ReadingBook',
  Selection = 'Selection',
  SelectionCarousel = 'SelectionCarousel',
  SelectionCover = 'SelectionCover',
  SelectionHookingSentence = 'SelectionHookingSentence',
  SelectionOriginal = 'SelectionOriginal',
  SelectionMultiline = 'SelectionMultiline',
  TopCarouselBanner = 'TopCarouselBanner',
  TopCarouselBook = 'TopCarouselBook',
  TopCarouselCover = 'TopCarouselCover',
}

export const RSectionMoreDetails = RRecord({
  resource_url: RString.nullable(),
  path: RString.nullable(),
});
export type SectionMoreDetails = RStatic<typeof RSectionMoreDetails>;

export enum SectionItemFilterTypes {
  ADULT_INCLUDE = 'adult_include',
  ADULT_EXCLUDE = 'adult_exclude',
}
const RSectionItemValue = RRecord({
  resource_url: RString,
});

export const RSectionItemsFilter = RRecord({
  [SectionItemFilterTypes.ADULT_EXCLUDE]: RSectionItemValue,
  [SectionItemFilterTypes.ADULT_INCLUDE]: RSectionItemValue,
});
export type SectionItemsFilter = RStatic<typeof RSectionItemsFilter>;

export enum SectionItemLayoutType {
  AiRecommendation = 'AiRecommendation',
  BestSeller = 'Bestseller',
  EventHorizontal = 'EventHorizontal',
  EventVertical = 'EventVertical',
  Keyword = 'Keyword',
  NewRelease = 'NewRelease',
  OnePick = 'OnePick',
  QuickMenu = 'QuickMenu',
  ReadingBook = 'ReadingBook',
  Selection = 'Selection',
  SelectionCarouselCharacter = 'SelectionCarouselCharacter',
  SelectionCarouselBookCover = 'SelectionCarouselBookCover',
  SelectionCover = 'SelectionCover',
  SelectionHookingSentence = 'SelectionHookingSentence',
  SelectionMultiline = 'SelectionMultiline',
  SelectionOriginal = 'SelectionOriginal',
  SelectionPreview = 'SelectionPreview',
  TopCarouselBanner = 'TopCarouselBanner',
  TopCarouselBook = 'TopCarouselBook',
  TopCarouselCover = 'TopCarouselCover',
  Unknown = 'Unknown',
}

export const RSectionItemSerial = RBackendsSerial.pick(
  'serial_id',
  'title',
  'cover',
  'original_cover_size',
  'episode',
  'total',
  'completion',
  'wait_for_free',
  'free',
  'unit',
  'purchase',
  'purchase_all',
  'rental',
  'rental_all',
  'scheduled_free',
  'last_opened_episode_date',
);
export type SectionItemSerial = RStatic<typeof RSectionItemSerial>;

export const RSectionItemBook = RBackendsBook.pick(
  'book_id',
  'title',
  'cover',
  'original_cover_size',
  'thumbnail',
  'authors',
  'categories',
  'adults_only',
  'ratings',
  'publisher',
  'set',
  'style',
  'ridiselect',
  'purchase',
  'rental',
).extend({
  serial: RSectionItemSerial.nullable(),
});
export type SectionItemBook = RStatic<typeof RSectionItemBook>;

export const RSectionItemBadge = RBackendsBadge;
export type SectionItemBadge = RStatic<typeof RSectionItemBadge>;

export const RSectionItemContentsBookBase = RRecord({
  book: RSectionItemBook,
  badges: RArray(RSectionItemBadge),
});
export type SectionItemContentsBookBase = RStatic<typeof RSectionItemContentsBookBase>;

// 경량화된 book을 위한 객체
export const RSectionItemSlot = RRecord({
  book_id: RString.optional(),
  title: RString.optional(),
});
export type SectionItemSlot = RStatic<typeof RSectionItemSlot>;

export const RSectionItemSlotExtras = RRecord({
  badges: RArray(RBackendsBadge).optional(),
});
export type SectionItemSlotExtras = RStatic<typeof RSectionItemSlotExtras>;

export const RSectionItemContentsSlotBase = RRecord({
  slot: RSectionItemSlot,
  extras: RSectionItemSlotExtras,
});
export type SectionItemContentsSlotBase = RStatic<typeof RSectionItemContentsSlotBase>;

export const RSectionItemContentsBase = RUnion(RSectionItemContentsBookBase, RSectionItemContentsSlotBase);
export type SectionItemContentsBase = RStatic<typeof RSectionItemContentsBase>;

export const RSectionItemBase = RRecord({
  id: RNumber,
  contents: RUnknown,
  layout: RString,
});
export type SectionItemBase = RStatic<typeof RSectionItemBase>;

export const RSectionItemsAsync = RRecord({
  need_login: RBoolean,
  resource_url: RString.nullable(),
});
export type SectionItemsAsync = RStatic<typeof RSectionItemsAsync>;

export const RSectionBase = RRecord({
  id: RNumber,
  title: RString,
  layout: RString.withGuard((string: string): string is SectionLayoutType => !!string),
  type: RString.optional(),
  contents: RUnknown.nullable(),
  more_details: RSectionMoreDetails.nullable(),
  items: RArray(RUnknown).optional(),
  items_async: RSectionItemsAsync.nullable().optional(),
});
export type SectionBase = RStatic<typeof RSectionBase>;

export enum ViewLayoutType {
  GenreHome = 'GenreHome',
}

export const RViewBase = RRecord({
  id: RNumber,
  title: RString,
  contents: RUnknown,
  layout: RString,
  resources: RArray(RUnknown),
});
export type ViewBase = RStatic<typeof RViewBase>;
