import Head from 'next/head';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { config } from '@/config';
import { isRidiAppSelector } from '@/features/global/variables/variablesSlice';
import ogImageFallback from '~/public/images/ridibooks.png';

export interface MetaProps {
  description?: string;
  publishedAt?: string;
  lastModified?: string;
  noIndex?: boolean;
  ogDescription?: string;
  ogTitle: string;
  ogUrlPath?: string;
  ogImage?: string;
  viewport?: string;
}

export const Meta = ({
  description = '웹툰, 웹소설, 전자책 모두 여기에! 이야기를 즐기는 나만의 공간, RIDI',
  lastModified,
  noIndex = false,
  ogDescription = '웹툰, 웹소설, 전자책 모두 여기에! 이야기를 즐기는 나만의 공간, RIDI',
  ogTitle,
  ogUrlPath = '',
  ogImage,
  viewport,
}: MetaProps): ReactJSX.Element => {
  const ogUrl = `https://${config.domain}${ogUrlPath}`;
  const isInApp = useSelector(isRidiAppSelector);

  const metaViewport = useMemo(() => {
    if (viewport) {
      return <meta name="viewport" content={viewport} />;
    }

    return isInApp ? (
      <meta
        name="viewport"
        content="width=device-width, height=device-height, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, viewport-fit=cover"
      />
    ) : (
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=yes, viewport-fit=cover"
      />
    );
  }, [viewport, isInApp]);

  return (
    <Head>
      {noIndex && <meta name="robots" content="none" />}
      <meta charSet="UTF-8" />

      {metaViewport}

      <meta httpEquiv="X-UA-Compatible" content="IE=Edge" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@ridi_twt" />
      <meta property="fb:app_id" content="208688205808637" />
      <meta name="theme-color" content="#ffffff" />
      <meta name="keywords" content="리디,ridi,리디북스,ridibooks,책,전자책,ebook,웹소설,webnovel,웹툰,webtoon,comic" />
      <meta name="format-detection" content="telephone=no" />
      <meta property="og:site_name" content="리디" />
      <meta property="og:url" content={ogUrl} key="opengraphUrl" />
      <meta property="og:image" content={ogImage || ogImageFallback.src} key="opengraphImage" />
      <meta property="og:title" content={ogTitle} key="opengraphTitle" />
      <meta property="og:description" content={ogDescription} key="opengraphDescription" />
      <meta name="description" content={description} key="description" />
      <meta name="naver-site-verification" content="77f7190e8232e0e71dc122e0057ded6a83f66210" />
      {lastModified && <meta httpEquiv="last-modified" content={lastModified} />}
      <link rel="manifest" href="/manifest.webmanifest" />
    </Head>
  );
};
