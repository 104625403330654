import { CheckoutCouponType } from '@/models/backendsApi/v1/Checkout/CheckoutType';
import { debug } from '@/utils/debug';

import {
  checkoutBookIdsSelector,
  checkoutCSRFTokenSelector,
  checkoutOrderSelector,
  checkoutSetBookIdByBookIdSelector,
} from '../../checkout/checkoutSlice';
import { paymentRequestAction } from '../../paymentRequest/paymentRequestSlice';
import { addPurchaseFinishedBooksAction } from '../actions';
import { checkoutStateInfoSelector } from '../selectors';
import { CheckoutStateTypes } from '../types';
import { createTransitionThunk } from '../utils/createTransitionThunk';

const log = debug('freePaymentTransitionAction');

export const freePaymentTransitionAction = createTransitionThunk(
  'freePaymentTransition',
  CheckoutStateTypes.FREE_PAYMENT,
  async (_previousStates, _args, { getState, dispatch }) => {
    const initialState = getState();

    const info = checkoutStateInfoSelector(initialState);
    const csrfToken = checkoutCSRFTokenSelector(initialState);
    const order = checkoutOrderSelector(initialState);

    if (!csrfToken || !info || !order) {
      log('Failure: Free Payment No Needed Data');
      return { state: CheckoutStateTypes.FAILURE, reason: null };
    }

    const bookIds = checkoutBookIdsSelector(getState());
    const baseParams = {
      orderType: info.orderType,
      checkout: {
        ridipointBalance: 0,
        totalPrice: 0,
        isPointback: !!info.isPointback,
        coupons: {
          [CheckoutCouponType.Discount]: null,
          [CheckoutCouponType.PointGiftCard]: null,
        },
      },
      csrfToken,
    };

    const seriesId = info.requestInfo.orderItemType === 'episode' && info.requestInfo.serialId;
    const paymentResult = await dispatch(
      paymentRequestAction(
        seriesId && order.is_entire_episode
          ? { reqParams: { ...baseParams, seriesId } }
          : { reqParams: { ...baseParams, bookIds } },
      ),
    );

    if (paymentRequestAction.rejected.match(paymentResult)) {
      log('Failure: Free Payment APIFail', paymentResult.payload?.message);
      return { state: CheckoutStateTypes.FAILURE, reason: null };
    }

    const { payload } = paymentResult;
    if (!payload.paymentFinished) {
      return null;
    }

    const setBookIdByBookId = checkoutSetBookIdByBookIdSelector(initialState);
    const purchasedBooks = payload.data.b_ids.map(bookId => ({
      bookId,
      isFreeBook: true,
      setBookId: setBookIdByBookId?.get(bookId) ?? null,
    }));

    dispatch(addPurchaseFinishedBooksAction(purchasedBooks));

    log('Success: Free Payment');
    return {
      state: CheckoutStateTypes.SUCCESS,
      message: '결제가 완료되었습니다.',
      needsOpenBook: true,
      isWaitFree: false,
      eventType: info.orderType,
      transactionId: payload.data.t_id,
    };
  },
);
