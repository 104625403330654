import colors from '@ridi/colors';
import {
  darkTheme as designSystemDarkTheme,
  lightTheme as designSystemLightTheme,
  RIDITheme as DesignSystemRIDITheme,
} from '@ridi-web/design-system/Styles/cjs';

export interface RIDITheme {
  backgroundColor: string;
  textColor: string;
  hoverBackground: string;
  dividerColor: string;
  dividerOpacity: number;
  placeholderThumbnail: string;
  placeholderColor: string;
  placeholderFade: number;
  primaryColor: string;
  primaryHoverColor: string;
  secondaryColor: string;
  footerTextColor: string;
  horizontalRuleColor: string;
  verticalRuleColor: string;
  logoFilter: string;
  logoFilter2: string;
  button: {
    primaryBackground: string;
    secondaryBackground: string;
    primaryFontColor: string;
    primaryBorderColor: string;
    secondaryBorderColor: string;
    secondaryFontColor: string;
    [type: string]: string;
  };
  input: {
    placeholder: string;
  };
  divider: string;
  divider2: string;
  divider3: string;
  genreTab: {
    icon: string;
    normal: string;
    active: string;
  };
  subServiceTab: {
    normal: string;
    hover: string;
    active: string;
  };
  label: string;
  label2: string;
  label3: string;
  etc: {
    slot1: string;
  };
  icon: {
    warn: string;
  };
  quickMenu: {
    label: string;
  };
  search: {
    itemHover: string;
  };
  image: {
    border: string;
  };

  colorScheme: 'dark' | 'light';

  // ## 넥스트 리디에서 새로 정의된 컬러
  // > 웹 디자인이 업데이트되면,
  // > 잠재적으로 @ridi/colors와 테마에 정의된 다른 색상들을 버리고 이 쪽으로 통일하는 방향으로...
  colors: DesignSystemRIDITheme['colors'];

  /**
   * 회원가입 웹뷰용 컬러 팔레트(legacy)
   * account에 있던 코드를 books-frontend로 가져오면서 어쩔 수 없이 만들었으니
   * 추후에 앱/웹 회원가입 디자인이 통일된다면 제거할 수 있도록 해야함.
   */
  inappSignup: {
    backgroundColor: string;
    loginLinkColor: string;
    button: {
      baseColor: string;
      baseBackgroundColor: string;
      baseBorderColor: string;
      primaryColor: string;
      primaryBackgroundColor: string;
      primaryBorderColor: string;
    };
    textButton: {
      color: string;
    };
    input: {
      placeholderColor: string;
      inputColor: string;
      inputBackgroundColor: string;
      inputBorderColor: string;
      invalidPlaceholderColor: string;
      invalidInputBorderColor: string;
      focusPlaceholderColor: string;
      focusInputBorderColor: string;
    };
    inputGroupLabelColor: string;
    autoComplete: {
      providerColor: string;
      focusProviderColor: string;
      focusProviderBackgroundColor: string;
    };
    radioButton: {
      checkedBackgroundColor: string;
      checkedColor: string;
    };
    checkbox: {
      backgroundColor: string;
      borderColor: string;
      titleColor: string;
    };
    policyLinkColor: string;
    policyModal: {
      backgroundColor: string;
      color: string;
      h1Color: string;
      tableBorderColor: string;
    };
    typography: {
      titleColor: string;
      descriptionColor: string;
    };
  };
}

export const lightTheme: RIDITheme = {
  backgroundColor: '#ffffff',
  textColor: '#303538',
  hoverBackground: '#F7FAFC',
  dividerColor: '#E6E8EB',
  dividerOpacity: 1,
  placeholderThumbnail: 'linear-gradient(326.23deg, #F8F9FB 1.42%, #F1F1F3 49.17%, #F8F9FB 100%)',
  placeholderColor: 'linear-gradient(357.81deg, #F8F9FB 1.42%, #F1F1F3 49.17%, #F8F9FB 100%)',
  placeholderFade: 0.14,
  primaryColor: colors.dodgerBlue50,
  primaryHoverColor: colors.dodgerBlue10,
  secondaryColor: colors.slateGray90,
  footerTextColor: '#ffffff',
  horizontalRuleColor: colors.slateGray80,
  verticalRuleColor: colors.slateGray70,
  logoFilter: 'drop-shadow(0 0 1px rgba(0, 0, 0, 0.3))',
  logoFilter2: 'drop-shadow(0 1px 1px rgba(0, 0, 0, 0.2))',
  button: {
    primaryBorderColor: colors.dodgerBlue20,
    primaryBackground: colors.dodgerBlue50,
    primaryFontColor: '#ffffff',
    secondaryBackground: '#ffffff',
    secondaryBorderColor: '#ffffff',
    secondaryFontColor: colors.dodgerBlue50,
  },
  input: {
    placeholder: colors.slateGray50,
  },
  divider: colors.slateGray5,
  divider2: colors.slateGray10,
  divider3: colors.slateGray20,
  label: colors.slateGray40,
  label2: colors.slateGray50,
  label3: colors.slateGray80,
  genreTab: {
    icon: colors.slateGray60,
    normal: colors.slateGray80,
    active: colors.dodgerBlue50,
  },
  subServiceTab: {
    normal: colors.slateGray50,
    hover: colors.slateGray90,
    active: colors.dodgerBlue50,
  },
  etc: {
    slot1: colors.slateGray20,
  },
  icon: {
    warn: colors.slateGray20,
  },
  quickMenu: {
    label: colors.slateGray70,
  },
  search: {
    itemHover: colors.lightSteelBlue5,
  },
  image: {
    border: colors.slateGray30,
  },
  ...designSystemLightTheme,
  inappSignup: {
    backgroundColor: colors.slateGray5,
    loginLinkColor: colors.slateGray50,
    button: {
      baseColor: colors.slateGray50,
      baseBackgroundColor: '#ffffff',
      baseBorderColor: colors.slateGray20,
      primaryColor: '#ffffff',
      primaryBackgroundColor: colors.dodgerBlue50,
      primaryBorderColor: colors.dodgerBlue50,
    },
    textButton: {
      color: colors.slateGray50,
    },
    input: {
      placeholderColor: colors.slateGray40,
      inputColor: colors.slateGray100,
      inputBackgroundColor: '#ffffff',
      inputBorderColor: colors.slateGray20,
      invalidPlaceholderColor: colors.red50,
      invalidInputBorderColor: colors.red10,
      focusPlaceholderColor: colors.dodgerBlue40,
      focusInputBorderColor: colors.dodgerBlue40,
    },
    inputGroupLabelColor: colors.slateGray50,
    autoComplete: {
      providerColor: '#7f8891',
      focusProviderColor: '#000000',
      focusProviderBackgroundColor: colors.dodgerBlue5,
    },
    radioButton: {
      checkedBackgroundColor: colors.dodgerBlue10,
      checkedColor: colors.slateGray70,
    },
    checkbox: {
      backgroundColor: '#ffffff',
      borderColor: colors.slateGray20,
      titleColor: colors.slateGray70,
    },
    policyLinkColor: colors.slateGray40,
    policyModal: {
      backgroundColor: '#ffffff',
      color: colors.slateGray70,
      h1Color: colors.slateGray70,
      tableBorderColor: colors.slateGray10,
    },
    typography: {
      titleColor: colors.slateGray100,
      descriptionColor: colors.slateGray50,
    },
  },
};

export const darkTheme: RIDITheme = {
  backgroundColor: '#212B3B',
  textColor: '#ffffff',
  hoverBackground: '#2E3847',
  dividerColor: '#ffffff',
  dividerOpacity: 0.08,
  placeholderThumbnail: 'linear-gradient(90.3deg, #283243 0%, #242F40 49.48%, #283243 100%)',
  placeholderColor: 'linear-gradient(95.29deg, #283243 0%, #242F40 49.48%, #283243 100%)',
  placeholderFade: 0.14,
  primaryColor: colors.gray90,
  primaryHoverColor: colors.dodgerBlue10,
  secondaryColor: colors.slateGray90,
  footerTextColor: '#ffffff',
  horizontalRuleColor: colors.slateGray80,
  verticalRuleColor: colors.slateGray70,
  logoFilter: 'drop-shadow(0 0 1px rgba(0, 0, 0, 0.3))',
  logoFilter2: 'drop-shadow(0 1px 1px rgba(0, 0, 0, 0.2))',
  button: {
    primaryBorderColor: colors.dodgerBlue20,
    primaryBackground: colors.dodgerBlue50,
    primaryFontColor: '#ffffff',
    secondaryBackground: '#ffffff',
    secondaryBorderColor: '#ffffff',
    secondaryFontColor: colors.dodgerBlue50,
  },
  input: {
    placeholder: colors.slateGray50,
  },
  divider: colors.slateGray5,
  divider2: colors.slateGray10,
  divider3: colors.slateGray20,
  genreTab: {
    icon: colors.slateGray60,
    normal: colors.slateGray80,
    active: colors.dodgerBlue50,
  },
  subServiceTab: {
    normal: colors.slateGray50,
    hover: colors.slateGray90,
    active: colors.dodgerBlue50,
  },
  label: colors.slateGray40,
  label2: colors.slateGray50,
  label3: colors.slateGray80,
  etc: {
    slot1: colors.slateGray20,
  },
  icon: {
    warn: colors.slateGray20,
  },
  quickMenu: {
    label: colors.slateGray70,
  },
  search: {
    itemHover: colors.lightSteelBlue5,
  },
  image: {
    border: colors.slateGray30,
  },
  ...designSystemDarkTheme,
  inappSignup: {
    backgroundColor: colors.blueGray80,
    loginLinkColor: colors.blueGray40,
    button: {
      baseColor: '#ffffff',
      baseBackgroundColor: colors.blueGray60,
      baseBorderColor: colors.blueGray50,
      primaryColor: '#ffffff',
      primaryBackgroundColor: colors.dodgerBlue50,
      primaryBorderColor: colors.dodgerBlue50,
    },
    textButton: {
      color: colors.blueGray30,
    },
    input: {
      placeholderColor: colors.blueGray30,
      inputColor: '#ffffff',
      inputBackgroundColor: colors.blueGray90,
      inputBorderColor: colors.blueGray70,
      invalidPlaceholderColor: colors.red50,
      invalidInputBorderColor: colors.red30,
      focusPlaceholderColor: colors.dodgerBlue40,
      focusInputBorderColor: colors.dodgerBlue40,
    },
    inputGroupLabelColor: colors.blueGray30,
    autoComplete: {
      providerColor: colors.blueGray5,
      focusProviderColor: '#ffffff',
      focusProviderBackgroundColor: colors.blueGray80,
    },
    radioButton: {
      checkedBackgroundColor: colors.blueGray60,
      checkedColor: '#ffffff',
    },
    checkbox: {
      backgroundColor: colors.blueGray90,
      borderColor: colors.blueGray70,
      titleColor: colors.blueGray30,
    },
    policyLinkColor: colors.blueGray30,
    policyModal: {
      backgroundColor: colors.blueGray80,
      color: colors.slateGray20,
      h1Color: '#ffffff',
      tableBorderColor: colors.blueGray60,
    },
    typography: {
      titleColor: '#ffffff',
      descriptionColor: colors.blueGray40,
    },
  },
};

export const defaultTheme = lightTheme;
