import { config } from '@/config';

interface Context {
  '@context': string;
  '@type': string;
}

interface Organization {
  '@type': 'Organization';
  name: string;
  url: string;
}

interface WebSiteJsonLd extends Context {
  url: string;
  sameAs: string[];
  potentialAction: {
    '@type': string;
    target: string;
    'query-input': string;
  };
}

interface BlogPostingParams {
  // url: string;
  headline: string;
  image?: string[];
  datePublished: string;
  dateModified: string;
  author: Omit<Organization, '@type'>;
  description: string;
}

interface BlogPostingJsonLd extends Context {
  headline: string;
  image?: string[];
  datePublished: string;
  dateModified: string;
  author: Organization;
  description: string;
}

export const createWebSiteJsonLd = (sameAs: string[] = []): WebSiteJsonLd => ({
  '@context': 'http://schema.org',
  '@type': 'WebSite',
  url: `https://${config.domain}`,
  sameAs,
  potentialAction: {
    '@type': 'SearchAction',
    target: 'https://ridibooks.com/search/?q={q}&amp;referer=sitelinks_searchbox',
    'query-input': 'required name=q',
  },
});

export const createBlogPostingJsonLd = ({
  // url,
  headline,
  image = [],
  datePublished,
  dateModified,
  author,
  description,
}: BlogPostingParams): BlogPostingJsonLd => ({
  '@context': 'http://schema.org',
  '@type': 'BlogPosting',
  //   mainEntityOfPage: { TODO: 블로그의 목록 페이지가 개발 되면 그 때 추가
  //     '@type': 'WebPage',
  //     '@id': url,
  //   },
  headline,
  image,
  datePublished,
  dateModified,
  author: {
    '@type': 'Organization',
    name: author.name,
    url: author.url,
  },
  description,
});

export const extractImageUrls = async (htmlString: string): Promise<string[]> => {
  if (typeof window !== 'undefined') {
    return [];
  }

  const { JSDOM } = await import('jsdom');
  const dom = new JSDOM(htmlString);
  const imgTags = dom.window.document.querySelectorAll('img');
  const imageUrls: string[] = [];

  imgTags.forEach(img => {
    const src = img.getAttribute('src');
    if (src) {
      imageUrls.push(src);
    }
  });

  return imageUrls;
};
