import { Literal as RLiteral, Null as RNull, Number as RNumber, Record as RRecord, Static as RStatic } from 'runtypes';

export const RGroupSectionsItemsCount = RRecord({
  count: RNumber,
});

export type GroupSectionsItemsCount = RStatic<typeof RGroupSectionsItemsCount>;

export const RGroupSectionsItemsCountResponse = RRecord({
  success: RLiteral(true),
  message: RNull,
  data: RGroupSectionsItemsCount,
});

export type GroupSectionsItemsCountResponse = RStatic<typeof RGroupSectionsItemsCountResponse>;
