import { initPerfume, IPerfumeData } from 'perfume.js';
import { useEffect } from 'react';

function sendToGTM(metricName: string, data: IPerfumeData) {
  window.dataLayer?.push({
    event: 'web-vitals',
    event_category: 'Perfume.js',
    event_action: metricName,
    event_value: data,
  });
}

export const usePerfumeJs = () => {
  useEffect(() => {
    initPerfume({
      analyticsTracker: ({ metricName, data }) => {
        switch (metricName) {
          case 'navigationTiming':
            if (data && typeof data !== 'number' && 'timeToFirstByte' in data && data.timeToFirstByte) {
              sendToGTM('navigationTiming', data);
            }
            break;
          case 'networkInformation':
            if (data && typeof data !== 'number' && 'effectiveType' in data && data.effectiveType) {
              sendToGTM('networkInformation', data);
            }
            break;
          case 'storageEstimate':
            sendToGTM('storageEstimate', data);
            break;
          case 'TTFB':
            sendToGTM('timeToFirstByte', data);
            break;
          case 'RT':
            sendToGTM('redirectTime', data);
            break;
          case 'FCP':
            sendToGTM('firstContentfulPaint', data);
            break;
          case 'FID':
            sendToGTM('firstInputDelay', data);
            break;
          case 'LCP':
            sendToGTM('largestContentfulPaint', data);
            break;
          case 'CLS':
            sendToGTM('cumulativeLayoutShift', data);
            break;
          case 'INP':
            sendToGTM('interactionToNextPaint', data);
            break;
          case 'TBT':
            sendToGTM('totalBlockingTime', data);
            break;
          default:
            sendToGTM(metricName, data);
            break;
        }
      },
    });
  }, []);
};
