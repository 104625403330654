import { AccountActionChangePasswordModel } from '@/models/booksBackend/ChangePasswordModel/AccountActionChangePasswordModel';
import { ServiceMethod, stringifyRequestParams } from '@/services/baseService';
import { getFeatureFlagValueByKey } from '@/utils/featureFlag';

import { makeCallAPIMethod } from '../booksBackendService';
import { AccountActionChangePasswordRequest } from './interfaces/AccountActionChangePasswordRequest';

export const accountActionChangePassword: ServiceMethod<
  AccountActionChangePasswordRequest,
  AccountActionChangePasswordModel
> = async reqParams => {
  const call = makeCallAPIMethod(
    !!getFeatureFlagValueByKey({
      featureFlag: reqParams.featureFlag,
      featureFlagKey: 'web-internal-books-backendsapi-request-20240813',
    }),
  )(AccountActionChangePasswordModel);
  return call<AccountActionChangePasswordRequest, Error, AccountActionChangePasswordModel>({
    method: 'POST',
    pathname: '/account/action/change-password',
    preferNonProxied: true,
    params: { body: reqParams.body },
    transformRequest: [stringifyRequestParams],
  });
};
