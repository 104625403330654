import { AnyAction, combineReducers } from '@reduxjs/toolkit';

import { instantSearchSlice, InstantSearchState } from './instantSearch/instantSearchSlice';

export interface SearchState {
  instantSearch: InstantSearchState;
}

export const searchReducer = (state: SearchState | undefined, action: AnyAction): SearchState => {
  const combinedReducers = combineReducers({
    instantSearch: instantSearchSlice.reducer,
  });

  return combinedReducers(state, action);
};

export type SearchReducerType = ReturnType<typeof searchReducer>;
