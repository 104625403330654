import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { hydrateAction } from '@/features/hydrate';
import type { ActionRequest, RootState } from '@/features/store';
import { AccountActionFindPassword } from '@/models/booksBackend/FindPasswordModel/AccountActionFindPasswordType';
import { accountActionFindPassword } from '@/services/booksBackend/findPassword/accountActionFindPasswordService';
import { debug } from '@/utils/debug';

import type { AccountState } from '..';

const log = debug('findPasswordSlice');

type FindPasswordFailure = {
  message: string;
  status: number;
};

export const findPasswordSelector = createSelector(
  (state: RootState) => state.account,
  (state: AccountState) => state.findPassword,
);

export interface FindPasswordState {
  IdInputValue: string;
  emailInputValue: string;
  isLoading: boolean;
  responseEmail: string;
  hasEmailSent: boolean;
  error: null | {
    message: string;
  };
}

const initialState: FindPasswordState = {
  IdInputValue: '',
  emailInputValue: '',
  isLoading: false,
  responseEmail: '',
  hasEmailSent: false,
  error: null,
};

export const findPasswordAction = createAsyncThunk<
  AccountActionFindPassword,
  ActionRequest<{ body: { email: string; return_url?: string; user_id: string } }>,
  { rejectValue: FindPasswordFailure }
>('account/findPasswordAction', async ({ reqParams }, thunkAPI) => {
  const [error, model] = await accountActionFindPassword(reqParams);

  if (error) {
    return thunkAPI.rejectWithValue({
      message: error.response?.data.message || '서버 오류가 발생했습니다.',
      status: error.response?.status || 400,
    });
  }

  return model.Data;
});

export const findPasswordSlice = createSlice({
  name: 'account/findPassword',
  initialState,
  reducers: {
    onChangeIdInputValue: (state, action: PayloadAction<string>) => {
      state.IdInputValue = action.payload;
    },
    onChangeEmailInputValue: (state, action: PayloadAction<string>) => {
      state.emailInputValue = action.payload;
    },
    onError: (state, action: PayloadAction<string>) => {
      state.error = { message: action.payload };
    },
  },
  extraReducers: builder => {
    builder.addCase(hydrateAction, (_, action) => action.payload.account.findPassword);
    builder.addCase(findPasswordAction.pending, state => {
      log('## findPasswordAction.pending', state);
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(findPasswordAction.fulfilled, (state, action) => {
      log('## findPasswordAction.fulfilled', state, action);
      state.isLoading = false;
      if (action.payload.success) {
        state.responseEmail = action.payload.email;
        state.hasEmailSent = true;
      } else {
        state.error = { message: action.payload.message };
      }
    });
    builder.addCase(findPasswordAction.rejected, (state, action) => {
      log('## findPasswordAction.rejected', state, action);
      state.isLoading = false;
      state.error = { message: action.payload?.message || '서버 오류가 발생했습니다.' };
    });
  },
});
