import { GroupSectionsError } from '@/models/backendsApi/v2/GroupSections/GroupSectionsErrorType';
import { GroupSectionItemsCountModel } from '@/models/backendsApi/v2/GroupSections/ItemsCount/GroupSectionsItemsCountModel';
import { ServiceMethod } from '@/services/baseService';
import { getFeatureFlagValueByKey } from '@/utils/featureFlag';

import { makeCallAPIMethod } from '../../backendsApiService';
import { GroupSectionsItemsCountRequest } from './interfaces/GroupSectionsItemsCountRequest';

export const groupSectionsItemsCount: ServiceMethod<
  GroupSectionsItemsCountRequest,
  GroupSectionItemsCountModel,
  GroupSectionsError
> = async (reqParams, req) => {
  const call = makeCallAPIMethod(
    !!getFeatureFlagValueByKey({
      featureFlag: req?.FeatureFlag || reqParams?.featureFlag,
      featureFlagKey: 'web-internal-backendsapi-request-20240710',
    }),
  )(GroupSectionItemsCountModel);
  const { groupSectionId, groupUnitId } = reqParams.params;
  return call<GroupSectionsItemsCountRequest, GroupSectionsError, GroupSectionItemsCountModel>({
    method: 'GET',
    pathname: `/v2/group-sections/${groupSectionId}/groups/${groupUnitId}/count`,
    params: {
      query: {
        platform: 'web',
      },
    },
    preferNonProxied: true,
  });
};
