import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from '@/features/store';

import type { CheckoutStateState } from './checkoutStateSlice';
import { CheckoutPurchaseFinishedBook } from './types';

export const checkoutStateRootSelector = (state: RootState): CheckoutStateState => state.inapp.checkout.checkoutState;

export const currentCheckoutStateSelector = createSelector(checkoutStateRootSelector, state => state.currentState);
export const currentCheckoutRenderStateSelector = createSelector(checkoutStateRootSelector, state => state.renderState);
export const checkoutStateInfoSelector = createSelector(checkoutStateRootSelector, state => state.info ?? undefined);
export const isInCheckoutTransitionSelector = createSelector(checkoutStateRootSelector, state => state.isInTransition);

export const purchaseFinishedBooksSelector = createSelector(
  checkoutStateRootSelector,
  state => state.purchaseFinishedBooks,
);

export const openRequestBookSelector = createSelector(purchaseFinishedBooksSelector, purchaseFinishedBooks =>
  purchaseFinishedBooks.reduce<CheckoutPurchaseFinishedBook | undefined>((previousBook, currentBook) => {
    if (!previousBook || parseInt(previousBook.bookId, 10) > parseInt(currentBook.bookId, 10)) {
      return currentBook;
    }

    return previousBook;
  }, undefined),
);
