import axios from 'axios';
import {
  QueryCache,
  QueryClient,
  QueryFunction,
  QueryKey,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';

import sendException, { isReportToSentry } from '@/utils/sentry/sendException';

const runMode = process.env.RUN_MODE;
const getHost = () => process.env.NEXT_PUBLIC_BACKENDS_API;

type Params = { [key: string]: string | number };

const encodeQueryParams = (paramObj: Params) =>
  Object.keys(paramObj)
    .map(key => [key, paramObj[key]].map(encodeURIComponent).join('='))
    .join('&');

export const getURL = (pathname: string, paramObj: Params | null, options?: { host?: string }) => {
  const host = options?.host ?? getHost();
  const validPathname = pathname[0] !== '/' ? `/${pathname}` : pathname;
  const params = paramObj !== null ? encodeQueryParams(paramObj) : '';
  return params !== '' ? `${host}${validPathname}?${params}` : `${host}${validPathname}`;
};

export const generateQueryClient = (): QueryClient =>
  new QueryClient({
    queryCache: new QueryCache({
      onError(error) {
        // query fetch 중에 error가 발생하면 sentry로 전송
        if (
          runMode === 'production' &&
          axios.isAxiosError(error) &&
          error.response?.status &&
          isReportToSentry(error.response.status)
        ) {
          sendException(error, {
            level: 'fatal',
            tags: {
              type: 'QueryCache',
            },
          });
        }
      },
    }),
    defaultOptions: {
      queries: {
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
      },
    },
  });

type Key = (QueryKey & [queryName: string, params: object]) | [queryName: string];

export const useBaseQuery = <
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends Key = Key,
>(
  queryKey: TQueryKey,
  queryFn: QueryFunction<TQueryFnData, TQueryKey>,
  options?: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
): UseQueryResult<TData, TError> => {
  const queryResult = useQuery(queryKey, queryFn, options);
  return queryResult;
};
