import { Runtype } from 'runtypes';

import { debug } from '@/utils/debug';

const log = debug('BaseModel');
export class BaseModel<M> {
  constructor(
    private data: M,
    private type: Runtype<M>,
  ) {}

  get Data(): M {
    return this.data;
  }

  public check(): M {
    try {
      return this.type.check(this.data);
    } catch (error) {
      log('check', error);
      return this.data;
    }
  }
}
