import { RemoveUserDeviceResponseModel } from '@/models/account/UserDevices/RemoveUserDeviceResponseModel';
import { UserDeviceResponseModel } from '@/models/account/UserDevices/UserDeviceResponseModel';
import { UserDevicesResponseModel } from '@/models/account/UserDevices/UserDevicesResponseModel';
import { ServiceMethod } from '@/services/baseService';

import { makeCallAPIMethod } from '../accountService';
import { RemoveUserDeviceRequest, UpdateUserDeviceRequest } from './interfaces/userDevicesRequest';

type UnauthorizedError = string;
type BadRequestError = Record<string, unknown>;
type NotFoundError = { message: string };

export type FetchUserDevicesError = UnauthorizedError;

export const fetchUserDevices: ServiceMethod<void, UserDevicesResponseModel, FetchUserDevicesError> = (_, req) => {
  const userDevicesCall = makeCallAPIMethod(UserDevicesResponseModel);

  return userDevicesCall<void, string, UserDevicesResponseModel>(
    {
      method: 'GET',
      pathname: '/api/user-devices/all',
      preferNonProxied: true,
    },
    req,
  );
};

export type UpdateUserDeviceError = UnauthorizedError | BadRequestError | NotFoundError;

export const updateUserDevice: ServiceMethod<
  UpdateUserDeviceRequest,
  UserDeviceResponseModel,
  UpdateUserDeviceError
> = (reqParams, req) => {
  const pathname = `/api/user-devices/${reqParams.params.id}`;
  const userDeviceCall = makeCallAPIMethod(UserDeviceResponseModel);

  return userDeviceCall<UpdateUserDeviceRequest, UpdateUserDeviceError, UserDeviceResponseModel>(
    {
      method: 'PUT',
      pathname,
      params: {
        body: reqParams.body,
      },
      preferNonProxied: true,
    },
    req,
  );
};

export type RemoveUserDeviceError = UnauthorizedError | BadRequestError | NotFoundError;

export const removeUserDevice: ServiceMethod<
  RemoveUserDeviceRequest,
  RemoveUserDeviceResponseModel,
  RemoveUserDeviceError
> = (reqParams, req) => {
  const userDeviceDeleteCall = makeCallAPIMethod(RemoveUserDeviceResponseModel);

  return userDeviceDeleteCall<RemoveUserDeviceRequest, RemoveUserDeviceError, RemoveUserDeviceResponseModel>(
    {
      method: 'DELETE',
      pathname: `/api/user-devices/${reqParams.params.id}`,
      preferNonProxied: true,
    },
    req,
  );
};
