import { Oauth2TokenModel } from '@/models/account/Oauth2Token/Oauth2TokenModel';
import { Oauth2TokenAction } from '@/models/account/Oauth2Token/Oauth2TokenType';
import { ServiceMethod } from '@/services/baseService';

import { makeCallAPIMethod } from '../accountService';
import { Oauth2TokenRequest } from './interfaces/Oauth2TokenRequest';

export type Oauth2TokenError = {
  error: string;
  message: string;
  actions: Oauth2TokenAction[];
};

export const oauth2Token: ServiceMethod<Oauth2TokenRequest, Oauth2TokenModel, Oauth2TokenError> = async (
  reqParams,
  req,
) => {
  const call = makeCallAPIMethod(Oauth2TokenModel);

  return call<Oauth2TokenRequest, Oauth2TokenError, Oauth2TokenModel>(
    {
      method: 'POST',
      pathname: '/oauth2/token',
      params: reqParams,
      preferNonProxied: true,
    },
    req,
  );
};
