import type { FeatureFlag, FeatureFlagDurationValue, FeatureFlagKey, FeatureFlagValue } from './types';

export const isEnabled = <T extends FeatureFlagDurationValue>(duration?: T) => {
  if (!duration || !duration.startAt || !duration.endAt) {
    return false;
  }

  const now = new Date().getTime();
  return now >= new Date(duration.startAt).getTime() && now <= new Date(duration.endAt).getTime();
};

export const getFeatureFlagValueByKey = <FlagKey extends FeatureFlagKey>({
  featureFlag = [] as FeatureFlag,
  featureFlagKey,
}: {
  featureFlag?: FeatureFlag;
  featureFlagKey: FlagKey;
}): FeatureFlagValue<FlagKey> | undefined => {
  const featureFlagValue = featureFlag?.find(flag => flag.flagKey === featureFlagKey)?.flagValue as
    | FeatureFlagValue<FlagKey>
    | undefined;

  return featureFlagValue;
};
