import { AccountValidationModel } from '@/models/backendsApi/v1/AccountValidation/AccountValidationModel';
import { ServiceMethod } from '@/services/baseService';
import { getFeatureFlagValueByKey } from '@/utils/featureFlag';

import { makeCallAPIMethod } from '../../backendsApiService';
import { AccountValidationEmailRequest } from './interfaces/AccountValidationEmailRequest';
import { AccountValidationError } from './interfaces/AccountValidationError';

export const accountValidationEmail: ServiceMethod<
  AccountValidationEmailRequest,
  AccountValidationModel,
  AccountValidationError
> = async (reqParams, req) => {
  const { email } = reqParams.body;
  const call = makeCallAPIMethod(
    !!getFeatureFlagValueByKey({
      featureFlag: req?.FeatureFlag || reqParams?.featureFlag,
      featureFlagKey: 'web-internal-backendsapi-request-20240710',
    }),
  )(AccountValidationModel);
  return call<AccountValidationEmailRequest, AccountValidationError, AccountValidationModel>(
    {
      method: 'POST',
      pathname: '/v1/account/validation/email',
      params: {
        body: { email },
      },
      preferNonProxied: true,
    },
    req,
  );
};
