import { GetService, makeServiceMethod } from '../baseService';

export const getService: GetService = ({ path = '/api/notification-api' } = {}) => ({
  host: process.env.NEXT_PUBLIC_NOTIFICATION_API,
  routePrefix: undefined,
  through: {
    host: undefined,
    routePrefix: path,
  },
});

export const makeCallAPIMethod = makeServiceMethod(getService);
