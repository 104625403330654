import {
  Array as RArray,
  Boolean as RBoolean,
  Literal as RLiteral,
  Null as RNull,
  Record as RRecord,
  Static as RStatic,
  String as RString,
} from 'runtypes';

export const RAccountValidation = RRecord({
  success: RLiteral(true),
  message: RNull,
  data: RRecord({
    valid: RBoolean,
    messages: RArray(RString),
  }),
});

export type AccountValidation = RStatic<typeof RAccountValidation>;
