import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

export type Method = 'GET' | 'DELETE' | 'HEAD' | 'OPTIONS' | 'POST' | 'PUT';

export type RequestError<T> = AxiosError<T>;
export type RequestSuccess<T> = [never, AxiosResponse<T>];
export type RequestFailure<T> = [RequestError<T>, never];
export type RequestResult<E, T> = XOR<RequestFailure<E>, RequestSuccess<T>>;

export type RequestConfig = AxiosRequestConfig & { method: Method };

export const { CancelToken } = axios;

export const request = async <E = Any, T = Any>(config: RequestConfig): Promise<RequestResult<E, T>> => {
  try {
    const result = await axios.request<T>(config);
    return [undefined as unknown, result] as RequestSuccess<T>;
  } catch (error) {
    return [error as RequestError<E>, undefined as unknown] as RequestFailure<E>;
  }
};

export * from './requestWithRefreshAuth';
