export enum HttpStatusCodes {
  MovedPermanently = 301,
  Found = 302,
  SeeOther = 303,
  TemporaryRedirect = 307,
  PermanentRedirect = 308,
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  InternalServerError = 500,
}
