import {
  Array as RArray,
  Literal as RLiteral,
  Null as RNull,
  Number as RNumber,
  Record as RRecord,
  Static as RStatic,
  String as RString,
  Union as RUnion,
} from 'runtypes';

import {
  RAiRecommendationItem,
  RAiRecommendationSection,
  RBestSellerItem,
  RBestSellerSection,
  REventHorizontalSection,
  REventSectionItem,
  REventVerticalSection,
  RGroupSection,
  RKeywordItem,
  RKeywordSection,
  RNewReleaseItem,
  RNewReleaseSection,
  ROnePickItem,
  ROnePickSection,
  RQuickMenuItem,
  RQuickMenuSection,
  RReadingBookItem,
  RReadingBookSection,
  RSelectionCarouselItem,
  RSelectionCarouselSection,
  RSelectionCoverItem,
  RSelectionCoverSection,
  RSelectionHookingSentenceItem,
  RSelectionHookingSentenceSection,
  RSelectionItem,
  RSelectionMultilineItem,
  RSelectionMultilineSection,
  RSelectionOriginalItem,
  RSelectionOriginalSection,
  RSelectionSection,
  RTopCarouselBannerItem,
  RTopCarouselBannerSection,
  RTopCarouselBookItem,
  RTopCarouselBookSection,
  RTopCarouselCoverItem,
  RTopCarouselCoverSection,
} from '../Views/ViewsType';

const sectionFields = ['id', 'title', 'contents', 'layout', 'type'] as const;
const RBaseSectionItemsSection = RRecord({
  view_id: RNumber,
});
const RSection = RUnion(
  RAiRecommendationSection.pick(...sectionFields).And(RBaseSectionItemsSection),
  RBestSellerSection.pick(...sectionFields).And(RBaseSectionItemsSection),
  REventHorizontalSection.pick(...sectionFields).And(RBaseSectionItemsSection),
  REventVerticalSection.pick(...sectionFields).And(RBaseSectionItemsSection),
  RGroupSection.pick(...sectionFields).And(RBaseSectionItemsSection),
  RKeywordSection.pick(...sectionFields).And(RBaseSectionItemsSection),
  RNewReleaseSection.pick(...sectionFields).And(RBaseSectionItemsSection),
  ROnePickSection.pick(...sectionFields).And(RBaseSectionItemsSection),
  RSelectionSection.pick(...sectionFields).And(RBaseSectionItemsSection),
  RSelectionCarouselSection.pick(...sectionFields).And(RBaseSectionItemsSection),
  RSelectionCoverSection.pick(...sectionFields).And(RBaseSectionItemsSection),
  RSelectionHookingSentenceSection.pick(...sectionFields).And(RBaseSectionItemsSection),
  RSelectionMultilineSection.pick(...sectionFields).And(RBaseSectionItemsSection),
  RSelectionOriginalSection.pick(...sectionFields).And(RBaseSectionItemsSection),
  RReadingBookSection.pick(...sectionFields).And(RBaseSectionItemsSection),
  RTopCarouselBannerSection.pick(...sectionFields).And(RBaseSectionItemsSection),
  RTopCarouselBookSection.pick(...sectionFields).And(RBaseSectionItemsSection),
  RTopCarouselCoverSection.pick(...sectionFields).And(RBaseSectionItemsSection),
  RQuickMenuSection.pick(...sectionFields).And(RBaseSectionItemsSection),
);

const RSectionItem = RUnion(
  RAiRecommendationItem,
  RBestSellerItem,
  REventSectionItem,
  RKeywordItem,
  RNewReleaseItem,
  ROnePickItem,
  RSelectionItem,
  RSelectionCarouselItem,
  RSelectionCoverItem,
  RSelectionHookingSentenceItem,
  RSelectionMultilineItem,
  RSelectionOriginalItem,
  RReadingBookItem,
  RTopCarouselBannerItem,
  RTopCarouselBookItem,
  RTopCarouselCoverItem,
  RQuickMenuItem,
);

export const RSectionItems = RRecord({
  success: RLiteral(true),
  message: RNull,
  data: RRecord({
    section: RSection,
    section_items: RArray(RSectionItem),
    pagination: RRecord({
      prev_page: RString,
      next_page: RString,
    }),
  }),
});
export type SectionItems = RStatic<typeof RSectionItems>;

export const RSectionItemsError = RRecord({
  success: RLiteral(false),
  message: RString,
  data: RNull,
});

export type SectionItemsError = RStatic<typeof RSectionItemsError>;
