import { useRouter } from 'next/router';

import { FINGERPRINT_KEY } from '@/base/constants';
import { getParsedCookie, setCookie } from '@/utils/cookie';
import sendException from '@/utils/sentry/sendException';

import { useIsomorphicLayoutEffect } from './useIsomorphicLayoutEffect';

export const useFingerprint = () => {
  const router = useRouter();
  useIsomorphicLayoutEffect(() => {
    const cookie = getParsedCookie(document.cookie);
    if (!cookie?.[FINGERPRINT_KEY]) {
      (async () => {
        try {
          const agent = (await import('@fingerprintjs/fingerprintjs')).load({ debug: false, monitoring: false });
          const result = (await agent).get();
          const { visitorId } = await result;
          setCookie(FINGERPRINT_KEY, visitorId, { maxAge: 60 * 60 * 24, sameSite: 'lax', path: '/' });
        } catch (e) {
          if (e instanceof Error) {
            sendException(e, {
              level: 'warning',
            });
          }
        }
      })();
    }
  }, [router]);
};
