import { AnyAction, combineReducers, createAction, createSelector } from '@reduxjs/toolkit';

import { hydrateAction } from '@/features/hydrate';
import type { RootState } from '@/features/store';

import { navigationSlice, NavigationState } from './navigation/navigationSlice';
import { scheduleEventsSlice, ScheduleEventsState } from './scheduleEvents/scheduleEventsSlice';

export interface GlobalNavigationBarState {
  navigation: NavigationState;
  scheduleEvents: ScheduleEventsState;
  shouldUseHistoryNavigation: boolean;
}

export const globalNavigationBarSelector = (state: RootState): GlobalNavigationBarState =>
  state.global.globalNavigationBar;

export const setShouldUseHistoryNavigationAction = createAction<boolean>(
  'global/globalNavigationBar/setShouldUseHistoryNavigationAction',
);

export const shouldUseHistoryNavigationSelector = createSelector(
  globalNavigationBarSelector,
  state => state.shouldUseHistoryNavigation,
);

const shouldUseHistoryNavigationReducer = (state: boolean | undefined, action: AnyAction) => {
  if (setShouldUseHistoryNavigationAction.match(action)) {
    return action.payload;
  }

  if (hydrateAction.match(action)) {
    return shouldUseHistoryNavigationSelector(action.payload);
  }

  return state ?? true;
};

export const globalNavigationBarReducer = (
  state: GlobalNavigationBarState | undefined,
  action: AnyAction,
): GlobalNavigationBarState => {
  const combinedReducers = combineReducers({
    navigation: navigationSlice.reducer,
    scheduleEvents: scheduleEventsSlice.reducer,
    shouldUseHistoryNavigation: shouldUseHistoryNavigationReducer,
  });

  return combinedReducers(state, action);
};

export type GlobalNavigationBarReducerType = ReturnType<typeof globalNavigationBarReducer>;
