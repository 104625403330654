import { createAction } from '@reduxjs/toolkit';

import type { CheckoutInfo, CheckoutPurchaseFinishedBook, CheckoutRenderStates } from './types';

export const setRenderStateAction = createAction(
  'inapp/checkout/checkoutState/setRenderStateAction',
  (newRenderState: CheckoutRenderStates) => ({
    payload: { newRenderState },
  }),
);

export const setCheckoutInfoAction = createAction(
  'inapp/checkout/checkoutState/setCheckoutInfoAction',
  (newCheckoutInfo: CheckoutInfo) => ({
    payload: { newCheckoutInfo },
  }),
);

export const addPurchaseFinishedBooksAction = createAction(
  'inapp/checkout/checkoutState/addPurchaseFinishedBooksAction',
  (books: CheckoutPurchaseFinishedBook[]) => ({
    payload: { books },
  }),
);

export const setIsInTransitionAction = createAction(
  'inapp/checkout/checkoutState/setIsInTransitionAction',
  (isInTransition: boolean) => ({
    payload: { isInTransition },
  }),
);
