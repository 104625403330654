import cookie, { CookieSerializeOptions } from 'cookie';

import {
  ADD_PWA_REQUESTED_KEY,
  ADULT_EXCLUDE_KEY,
  ADULT_INCLUDE_KEY,
  DEAUTH_WATCHDOG_SERVER_TOKEN_KEY,
  FINGERPRINT_KEY,
  FROM_ACCOUNT_MODIFY,
  LOGIN_TIME_KEY,
  RIDI_APP_THEME_KEY,
  RIDI_AUTH_INFO_KEY,
  RIDI_NAVIGATION_KEY,
  USE_GRID_BOOK_LISTING,
} from '@/base/constants';

export type RIDICookies = {
  [RIDI_APP_THEME_KEY]?: string;
  [RIDI_AUTH_INFO_KEY]?: string;
  [RIDI_NAVIGATION_KEY]?: string;
  [ADULT_EXCLUDE_KEY]?: 'y' | 'n';
  [ADULT_INCLUDE_KEY]?: 'y' | 'n';
  [DEAUTH_WATCHDOG_SERVER_TOKEN_KEY]?: string;
  [USE_GRID_BOOK_LISTING]?: string;
  [LOGIN_TIME_KEY]?: string;
  [FROM_ACCOUNT_MODIFY]?: string;
  [FINGERPRINT_KEY]?: string;
  [ADD_PWA_REQUESTED_KEY]?: 'y' | 'n';
};

export type RIDICookiesKey = keyof RIDICookies;

export const getParsedCookie = (cookieString?: string): RIDICookies | null => {
  if (!cookieString) {
    return null;
  }
  return cookie.parse(cookieString);
};

export const setCookie = <K extends RIDICookiesKey = RIDICookiesKey>(
  key: K,
  value: RIDICookies[K],
  options?: CookieSerializeOptions,
): void => {
  if (value === undefined) {
    document.cookie = cookie.serialize(key, '', {
      ...options,
      expires: new Date(0),
    });

    return;
  }

  document.cookie = cookie.serialize(key, value, options);
};
