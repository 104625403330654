import {
  Boolean as RBoolean,
  Number as RNumber,
  Record as RRecord,
  Static as RStatic,
  String as RString,
} from 'runtypes';

export const RDeauthWatchdog = RRecord({
  isPHPSessionValid: RBoolean,
  isRtValid: RBoolean,
  isAtValid: RBoolean,
  isAutoLogin: RBoolean,
  isAutoLoginExist: RBoolean,
  isAutoLoginActive: RBoolean,
  issuedAt: RNumber,
  ffid: RString,
});

export type DeauthWatchdog = RStatic<typeof RDeauthWatchdog>;
