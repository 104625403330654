import { createAction, createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';

import type { ActionRequest, RootState } from '@/features/store';
import { SearchResult } from '@/models/searchApi/Search/SearchType';
import { SearchRequest } from '@/services/searchApi/search/interfaces/SearchRequest';
import { search } from '@/services/searchApi/search/searchService';

import type { SearchState } from '..';

export interface InstantSearchState {
  isLoading: boolean;
  timestamp: number;
  result?: SearchResult;
}

export interface InstantSearchQuery {
  keyword: string;
  isAdultExcluded?: boolean;
}

export const updateInstantSearchAction = createAsyncThunk<
  { result?: SearchResult; timestamp: number },
  ActionRequest<InstantSearchQuery>
>('search/instantSearch/updateInstantSearchAction', async ({ req, reqParams }, thunkAPI) => {
  const requestTimestamp = Date.now();
  if (reqParams.keyword === '') {
    return { timestamp: requestTimestamp };
  }

  const query: SearchRequest = {
    query: {
      keyword: reqParams.keyword,
      adult_exclude: reqParams.isAdultExcluded ? 'y' : 'n',
      where: ['book', 'author'],
      what: 'instant',
    },
  };

  const [error, model] = await search(query, req);

  if (error) {
    return thunkAPI.rejectWithValue({
      message: error.message,
      data: error.response?.data,
    });
  }

  return {
    result: model.result,
    timestamp: requestTimestamp,
  };
});

export const resetInstantSearchAction = createAction('search/instantSearch/resetInstantSearchAction');

export const instantSearchSelector = createSelector(
  (state: RootState) => state.search,
  (state: SearchState) => state.instantSearch,
);

export const instantSearchSlice = createSlice({
  name: 'search/instantSearch',

  initialState: {
    isLoading: false,
    timestamp: Date.now(),
  } as InstantSearchState,

  reducers: {},

  extraReducers: builder => {
    builder
      .addCase(resetInstantSearchAction, (state, _) => {
        delete state.result;
        state.isLoading = false;
        state.timestamp = Date.now();
      })
      .addCase(updateInstantSearchAction.pending, (state, _) => {
        state.isLoading = true;
      })
      .addCase(updateInstantSearchAction.fulfilled, (state, action) => {
        if (state.timestamp > action.payload.timestamp) {
          return;
        }

        state.timestamp = action.payload.timestamp;
        state.result = action.payload.result;
        state.isLoading = false;
      });
  },
});
