import { AccountValidationModel } from '@/models/backendsApi/v1/AccountValidation/AccountValidationModel';
import { ServiceMethod } from '@/services/baseService';
import { getFeatureFlagValueByKey } from '@/utils/featureFlag';

import { makeCallAPIMethod } from '../../backendsApiService';
import { AccountValidationError } from './interfaces/AccountValidationError';
import { AccountValidationPasswordRequest } from './interfaces/AccountValidationPasswordRequest';

export const accountValidationPassword: ServiceMethod<
  AccountValidationPasswordRequest,
  AccountValidationModel,
  AccountValidationError
> = async (reqParams, req) => {
  const { user_id: userId, password } = reqParams.body;
  const call = makeCallAPIMethod(
    !!getFeatureFlagValueByKey({
      featureFlag: req?.FeatureFlag || reqParams?.featureFlag,
      featureFlagKey: 'web-internal-backendsapi-request-20240710',
    }),
  )(AccountValidationModel);

  return call<AccountValidationPasswordRequest, AccountValidationError, AccountValidationModel>(
    {
      method: 'POST',
      pathname: '/v1/account/validation/password',
      params: {
        body: { user_id: userId, password },
      },
      preferNonProxied: true,
    },
    req,
  );
};
