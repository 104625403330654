import { createSelector } from '@reduxjs/toolkit';

import type { State } from '@/features/rootReducer';
import { CheckoutCouponItem, CheckoutCouponType, PointBack } from '@/models/backendsApi/v1/Checkout/CheckoutType';

import type { CheckoutState } from './checkoutSlice';

const checkoutStateSelector = (state: State): CheckoutState => state.inapp.checkout.checkout;

export const checkoutLastErrorSelector = createSelector(
  checkoutStateSelector,
  checkoutState => checkoutState.requestStatus.lastError,
);

// 현재 참조하고 있는 orderType
export const checkoutOrderTypeSelector = createSelector(checkoutStateSelector, state => state.orderType);
export const checkoutOrderItemTypeSelector = createSelector(
  checkoutStateSelector,
  state => state.requestInfo?.orderItemType,
);

// 앞으로 바뀔 orderType
export const checkoutDesiredOrderTypeSelector = createSelector(
  checkoutStateSelector,
  state => state.requestStatus.desiredOrderType,
);

export const checkoutIsLoadingSelector = createSelector(
  checkoutStateSelector,
  state => state.requestStatus.isRefreshing || state.requestStatus[state.requestStatus.desiredOrderType].isLoading,
);

const checkoutByOrderTypeSelector = createSelector(
  checkoutStateSelector,
  (state: CheckoutState) => state[state.orderType],
);

export const checkoutAvailableOrderTypesSelector = createSelector(
  checkoutByOrderTypeSelector,
  checkoutByOrderType => checkoutByOrderType?.checkout.available_order_types,
);

export const checkoutCSRFTokenSelector = createSelector(
  checkoutByOrderTypeSelector,
  checkoutByOrderType => checkoutByOrderType?.csrf_token,
);

export const checkoutPaymentSelector = createSelector(
  checkoutByOrderTypeSelector,
  checkoutByOrderType => checkoutByOrderType?.checkout.payment,
);

export const checkoutCommonNoticesSelector = createSelector(
  checkoutByOrderTypeSelector,
  checkoutByOrderType => checkoutByOrderType?.checkout.notices.filter(notice => notice.level === 1) || [],
);

export const checkoutEmphasizedNoticesSelector = createSelector(
  checkoutByOrderTypeSelector,
  checkoutByOrderType => checkoutByOrderType?.checkout.notices.filter(notice => notice.level === 2) || [],
);

export const checkoutFixedNoticesSelector = createSelector(
  checkoutByOrderTypeSelector,
  checkoutByOrderType => checkoutByOrderType?.checkout.notices.filter(notice => notice.level === 3) || [],
);

export const checkoutOrderSelector = createSelector(
  checkoutByOrderTypeSelector,
  checkoutByOrderType => checkoutByOrderType?.checkout.order,
);

export const checkoutBookIdsSelector = createSelector(
  checkoutOrderSelector,
  order => order?.items.map(item => item.book_id) ?? [],
);

export const checkoutHasAdultItemsSelector = createSelector(
  checkoutOrderSelector,
  order => !!order?.unavailable_adults_only_items?.length,
);

export const checkoutCouponSelector = createSelector(
  checkoutByOrderTypeSelector,
  checkoutByOrderType => checkoutByOrderType?.checkout.coupon,
);

export const checkoutAllCouponsSelector = createSelector(checkoutCouponSelector, coupon => coupon?.coupons || []);

export const checkoutAppliedCouponsSelector = createSelector(checkoutAllCouponsSelector, coupons =>
  coupons.reduce<{ [key in CheckoutCouponType]?: CheckoutCouponItem }>(
    (appliedCoupons, coupon) => (coupon.is_applied ? { ...appliedCoupons, [coupon.group]: coupon } : appliedCoupons),
    {},
  ),
);

export const checkoutHasMostBeneficialSelector = createSelector(
  checkoutCouponSelector,
  coupon => !!coupon?.most_beneficial_combinations?.length,
);

export const checkoutIsMostBeneficialSelector = createSelector(checkoutCouponSelector, coupons => {
  const couponAppliedStates = (coupons?.coupons ?? []).reduce(
    (appliedStates, coupon) => ({ ...appliedStates, [coupon.id]: coupon.is_applied }),
    Object.create(null) as Record<string, boolean>,
  );

  return coupons?.most_beneficial_combinations.some(combination => combination.every(id => couponAppliedStates[id]));
});

export const checkoutRidipointSelector = createSelector(
  checkoutByOrderTypeSelector,
  checkoutByOrderType => checkoutByOrderType?.checkout.ridipoint,
);

export const checkoutRidicashSelector = createSelector(
  checkoutByOrderTypeSelector,
  checkoutByOrderType => checkoutByOrderType?.checkout.ridicash,
);

export const checkoutPointbackSelector = createSelector(checkoutStateSelector, state =>
  state.rental?.checkout.order.items
    .map(item => item.rental?.pointback)
    .filter((pointback): pointback is PointBack => !!pointback)
    .reduce<PointBack | null>((previous, current) => {
      if (previous === null) {
        return { ...current };
      }

      return {
        amount: previous.amount + current.amount,
        duration_days: Math.min(previous.duration_days, current.duration_days),
      };
    }, null),
);

export const checkoutSetBookIdByBookIdSelector = createSelector(checkoutOrderSelector, order =>
  order?.items.reduce((map, item) => map.set(item.book_id, item.set?.set_id ?? null), new Map<string, string | null>()),
);
