import type { ParsedQs } from 'qs';

import { GenreType } from '@/base/constants/genreMap';
import { isBrowser } from '@/utils/isBrowser';

// genre가 GenreType에 속하지 않는 경우 general(default)
export const genreValidator = (genre: string | string[] | undefined): GenreType =>
  Object.values(GenreType).includes(genre as GenreType) ? (genre as GenreType) : ('general' as GenreType);

// page가 자연수가 아니면 1(default)
export const pageValidator = (page: string | string[] | undefined): number => {
  const pageNumber = Number(page);
  return pageNumber > 0 && Number.isInteger(pageNumber) ? pageNumber : 1;
};

// 계산된 offset이 자연수가 아니면 0(default)
export const offsetCalculator = (page: number, itemsPerPage: number): number => {
  const offset = (page - 1) * itemsPerPage;
  return offset > 0 && Number.isInteger(offset) ? offset : 0;
};

export interface RDTTrackingURIParams {
  sectionId: string;
  sectionItemIdx?: number;
  sectionArg?: string;
}

export const getRDTGenrePrefix = (): string =>
  isBrowser() ? `${window.location.pathname.replace(/-/g, '_').split('/').filter(Boolean).join('_')}_` : '';

export const searchParamsToParsedQs = (searchParams: URLSearchParams): ParsedQs => {
  const queryString = Object.create(null) as Record<string, string | string[]>;
  searchParams.forEach((value, key) => {
    const existingValue = queryString[key];

    if (typeof existingValue === 'string') {
      queryString[key] = [existingValue, value];
      return;
    }

    if (Array.isArray(existingValue)) {
      queryString[key] = [...existingValue, value];
      return;
    }

    queryString[key] = value;
  });

  return queryString;
};

export { addRDTTrackingURIQuery } from '@ridi-web/design-system/Utils/cjs';
