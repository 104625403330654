import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { ActionRequest, RootState } from '@/features/store';
import { AccountValidation } from '@/models/backendsApi/v1/AccountValidation/AccountValidationType';
import { AccountActionChangePassword } from '@/models/booksBackend/ChangePasswordModel/AccountActionChangePasswordType';
import { ProlongPasswordExpiration } from '@/models/booksBackend/ProlongPasswordExpirationModel/ProlongPasswordExpirationType';
import { AccountActionValidateResult } from '@/models/booksBackend/ValidateResultModel/AccountActionValidateResultType';
import { accountValidationPassword } from '@/services/backendsApi/v1/accountValidation/accountValidationPasswordService';
import { accountActionIsCurrentPassword } from '@/services/booksBackend/accountValidator/accountActionIsCurrentPasswordService';
import { accountActionChangePassword } from '@/services/booksBackend/changePassword/accountActionChangePasswordService';
import { ProlongPasswordExpirationRequest } from '@/services/booksBackend/prolongPasswordExpiration/interfaces/ProlongPasswordExpirationRequest';
import { prolongPasswordExpiration } from '@/services/booksBackend/prolongPasswordExpiration/prolongPasswordExpirationService';
import { debug } from '@/utils/debug';

import type { AccountState } from '..';

const log = debug('changePasswordSlice');

export const changePasswordSelector = createSelector(
  (state: RootState) => state.account,
  (state: AccountState) => state.changePassword,
);

export interface PasswordState {
  inputValue: string;
  isValid: boolean;
  isDirty: boolean;
  isLoading: boolean;
}

const initialPasswordState: PasswordState = {
  inputValue: '',
  isValid: false,
  isDirty: false,
  isLoading: false,
};

export interface ChangePasswordState {
  currentPassword: PasswordState;
  newPassword: PasswordState;
  reenterPassword: PasswordState;
  isChangeButtonLoading: boolean;
  isProlongButtonLoading: boolean;
  currentPasswordMessage: string;
  newPasswordMessages: string[] | string;
}

const initialState: ChangePasswordState = {
  currentPassword: { ...initialPasswordState },
  newPassword: { ...initialPasswordState },
  reenterPassword: { ...initialPasswordState },
  isChangeButtonLoading: false,
  isProlongButtonLoading: false,
  currentPasswordMessage: '',
  newPasswordMessages: [],
};

export const changePasswordAction = createAsyncThunk<
  AccountActionChangePassword,
  ActionRequest<{ body: { password: string; new_password: string } }>,
  {
    rejectValue: { message: string; status: number | undefined };
  }
>('account/changePasswordAction', async ({ reqParams }, thunkAPI) => {
  const [error, model] = await accountActionChangePassword(reqParams);

  if (error) {
    return thunkAPI.rejectWithValue({
      message: error.message,
      status: error.response?.status,
    });
  }

  return model.Data;
});

export const prolongPasswordExpirationAction = createAsyncThunk<
  ProlongPasswordExpiration,
  ActionRequest<ProlongPasswordExpirationRequest>,
  {
    rejectValue: { status: number | undefined };
    state: RootState;
  }
>('account/prolongPasswordExpirationAction', async (_, thunkAPI) => {
  const [error, model] = await prolongPasswordExpiration();

  if (error) {
    return thunkAPI.rejectWithValue({
      status: error.response?.status,
    });
  }

  return model.Data;
});

export const validateCurrentPasswordAction = createAsyncThunk<
  AccountActionValidateResult,
  ActionRequest<{ body: { password: string } }>
>('account/validator/fetchCurrentPasswordValidation', async ({ reqParams }, thunkAPI) => {
  const { password } = reqParams.body;

  if (!password) {
    return { success: false, message: '비밀번호를 입력해주세요.' };
  }

  const [error, model] = await accountActionIsCurrentPassword(reqParams);

  if (error) {
    return thunkAPI.rejectWithValue({
      message: error.message,
      data: error.response?.data,
    });
  }

  return model.Data;
});

export const validateNewPasswordAction = createAsyncThunk<
  AccountValidation,
  ActionRequest<{ body: { user_id: string; password: string } }>
>('account/validator/validateNewPasswordAction', async ({ reqParams }, thunkAPI) => {
  const { password } = reqParams.body;

  if (!password) {
    return {
      success: true,
      message: null,
      data: {
        valid: false,
        messages: ['비밀번호를 입력해주세요.'],
      },
    };
  }

  const [error, model] = await accountValidationPassword(reqParams);

  if (error) {
    return thunkAPI.rejectWithValue({
      message: error.message,
      data: error.response?.data,
    });
  }

  return model.Data;
});

export const validateReenterPasswordAction = createAsyncThunk<
  AccountValidation,
  ActionRequest<{ body: { user_id: string; password: string; reenterPassword: string } }>
>('account/validator/validateReenterPasswordAction', async ({ reqParams }, thunkAPI) => {
  const { password, reenterPassword } = reqParams.body;

  if (!password) {
    return {
      success: true,
      message: null,
      data: {
        valid: false,
        messages: ['비밀번호를 입력해주세요.'],
      },
    };
  }
  if (password && !reenterPassword) {
    return {
      success: true,
      message: null,
      data: {
        valid: false,
        messages: ['비밀번호를 재입력해주세요.'],
      },
    };
  }
  if (password !== reenterPassword) {
    return {
      success: true,
      message: null,
      data: {
        valid: false,
        messages: ['비밀번호가 일치하지 않습니다.'],
      },
    };
  }

  const [error, model] = await accountValidationPassword(reqParams);

  if (error) {
    return thunkAPI.rejectWithValue({
      message: error.message,
      data: error.response?.data,
    });
  }

  return model.Data;
});

export const changePasswordSlice = createSlice({
  name: 'account/changePassword',
  initialState,
  reducers: {
    onChangeCurrentPassword: (state, action: PayloadAction<string>) => {
      state.currentPassword.inputValue = action.payload;
      state.currentPassword.isValid = false;
    },
    onChangeNewPassword: (state, action: PayloadAction<string>) => {
      state.newPassword.inputValue = action.payload;
      state.newPassword.isValid = false;
    },
    onChangeReenterPassword: (state, action: PayloadAction<string>) => {
      state.reenterPassword.inputValue = action.payload;
      state.reenterPassword.isValid = false;
    },
  },
  extraReducers: builder => {
    // changePasswordAction
    builder.addCase(changePasswordAction.pending, state => {
      log('## changePasswordAction.pending', state);
      state.isChangeButtonLoading = true;
    });
    builder.addCase(changePasswordAction.fulfilled, (state, action) => {
      log('## changePasswordAction.fulfilled', state, action);
      state.isChangeButtonLoading = false;
    });
    builder.addCase(changePasswordAction.rejected, (state, action) => {
      log('## changePasswordAction.rejected', state, action);
      state.isChangeButtonLoading = false;
    });
    // prolongPasswordExpirationAction
    builder.addCase(prolongPasswordExpirationAction.pending, state => {
      log('## prolongPasswordExpirationAction.pending', state);
      state.isProlongButtonLoading = true;
    });
    builder.addCase(prolongPasswordExpirationAction.fulfilled, (state, action) => {
      log('## prolongPasswordExpirationAction.fulfilled', state, action);
      state.isProlongButtonLoading = false;
    });
    builder.addCase(prolongPasswordExpirationAction.rejected, (state, action) => {
      log('## prolongPasswordExpirationAction.rejected', state, action);
      state.isProlongButtonLoading = false;
    });
    // validateCurrentPasswordAction
    builder.addCase(validateCurrentPasswordAction.pending, state => {
      log('## validateCurrentPasswordAction.pending', state);
      state.currentPassword.isLoading = true;
    });
    builder.addCase(validateCurrentPasswordAction.fulfilled, (state, action) => {
      log('## validateCurrentPasswordAction.fulfilled', state, action);
      state.currentPassword.isValid = action.payload.success;
      state.currentPassword.isDirty = true;
      state.currentPassword.isLoading = false;
      if (action.payload.success) {
        state.currentPasswordMessage = '';
      } else {
        state.currentPasswordMessage =
          // TODO: current password validation은 현재 API가 message 필드만 내려주지만 validation API들의 model type을 공유하기 위해 messages[0]를 임시로 작성
          'message' in action.payload ? action.payload.message : action.payload.messages[0];
      }
    });
    builder.addCase(validateCurrentPasswordAction.rejected, (state, action) => {
      log('## validateCurrentPasswordAction.rejected', state, action);
      state.currentPassword.isLoading = false;
      state.currentPasswordMessage = '네트워크 오류가 발생했습니다.';
    });
    // validateNewPasswordAction
    builder.addCase(validateNewPasswordAction.pending, state => {
      log('## validateNewPasswordAction.pending', state);
      state.newPassword.isLoading = true;
    });
    builder.addCase(validateNewPasswordAction.fulfilled, (state, action) => {
      log('## validateNewPasswordAction.fulfilled', state, action);
      const { valid, messages } = action.payload.data;
      state.newPassword.isValid = valid;
      state.newPassword.isDirty = true;
      state.newPassword.isLoading = false;
      state.newPasswordMessages = valid ? '' : messages;
    });
    builder.addCase(validateNewPasswordAction.rejected, (state, action) => {
      log('## validateNewPasswordAction.rejected', state, action);
      state.newPassword.isLoading = false;
      state.newPasswordMessages = '네트워크 오류가 발생했습니다.';
    });
    // validateReenterPasswordAction
    builder.addCase(validateReenterPasswordAction.pending, state => {
      log('## validateReenterPasswordAction.pending', state);
      state.reenterPassword.isLoading = true;
    });
    builder.addCase(validateReenterPasswordAction.fulfilled, (state, action) => {
      log('## validateReenterPasswordAction.fulfilled', state, action);
      const { valid, messages } = action.payload.data;

      state.reenterPassword.isValid = valid;
      state.reenterPassword.isDirty = true;
      state.newPassword.isDirty = true;
      state.reenterPassword.isLoading = false;
      state.newPasswordMessages = valid ? '' : messages;
    });
    builder.addCase(validateReenterPasswordAction.rejected, (state, action) => {
      log('## validateReenterPasswordAction.rejected', state, action);
      state.reenterPassword.isLoading = false;
      state.newPasswordMessages = '네트워크 오류가 발생했습니다.';
    });
  },
});
