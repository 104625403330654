import { Action, AnyAction, configureStore, Store } from '@reduxjs/toolkit';
import { IncomingMessage } from 'http';
import type { GetStaticProps, GetStaticPropsContext, GetStaticPropsResult, NextPageContext, PreviewData } from 'next';
import { GetServerSidePropsContext, GetServerSidePropsResult } from 'next';
import { AppInitialProps } from 'next/app';
import { Router } from 'next/dist/client/router';
import { AppContextType } from 'next/dist/shared/lib/utils';
import { Config, Context, createWrapper as originCreateWrapper } from 'next-redux-wrapper';
import { ParsedUrlQuery } from 'querystring';
import { QueryClient } from 'react-query';

import { HttpStatusCodes } from '@/base/constants/httpStatusCodes';
import { ServerRequest } from '@/base/interfaces/ServerRequest';
import { DefaultServerRequestParameters, ServerRequestParameters } from '@/base/interfaces/ServerRequestParameters';
import { ServerResponse } from '@/base/interfaces/ServerResponse';
import { rootReducer, State } from '@/features/rootReducer';

const isDevelopment = process.env.NODE_ENV === 'development';

interface ActionRequestMandatory<T> {
  reqParams: T;
  req?: ServerRequest<DefaultServerRequestParameters>;
  cache?: {
    seconds: number;
  };
}

interface ActionRequestOptional {
  req?: ServerRequest<DefaultServerRequestParameters>;
  cache?: {
    seconds: number;
  };
}

// 가능한 사용 케이스:
// ActionRequest<void> ->                                 { req: ServerRequest<DefaultServerRequestParameters> }
// ActionRequest<{ aaa: bbb }> ->     { reqParams: { aaa }, req: ServerRequest<DefaultServerRequestParameters> }
// ActionRequest<void, MyServiceRequest> ->                             { req: ServerRequest<MyServiceRequest> }
// ActionRequest<{ aaa: bbb }, MyServiceRequest> -> { reqParams: { aaa }, req: ServerRequest<MyServiceRequest> }
// ActionRequest<MyServiceRequest> ->      { reqParams: MyServiceRequest, req: ServerRequest<MyServiceRequest> }
export type ActionRequest<T> = T extends void ? ActionRequestOptional : ActionRequestMandatory<T>;

type AnyObject = Record<string, Any>;

export interface AppStore<S, A extends Action = AnyAction> extends Store<S, A> {
  dispatch: AppDispatch;
}

type MakeStore<S extends Store> = (context: Context) => S;

export interface ErrorPageProps {
  error: {
    statusCode:
      | HttpStatusCodes.BadRequest
      | HttpStatusCodes.Unauthorized
      | HttpStatusCodes.NotFound
      | HttpStatusCodes.InternalServerError;
    title?: string;
    message?: string;
  };
}

export type GetServerSidePropsWrapperContext<Q extends AnyObject = AnyObject> = GetServerSidePropsContext<Q> & {
  req: ServerRequest & GetServerSidePropsContext['req'];
  res: ServerResponse;
};

export type GetServerSidePropsWrapperOptions = {
  withCsrfToken?: boolean;
};

type GetServerSidePropsWrapperAdditionalProps = {
  csrfToken: string;
};

export type GetServerSidePropsReturnWrapper<
  Props extends { [key: string]: Any } = { [key: string]: Any },
  Params extends ParsedUrlQuery = ParsedUrlQuery,
  Preview extends PreviewData = PreviewData,
> = (context: GetServerSidePropsContext<Params, Preview>) => Promise<GetServerSidePropsResult<Props>>;

export type GetServerSidePropsWrapperCallbackParam<
  S extends Store<State, AnyAction>,
  P extends AnyObject = AnyObject,
  Q extends AnyObject = AnyObject,
> = (
  ...params: [store: S, sharedQuery: QueryClient, additionalParams: GetServerSidePropsWrapperAdditionalProps]
) => (
  context: GetServerSidePropsWrapperContext<Q>,
) => Promise<GetServerSidePropsResult<P | ErrorPageProps>> | GetServerSidePropsResult<P | ErrorPageProps>;

export type GetStaticPropsWrapperCallbackParam<
  S extends Store<State, AnyAction>,
  P extends AnyObject = AnyObject,
  Q extends AnyObject = AnyObject,
  D extends AnyObject = AnyObject,
> = (
  store: S,
) => (
  context: GetStaticPropsContext<Q, D>,
) => Promise<GetStaticPropsResult<P | ErrorPageProps>> | GetStaticPropsResult<P | ErrorPageProps>;

export type GetServerSidePropsWrapper<S extends Store<State, AnyAction>> = <
  P extends AnyObject = AnyObject,
  Q extends AnyObject = AnyObject,
  O extends AnyObject = GetServerSidePropsWrapperOptions,
>(
  callback: GetServerSidePropsWrapperCallbackParam<S, P, Q>,
  options?: O,
) => GetServerSidePropsReturnWrapper<P | ErrorPageProps, Q>;

export type GetStaticPropsWrapper<S extends Store<State, AnyAction>> = <
  P extends AnyObject = AnyObject,
  Q extends AnyObject = AnyObject,
  D extends AnyObject = AnyObject,
>(
  callback: GetStaticPropsWrapperCallbackParam<S, P, Q, D>,
) => GetStaticProps<P | ErrorPageProps, Q, D>;

type OriginalCreateWrapper = ReturnType<typeof originCreateWrapper>;
interface RidibooksAppContextType<P extends ServerRequestParameters> extends AppContextType<Router> {
  ctx: NextPageContext & {
    req: ServerRequest<P> & IncomingMessage;
  };
}

const createWrapper = originCreateWrapper as unknown as <S extends Store<State, AnyAction>>(
  makeStore: MakeStore<S>,
  config?: Config<S>,
) => {
  getServerSideProps: GetServerSidePropsWrapper<S>;
  getStaticProps: GetStaticPropsWrapper<S>;
  getInitialAppProps: <P extends AnyObject = AnyObject>(
    callback: (store: S) => ({ Component, ctx }: RidibooksAppContextType<P>) => Promise<AppInitialProps>,
  ) => ({ Component, ctx }: AppContextType<Router>) => Promise<AppInitialProps>;
  getInitialPageProps: OriginalCreateWrapper['getInitialPageProps'];
  withRedux: OriginalCreateWrapper['withRedux'];
};

const createStore = () =>
  configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: undefined,
        },
        immutableCheck: isDevelopment,
        serializableCheck: isDevelopment,
      }),
    devTools: isDevelopment,
  });

export const store = createStore();

export type RootState = ReturnType<ReturnType<typeof createStore>['getState']>;
export type AppDispatch = ReturnType<typeof createStore>['dispatch'];

export const wrapper = createWrapper<AppStore<State>>(() => createStore());

export type { State };

// @internal
export type { RidibooksAppContextType };
