import { AccountSignupCreateAccountModel } from '@/models/backendsApi/v1/AccountSignupCreateAccount/AccountSignupCreateAccountModel';
import { ServiceMethod } from '@/services/baseService';
import { getFeatureFlagValueByKey } from '@/utils/featureFlag';

import { makeCallAPIMethod } from '../../backendsApiService';
import { AccountSignupCreateAccountError } from './interfaces/AccountSignupCreateAccountError';
import { AccountSignupCreateAccountRequest } from './interfaces/AccountSignupCreateAccountRequest';

export const accountSignupCreateAccount: ServiceMethod<
  AccountSignupCreateAccountRequest,
  AccountSignupCreateAccountModel,
  AccountSignupCreateAccountError
> = async (reqParams, req) => {
  const call = makeCallAPIMethod(
    !!getFeatureFlagValueByKey({
      featureFlag: req?.FeatureFlag || reqParams.featureFlag,
      featureFlagKey: 'web-internal-backendsapi-request-20240710',
    }),
  )(AccountSignupCreateAccountModel);
  return call<AccountSignupCreateAccountRequest, AccountSignupCreateAccountError, AccountSignupCreateAccountModel>(
    {
      method: 'POST',
      pathname: '/v1/account/signup/create-account',
      params: {
        body: reqParams.body,
      },
      withCredentials: true,
      preferNonProxied: true,
    },
    req,
  );
};
