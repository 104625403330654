import { BookApiModel } from '@/models/bookApi/Books/BooksModel';
import { getCacheKey, makeServiceAction } from '@/services/baseService';

import { makeCallAPIMethod } from '../bookApiService';
import { BooksRequest } from './interfaces/BooksRequest';

export type BooksError = {
  code: string;
  message: string;
};

const makeCallMethod = () => makeCallAPIMethod(BookApiModel);
const makeCacheKey = (reqParams: BooksRequest) => getCacheKey('bookApi', 'fetchBooks', reqParams);

export const fetchBooks = makeServiceAction<BooksRequest, BooksError, BookApiModel>(makeCallMethod(), reqParams => ({
  method: 'GET',
  pathname: '/books',
  params: {
    query: {
      ...reqParams.query,
    },
  },
  preferNonProxied: true,
}))(BookApiModel, makeCacheKey);
