import {
  Literal as RLiteral,
  Record as RRecord,
  Static as RStatic,
  String as RString,
  Union as RUnion,
} from 'runtypes';

export const RAccountActionFindPasswordSuccess = RRecord({
  success: RLiteral(true),
  email: RString,
});

export const RAccountActionFindPasswordFailure = RRecord({
  success: RLiteral(false),
  message: RString,
});

export const RAccountActionFindPassword = RUnion(RAccountActionFindPasswordSuccess, RAccountActionFindPasswordFailure);

export type AccountActionFindPassword = RStatic<typeof RAccountActionFindPassword>;
