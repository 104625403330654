import { AccountActionFindPasswordModel } from '@/models/booksBackend/FindPasswordModel/AccountActionFindPasswordModel';
import { ServiceMethod, stringifyRequestParams } from '@/services/baseService';
import { getFeatureFlagValueByKey } from '@/utils/featureFlag';

import { makeCallAPIMethod } from '../booksBackendService';
import { AccountActionFindPasswordRequest } from './interfaces/AccountActionFindPasswordRequest';

export const accountActionFindPassword: ServiceMethod<
  AccountActionFindPasswordRequest,
  AccountActionFindPasswordModel
> = async reqParams => {
  const call = makeCallAPIMethod(
    !!getFeatureFlagValueByKey({
      featureFlag: reqParams.featureFlag,
      featureFlagKey: 'web-internal-books-backendsapi-request-20240813',
    }),
  )(AccountActionFindPasswordModel);
  return call<AccountActionFindPasswordRequest, Error, AccountActionFindPasswordModel>({
    method: 'POST',
    pathname: '/account/action/find-password',
    params: { body: reqParams.body },
    transformRequest: [stringifyRequestParams],
    preferNonProxied: true,
  });
};
