import { BaseModel } from '@/models/BaseModel';

import {
  AccountParentalConsentRequiredData,
  RAccountParentalConsentRequiredData,
} from './AccountParentalConsentRequiredDataType';

export class AccountParentalConsentRequiredDataModel extends BaseModel<AccountParentalConsentRequiredData> {
  constructor(data: AccountParentalConsentRequiredData) {
    super(data, RAccountParentalConsentRequiredData);
  }
}
