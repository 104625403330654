import { BaseModel } from '@/models/BaseModel';

import { RSearch, Search, SearchAuthorResult, SearchBookResult, SearchResult } from './SearchType';

export class SearchModel extends BaseModel<Search> {
  constructor(data: Search) {
    super(data, RSearch);
  }

  get result(): SearchResult {
    if ((this.Data as SearchAuthorResult).authors) {
      return {
        author: this.Data as SearchAuthorResult,
        book: {
          books: [],
        },
      };
    }

    if ((this.Data as SearchBookResult).books) {
      return {
        author: {
          authors: [],
        },
        book: this.Data as SearchBookResult,
      };
    }

    return this.Data as SearchResult;
  }
}
