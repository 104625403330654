import { needsOpenBookSelector } from '../../checkoutForm/checkoutFormSlice';
import { purchaseFinishedBooksSelector } from '../selectors';
import { CheckoutStates, CheckoutStateTypes } from '../types';
import { createTransitionThunk } from '../utils/createTransitionThunk';

export const abortTransitionAction = createTransitionThunk(
  'abortTransition',
  null,
  (_previousStatus, _args, { getState }) => {
    const hasPurchaseFinishedBooks = purchaseFinishedBooksSelector(getState());
    if (!hasPurchaseFinishedBooks.length) {
      return { state: CheckoutStateTypes.FAILURE_ABORTED } as CheckoutStates;
    }

    const needsOpenBook = needsOpenBookSelector(getState());

    return {
      state: CheckoutStateTypes.SUCCESS,
      message: '결제가 완료되었습니다.',
      isWaitFree: false,
      needsOpenBook: needsOpenBook.defaultByApp,
      eventType: 'abort',
    };
  },
);
