import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { hydrateAction } from '@/features/hydrate';
import type { ActionRequest, AppDispatch, RootState } from '@/features/store';
import { BookApiResponseUnit } from '@/models/bookApi/Books/BooksTypes';
import {
  newReleases,
  NewReleasesQuery,
  newReleasesQueryBuilder,
} from '@/services/backendsApi/v1/newReleases/newReleasesService';
import { fetchBooks } from '@/services/bookApi/books/booksService';
import { fetchBooksDescriptions } from '@/services/bookApi/descriptions/booksDescriptionsService';

export const newReleasesSelector = (state: RootState): NewReleasesState => state.newReleases;

export interface NewReleasesState {
  isLoading: boolean;
  books: FetchNewReleasesActionResponse | null;
  isGridStyle: boolean;
}

const initialState: NewReleasesState = {
  isLoading: true,
  books: null,
  isGridStyle: true,
};

interface FetchNewReleasesActionRequest {
  query: {
    query: NewReleasesQuery;
    offset?: string;
    limit?: string;
  };
}

export type RatingsType = { rating: number; count: number }[];

export interface FetchNewReleasesActionResponseUnit extends BookApiResponseUnit {
  description?: string;
  ratings: RatingsType;
}

export type FetchNewReleasesActionResponse = FetchNewReleasesActionResponseUnit[];

export const fetchNewReleasesAction = createAsyncThunk<
  FetchNewReleasesActionResponse,
  ActionRequest<FetchNewReleasesActionRequest>,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>('newReleases', async ({ reqParams }, thunkAPI) => {
  const reqQuery = newReleasesQueryBuilder(reqParams.query.query);

  const [newReleasesError, newReleasesModel] = await newReleases({
    query: {
      ...reqParams.query,
      query: reqQuery,
    },
  });

  if (newReleasesError) {
    return thunkAPI.rejectWithValue({
      message: newReleasesError.message,
      data: newReleasesError.response?.data,
    });
  }

  // newReleases의 응답에서 book_id만 모은 배열 생성
  const newReleasesBookIds = newReleasesModel.Data.data.new_releases.map(book => book.book_id);

  const [fetchBooksError, fetchBooksModel] = await fetchBooks({ query: { b_ids: newReleasesBookIds.toString() } });

  if (fetchBooksError) {
    return thunkAPI.rejectWithValue({
      message: fetchBooksError.message,
      data: fetchBooksError.response?.data,
    });
  }

  const [booksDescriptionError, booksDescriptionsModel] = await fetchBooksDescriptions({
    query: { b_ids: newReleasesBookIds.toString() },
  });

  if (booksDescriptionError) {
    return thunkAPI.rejectWithValue({
      message: booksDescriptionError.message,
      data: booksDescriptionError.response?.data,
    });
  }

  const booksWithDescriptionAndRatings = booksDescriptionsModel.Data.map((book, index) => ({
    ...fetchBooksModel.Data[index],
    ratings: newReleasesModel.Data.data.new_releases[index].ratings,
    description: book.descriptions.intro,
  }));

  return booksWithDescriptionAndRatings;
});

export const newReleasesSlice = createSlice({
  name: 'newReleases',
  initialState,
  reducers: {
    setIsGridStyle: (state, action: PayloadAction<boolean>) => {
      state.isGridStyle = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchNewReleasesAction.pending, (state, _) => {
        state.isLoading = true;
      })
      .addCase(fetchNewReleasesAction.rejected, (state, _) => {
        state.isLoading = false;
      })
      .addCase(fetchNewReleasesAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.books = action.payload;
      });

    builder.addCase(hydrateAction, (state, action) => {
      const nextState: NewReleasesState = {
        ...state,
        ...action.payload.newReleases,
      };

      if (!state.isGridStyle) {
        nextState.isGridStyle = state.isGridStyle;
      }

      return nextState;
    });
  },
});
