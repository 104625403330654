import { checkoutAppliedCouponsSelector, checkoutOrderSelector } from '../../checkout/checkoutSlice';
import { checkoutStateInfoSelector } from '../selectors';
import { CheckoutStateTypes } from '../types';
import { createTransitionThunk } from '../utils/createTransitionThunk';

export const checkoutTransitionAction = createTransitionThunk(
  'checkoutTransition',
  CheckoutStateTypes.FETCH_CHECKOUT_WAITING,
  async (_previousStates, _args, { getState }) => {
    const initialState = getState();
    const checkoutInfo = checkoutStateInfoSelector(initialState);
    const order = checkoutOrderSelector(initialState);
    const coupons = checkoutAppliedCouponsSelector(initialState);
    const isCouponApplied = !!coupons?.discount || !!coupons?.point_gift_card;

    if (checkoutInfo?.isFreePayment) {
      return {
        state: CheckoutStateTypes.FREE_PAYMENT,
      };
    }

    if (order?.total_price === 0 && !isCouponApplied) {
      return {
        state: CheckoutStateTypes.FREE_PAYMENT,
      };
    }

    return {
      state: CheckoutStateTypes.CHECKOUT,
    };
  },
);
