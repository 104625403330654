type Meta = {
  title: string;
  description: string;
  imageUrl: string;
};

export const extractMeta = async (htmlString: string): Promise<Meta> => {
  if (typeof window !== 'undefined') {
    return {
      title: '',
      description: '',
      imageUrl: '',
    };
  }

  const { JSDOM } = await import('jsdom');
  const dom = new JSDOM(htmlString);
  const metaTags = dom.window.document.head.querySelectorAll('meta');

  let title = '';
  const fallbackTitle = dom.window.document.title || '';
  let description = '';
  let fallbackDescription = '';
  let imageUrl = '';

  metaTags.forEach(tag => {
    if (tag.getAttribute('property') === 'og:title') {
      title = tag.getAttribute('content') || '';
    }
    if (tag.getAttribute('property') === 'og:description') {
      description = tag.getAttribute('content') || '';
    }
    if (tag.getAttribute('name') === 'description') {
      fallbackDescription = tag.getAttribute('content') || '';
    }
    if (tag.getAttribute('property') === 'og:image') {
      imageUrl = tag.getAttribute('content') || '';
    }
  });

  if (!title) {
    title = fallbackTitle;
  }

  if (!description) {
    description = fallbackDescription;
  }

  return { title, description, imageUrl };
};
