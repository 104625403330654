import { AnyAction, combineReducers } from '@reduxjs/toolkit';

import { paymentRouteBookSlice, PaymentRouteBookState } from './paymentRouteBook/paymentRouteBookSlice';
import { paymentRouteEpisodeSlice, PaymentRouteEpisodeState } from './paymentRouteEpisode/paymentRouteEpisodeSlice';
import { paymentRouteMethodSlice, PaymentRouteMethodState } from './paymentRouteMethod/paymentRouteMethodSlice';

export interface PaymentRouteRootState {
  paymentRouteBook: PaymentRouteBookState;
  paymentRouteEpisode: PaymentRouteEpisodeState;
  paymentRouteMethod: PaymentRouteMethodState;
}

export const paymentRouteRootReducer = (
  state: PaymentRouteRootState | undefined,
  action: AnyAction,
): PaymentRouteRootState => {
  const combinedReducers = combineReducers({
    paymentRouteBook: paymentRouteBookSlice.reducer,
    paymentRouteEpisode: paymentRouteEpisodeSlice.reducer,
    paymentRouteMethod: paymentRouteMethodSlice.reducer,
  });

  return combinedReducers(state, action);
};

export type PaymentRouteRootReducerType = ReturnType<typeof paymentRouteRootReducer>;
