import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';

import type { AppDispatch, RootState } from '@/features/store';
import { debug } from '@/utils/debug';

import { setIsInTransitionAction } from '../actions';
import { currentCheckoutStateSelector, isInCheckoutTransitionSelector } from '../selectors';
import type { CheckoutStates, CheckoutStateTypes } from '../types';

const log = debug('createTransitionThunk');

type ThunkAPI = { dispatch: AppDispatch; getState: () => RootState };
export const createTransitionThunk = <Argument, PreviousStateType extends CheckoutStateTypes | null>(
  name: string,
  previousType: PreviousStateType,
  thunk: (
    previousState: CheckoutStates & { state: PreviousStateType },
    argument: Argument,
    thunkAPI: ThunkAPI,
  ) => CheckoutStates | null | Promise<CheckoutStates | null>,
): AsyncThunk<CheckoutStates | null, Argument, { state: RootState }> =>
  createAsyncThunk<CheckoutStates | null, Argument, { state: RootState; extra: undefined }>(
    `inapp/checkout/checkoutState/${name}Action`,
    async (argument, thunkAPI) => {
      const state = thunkAPI.getState();
      const currentCheckoutState = currentCheckoutStateSelector(state);
      if (isInCheckoutTransitionSelector(state)) {
        log('CheckoutState InvariantViolationException: Cannot make a transition in a transition!');
        return null;
      }

      thunkAPI.dispatch(setIsInTransitionAction(true));

      if (previousType !== null && currentCheckoutState.state !== previousType) {
        log(
          `CheckoutState InvariantViolationException: Cannot make a transition ${name} ` +
            `from an invalid state ${currentCheckoutState.state}, ${previousType as number} expected!`,
        );
        return currentCheckoutState;
      }

      return thunk(
        currentCheckoutState as CheckoutStates & { state: PreviousStateType },
        argument,
        thunkAPI as ThunkAPI,
      );
    },
  );
