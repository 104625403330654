import { createSlice } from '@reduxjs/toolkit';

import { hydrateAction } from '@/features/hydrate';
import type { RootState } from '@/features/store';

import { hydrateAuthVariablesAction } from '../actions';

export const isHydrateNeededSelector = (state: RootState): boolean => state.global.isHydrateNeeded;

// Static Page인 경우에는 초기에 로그인 정보 및 variable이 잘못되었으므로, 이를 표시해둔다
export const isHydrateNeededSlice = createSlice({
  name: 'global/isHydrateNeeded',
  initialState: false,
  reducers: {
    markAsHydrateNeededAction: () => true,
  },

  extraReducers(builder) {
    // 1차 Hydration (Static Page의 정보) 때에는 true를 유지
    // 2차 Hydration (auth-variables 의 정보) 때에는 false로 변경
    builder.addCase(hydrateAction, (_, action) => isHydrateNeededSelector(action.payload));
    builder.addCase(hydrateAuthVariablesAction.fulfilled, () => false);
  },
});

export const { markAsHydrateNeededAction } = isHydrateNeededSlice.actions;
