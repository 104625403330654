import { type FeatureFlag } from '@/utils/featureFlag';

import { GetService, isInternalServerToServerRequest, makeServiceMethod } from '../baseService';

const getNewService: GetService = ({ path = '/api/books-backend' } = {}) => {
  const isServerToServer = isInternalServerToServerRequest();
  return {
    host: isServerToServer ? process.env.SERVER_SIDE_BACKENDS : process.env.NEXT_PUBLIC_BOOKS_BACKEND_API,
    routePrefix: isServerToServer ? '/apps/books' : undefined,
    through: {
      host: undefined,
      routePrefix: path,
    },
  };
};

const getLegacyService: GetService = ({ path = '/api/books-backend' } = {}) => ({
  host: process.env.NEXT_PUBLIC_BOOKS_BACKEND_API,
  routePrefix: undefined,
  through: {
    host: undefined,
    routePrefix: path,
  },
});

const getService = (useInternalRequestFeatureFlag: boolean) =>
  useInternalRequestFeatureFlag ? getNewService : getLegacyService;

const createAPIMethod = (useInternalRequestFeatureFlag: boolean, path?: string) =>
  makeServiceMethod(() => getService(useInternalRequestFeatureFlag)({ path }));

export const makeCallAPIMethod = (useInternalRequestFeatureFlag: boolean) =>
  createAPIMethod(useInternalRequestFeatureFlag);

export const makeCallAPIMethodWithoutPrefix = (useInternalRequestFeatureFlag: boolean) =>
  createAPIMethod(useInternalRequestFeatureFlag, '');

export type BooksBackendAPIRequest = {
  featureFlag?: FeatureFlag;
  /**
   * 사용하지 않을 파라미터지만 ServiceMethods에서 타입 검사 시
   * ServerRequestParameters 로부터 extends 체크를 통해 T or void 판단을 하기 때문에
   * 사용하지 않을 을 undefined로 초기화함.
   */
  query?: undefined;
};
