import { UnreadCountModel } from '@/models/notificationApi/Notification/UnreadCountModel';
import { ServiceMethod } from '@/services/baseService';

import { makeCallAPIMethod } from '../notificationApiService';

const call = makeCallAPIMethod(UnreadCountModel);

export const unreadCount: ServiceMethod<void, UnreadCountModel, void> = async (_reqParams, req) =>
  call<void, void, UnreadCountModel>(
    {
      method: 'GET',
      pathname: '/notification/unread_count',
      preferNonProxied: true,
      timeout: 5000,
    },
    req,
  );
