import {
  Boolean as RBoolean,
  Literal as RLiteral,
  Null as RNull,
  Record as RRecord,
  Static as RStatic,
  String as RString,
  Union as RUnion,
} from 'runtypes';

export const ROperatingSystemType = RUnion(
  RLiteral('window'),
  RLiteral('android'),
  RLiteral('ios'),
  RLiteral('macos'),
  RLiteral('linux'),
  RLiteral('windowsphone'),
  RLiteral('unknown'),
);
export type OperatingSystemType = RStatic<typeof ROperatingSystemType>;
export const RAppType = RUnion(
  RLiteral('desktop'),
  RLiteral('paper'),
  RLiteral('mobile'),
  RLiteral('tablet'),
  RLiteral('mobile_onepun'),
  RLiteral('tablet_onepun'),
);
export const RAppVersion = RString.Or(RLiteral('unknown'));
export type AppVersion = RStatic<typeof RAppVersion>;

export const RAppTheme = RUnion(RLiteral('light'), RLiteral('dark'));
export type AppTheme = RStatic<typeof RAppTheme>;

export const RDeviceType = RUnion(
  RLiteral('desktop'),
  RLiteral('tablet'),
  RLiteral('mobile'),
  RLiteral('paper'),
  RLiteral('ipod'),
  RLiteral('unknown'),
);
export type DeviceType = RStatic<typeof RDeviceType>;

export const RVariables = RRecord({
  isDevelopment: RBoolean,
  operatingSystem: ROperatingSystemType,
  deviceType: RDeviceType,
  app: RRecord({
    name: RString.optional(),
    version: RAppVersion.optional(),
    type: RAppType.optional(),
    theme: RAppTheme.optional(),
    isOnePun: RBoolean.optional(),
  }).Or(RNull),
  requestId: RString,
});
export type Variables = RStatic<typeof RVariables>;
