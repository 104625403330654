import { ViewsModel } from '@/models/backendsApi/v2/Views/ViewsModel';
import { makeCallAPIMethod } from '@/services/backendsApi/backendsApiService';
import { ServiceMethod } from '@/services/baseService';
import { getFeatureFlagValueByKey } from '@/utils/featureFlag';
import { searchParamsToParsedQs } from '@/utils/query';

import { ViewsResourceRequest } from './interfaces/ViewsResourceRequest';

export const viewsResource: ServiceMethod<ViewsResourceRequest, ViewsModel, void> = async (reqParams, req) => {
  const { endpoint } = reqParams.body;
  const apiURL = new URL(endpoint);
  const call = makeCallAPIMethod(
    !!getFeatureFlagValueByKey({
      featureFlag: req?.FeatureFlag || reqParams?.featureFlag,
      featureFlagKey: 'web-internal-backendsapi-request-20240710',
    }),
  )(ViewsModel);
  return call(
    {
      method: 'GET',
      pathname: apiURL.pathname,
      params: { query: { ...searchParamsToParsedQs(apiURL.searchParams), platform: 'web' } },

      through: {
        method: 'POST',
        pathname: '/v2/views/resource',
        params: { body: { endpoint } },
      },
    },
    req,
  );
};
