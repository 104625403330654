import { createContext, PropsWithChildren, useMemo, useState } from 'react';

import { MarketingAgreement, MarketingAgreementContextInterface, User, UserContextInterface } from './types';

const UserContext = createContext<UserContextInterface>({
  user: null,
  setUser: () => {},
});
const MarketingAgreementContext = createContext<MarketingAgreementContextInterface>({
  agreement: null,
  setAgreement: () => {},
});

const UserInfoProvider = ({ children }: PropsWithChildren) => {
  const [user, setUser] = useState<User | null>(null);
  const [agreement, setAgreement] = useState<MarketingAgreement | null>(null);
  const userContextValue = useMemo(
    () => ({
      user,
      setUser,
    }),
    [user],
  );
  const marketingAgreementContext = useMemo(
    () => ({
      agreement,
      setAgreement,
    }),
    [agreement],
  );

  return (
    <UserContext.Provider value={userContextValue}>
      <MarketingAgreementContext.Provider value={marketingAgreementContext}>
        {children}
      </MarketingAgreementContext.Provider>
    </UserContext.Provider>
  );
};

export { MarketingAgreementContext, UserContext, UserInfoProvider };
