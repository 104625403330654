import { createAction, createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { AccountState } from '@/features/account';
import type { AppDispatch, RootState, State } from '@/features/store';
import { AccountValidation } from '@/models/backendsApi/v1/AccountValidation/AccountValidationType';
import { AccountVerificationSendEmailSuccess } from '@/models/backendsApi/v1/AccountVerificationSendEmail/AccountVerificationSendEmailType';
import { accountValidationEmail } from '@/services/backendsApi/v1/accountValidation/accountValidationEmailService';
import { accountVerificationSendEmail } from '@/services/backendsApi/v1/accountVerificationSendEmail/accountVerificationSendEmailService';
import { debug } from '@/utils/debug';

const log = debug('emailSend');

export interface EmailSendState {
  description: string;
  isSending: boolean;
  sendEmailSuccess: boolean;
  userIdx: number;
  email: string;
  emailValidationMessages: string[];
  isValidating: boolean;
  returnUrl: string;
}

interface SetValuePayload<T extends keyof EmailSendState> {
  key: T;
  value: EmailSendState[T];
}

export const SEND_EMAIL_SUCCESS_DESCRIPTION =
  '이메일로 전송받은 <strong>인증 링크</strong>를 확인해주세요.<br />인증 링크는 이메일 발송 시점으로부터 1시간동안 유효합니다.';
export const SEND_EMAIL_FAILURE_DESCRIPTION_DEFAULT = '일시적인 오류로 발송에 실패했습니다. 다시 시도해주세요.';
export const EMAIL_REQUIRED_MESSAGE = '이메일 주소를 입력해주세요.';

const emailSendStateSelector = createSelector(
  (state: State) => state.account,
  (state: AccountState) => state.emailSend,
);

const makeValueSelector = <T extends keyof EmailSendState>(key: T) =>
  createSelector(emailSendStateSelector, (state: EmailSendState): EmailSendState[T] => state[key]);

export const sendEmailSuccessSelector = makeValueSelector('sendEmailSuccess');
export const emailSelector = makeValueSelector('email');
export const descriptionSelector = makeValueSelector('description');
export const emailValidationMessagesSelector = makeValueSelector('emailValidationMessages');
export const isValidatingSelector = makeValueSelector('isValidating');
export const isEmailValidSelector = createSelector(
  emailSendStateSelector,
  (state: EmailSendState) => !state.emailValidationMessages.length,
);

export const setValueAction = createAction(
  'account/emailSend/setValueAction',
  <T extends keyof EmailSendState>(payload: SetValuePayload<T>) => ({
    payload,
  }),
);

/**
 * 인증 메일 전송 action creator
 */
export const sendVerificationEmailAction = createAsyncThunk<
  AccountVerificationSendEmailSuccess,
  {
    email: string;
    returnUrl?: string;
  },
  {
    state: RootState;
    dispatch: AppDispatch;
    rejectValue: {
      isAlreadyVerified: boolean;
      message: string;
    };
  }
>(
  'account/emailSend/sendVerificationEmailAction',
  async ({ email, returnUrl }, { dispatch, rejectWithValue }) => {
    dispatch(setValueAction({ key: 'email', value: email }));
    if (returnUrl) {
      dispatch(setValueAction({ key: 'returnUrl', value: returnUrl }));
    }

    const [error, model] = await accountVerificationSendEmail({
      body: {
        email,
        return_url: returnUrl,
      },
    });

    if (error) {
      return rejectWithValue({
        isAlreadyVerified: false,
        message: error.response?.data.message || SEND_EMAIL_FAILURE_DESCRIPTION_DEFAULT,
      });
    }

    if (!model.Data.data.valid) {
      return rejectWithValue({
        isAlreadyVerified: model.Data.data.is_already_verified,
        message: model.Data.data.messages.join(', '),
      });
    }

    return model.Data as AccountVerificationSendEmailSuccess;
  },
  {
    condition: (_, { getState }) => {
      const { isSending } = getState().account.emailSend;
      return !isSending;
    },
  },
);

/**
 * 인증 메일 재전송 action creator
 */
export const resendVerificationEmailAction = createAsyncThunk<
  void,
  void,
  {
    state: RootState;
    dispatch: AppDispatch;
    rejectValue:
      | {
          isAlreadyVerified: boolean;
          message: string;
        }
      | undefined;
  }
>('account/emailSend/resendVerificationEmailAction', async (_, { getState, dispatch, rejectWithValue }) => {
  const { email, returnUrl } = getState().account.emailSend;

  const result = await dispatch(
    sendVerificationEmailAction({
      email,
      returnUrl,
    }),
  );

  if (sendVerificationEmailAction.rejected.match(result)) {
    const resendEmailResult = result.payload;
    return rejectWithValue(resendEmailResult);
  }

  return undefined;
});

/**
 * 메일 수정 후 인증 메일 전송 action creator
 */
export const modifyVerificationEmailAction = createAsyncThunk<
  void,
  {
    email: string;
  },
  {
    state: RootState;
    dispatch: AppDispatch;
    rejectValue: void;
  }
>('account/emailSend/modifyVerificationEmailAction', async ({ email }, { getState, dispatch, rejectWithValue }) => {
  const { returnUrl } = getState().account.emailSend;

  const result = await dispatch(
    sendVerificationEmailAction({
      email,
      returnUrl,
    }),
  );

  if (sendVerificationEmailAction.rejected.match(result)) {
    return rejectWithValue();
  }

  return undefined;
});

export const validateEmailAction = createAsyncThunk<
  AccountValidation,
  string,
  {
    rejectValue: { message: string };
  }
>('account/emailSend/validateEmailAction', async (email, { rejectWithValue }) => {
  if (!email) {
    return {
      success: true,
      message: null,
      data: {
        valid: false,
        messages: [EMAIL_REQUIRED_MESSAGE],
      },
    };
  }

  const [error, model] = await accountValidationEmail({ body: { email } });

  // 네트워크 실패 등의 에러 핸들링. validation 실패가 아님
  if (error) {
    return rejectWithValue({
      message: error.response?.data.message || error.message,
    });
  }

  return model.Data;
});

const initialState: EmailSendState = {
  description: '',
  isSending: false,
  sendEmailSuccess: false,
  userIdx: 0,
  email: '',
  emailValidationMessages: [],
  isValidating: false,
  returnUrl: '',
};

export const emailSendSlice = createSlice({
  name: 'account/emailSend',
  initialState,
  reducers: {
    resetModifyForm(state) {
      state.emailValidationMessages = [];
      state.isValidating = false;
    },
    reset() {
      return initialState;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(
        setValueAction,
        <T extends keyof EmailSendState>(state: EmailSendState, action: PayloadAction<SetValuePayload<T>>) => {
          const { key, value } = action.payload;
          state[key] = value;
        },
      )
      .addCase(sendVerificationEmailAction.pending, state => {
        log('## sendVerificationEmailAction.pending', state);
        state.isSending = true;
      })
      .addCase(sendVerificationEmailAction.fulfilled, (state, action) => {
        log('## sendVerificationEmailAction.fulfilled', state, action);

        state.sendEmailSuccess = true;
        state.description = SEND_EMAIL_SUCCESS_DESCRIPTION;
        state.isSending = false;
      })
      .addCase(sendVerificationEmailAction.rejected, (state, action) => {
        log('## sendVerificationEmailAction.rejected', state, action);

        state.sendEmailSuccess = false;
        state.isSending = false;

        if (action.payload) {
          state.description = action.payload.message;
        } else {
          state.description = SEND_EMAIL_FAILURE_DESCRIPTION_DEFAULT;
        }
      })
      .addCase(validateEmailAction.pending, (state, action) => {
        log('## validateEmailAction.pending', state, action);

        state.isValidating = true;
      })
      .addCase(validateEmailAction.fulfilled, (state, action) => {
        log('## validateEmailAction.fulfilled', state, action);

        const { valid, messages } = action.payload.data;

        state.emailValidationMessages = valid ? [] : messages;
        state.isValidating = false;
      })
      .addCase(validateEmailAction.rejected, (state, action) => {
        log('## validateEmailAction.rejected', state, action);

        state.isValidating = false;
      });
  },
});

export type EmailSendReducerType = ReturnType<typeof emailSendSlice.reducer>;
