import {
  checkoutRefreshAction,
  checkoutRefreshFinishAction,
  checkoutRefreshStartAction,
} from '../../checkout/checkoutSlice';
import { CheckoutStateTypes } from '../types';
import { createTransitionThunk } from '../utils/createTransitionThunk';

export const chargeRidicashStartTransitionAction = createTransitionThunk(
  'chargeRidicashStartTransition',
  CheckoutStateTypes.CHECKOUT,
  (_previousState, _args, { dispatch }) => {
    dispatch(checkoutRefreshStartAction());

    return { state: CheckoutStateTypes.CHECKOUT_CHARGE_RIDICASH };
  },
);

export const chargeRidicashFinishTransitionAction = createTransitionThunk(
  'chargeRidicashFinishTransition',
  CheckoutStateTypes.CHECKOUT_CHARGE_RIDICASH,
  (_previousState, { needsRefresh }: { needsRefresh: boolean }, { dispatch }) => {
    if (needsRefresh) {
      dispatch(checkoutRefreshAction({}));
    } else {
      dispatch(checkoutRefreshFinishAction());
    }

    return { state: CheckoutStateTypes.CHECKOUT };
  },
);
