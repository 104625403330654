import { checkoutHasAdultItemsSelector, checkoutOrderSelector } from '../../checkout/checkoutSlice';
import { CheckoutStateTypes } from '../types';
import { createTransitionThunk } from '../utils/createTransitionThunk';

export const adultCheckTransitionAction = createTransitionThunk(
  'adultCheckTransition',
  CheckoutStateTypes.FETCH_CHECKOUT_WAITING,
  (_previousState, _args, { getState }) => {
    const state = getState();
    const hasAdultItems = checkoutHasAdultItemsSelector(state);
    const isAllAdultItems = !checkoutOrderSelector(state)?.items.length;

    return { state: CheckoutStateTypes.ADULT_CHECK, hasAdultItems, isAllAdultItems };
  },
);
