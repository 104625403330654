import {
  Array as RArray,
  Optional as ROptional,
  Record as RRecord,
  Static as RStatic,
  String as RString,
} from 'runtypes';

export const RPaymentRequest = RRecord({
  t_id: RString,
  b_ids: RArray(RString),
  tracking_info: ROptional(RString.nullable()),
});

export type PaymentRequest = RStatic<typeof RPaymentRequest>;

export const RPaymentRequestFailure = RRecord({
  code: RString,
  message: RString,
});

export type PaymentRequestFailure = RStatic<typeof RPaymentRequestFailure>;
