import { createAction, createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { AccountState } from '@/features/account';
import type { AppDispatch, RootState, State } from '@/features/store';
import { AccountSignupCreateAccountSuccess } from '@/models/backendsApi/v1/AccountSignupCreateAccount/AccountSignupCreateAccountType';
import { AccountValidation } from '@/models/backendsApi/v1/AccountValidation/AccountValidationType';
import { accountSignupCreateAccount } from '@/services/backendsApi/v1/accountSignupCreateAccount/accountSignupCreateAccountService';
import { accountValidationEmail } from '@/services/backendsApi/v1/accountValidation/accountValidationEmailService';
import { accountValidationId } from '@/services/backendsApi/v1/accountValidation/accountValidationIdService';
import { accountValidationName } from '@/services/backendsApi/v1/accountValidation/accountValidationNameService';
import { accountValidationPassword } from '@/services/backendsApi/v1/accountValidation/accountValidationPasswordService';
import { debug } from '@/utils/debug';
import { isValidEmail } from '@/utils/emailValidator';

const log = debug('signupFormSlice');
export interface SignupFormInput<T = string> {
  value: T;
  isValid: boolean;
  isFetching: boolean;
  isDirty: boolean;
  isRequired: boolean;
  validationMessages: string[];
}

export type GenderValue = '' | 'M' | 'F';

export interface TermsValue {
  // 약관 동의
  useConfirm: boolean;
  // 이벤트, 혜택 알림 수신
  marketingPushConfirm: boolean;
  // 성별, 생년 정보 제공
  provideGenderAndBirthConfirm: boolean;
  // 개인 정보 수집 및 이용
  consentConfirm: boolean;
}

export type SignupFormInputValue = string | GenderValue | TermsValue;

export interface SignupFormState {
  inputs: {
    userId: SignupFormInput;
    password: SignupFormInput;
    passwordConfirm: SignupFormInput;
    email: SignupFormInput;
    name: SignupFormInput;
    birthYear: SignupFormInput;
    gender: SignupFormInput<GenderValue>;
    terms: SignupFormInput<TermsValue>;
  };
  isSubmitting: boolean;
}

export const SIGNUP_ERROR_MESSAGES = {
  NETWORK_ERROR_MESSAGE: '네트워크 오류입니다. 인터넷 연결을 확인해주세요.',
  ID_REQUIRED_MESSAGE: '아이디를 입력해주세요.',
  PASSWORD_REQUIRED_MESSAGE: '비밀번호를 입력해주세요.',
  PASSWORD_CONFIRM_REQUIRED_MESSAGE: '비밀번호를 재입력해주세요.',
  PASSWORD_NOT_SAME_MESSAGE: '비밀번호가 일치하지 않습니다.',
  EMAIL_REQUIRED_MESSAGE: '이메일을 입력해주세요.',
  EMAIL_FORMAT_NOT_VALID_MESSAGE: '올바른 이메일 형식이 아닙니다.',
  NAME_REQUIRED_MESSAGE: '이름을 입력해주세요.',
  BIRTH_YEAR_INVALID_MESSAGE: '출생년도를 YYYY(예-1981) 형태로 올바르게 입력해 주세요.',
  PARENTAL_CONSENT_NEED_MESSAGE: '14세 미만은 법정대리인의 회원가입 동의가 필요합니다.',
  USE_CONFIRM_MESSAGE: '이용약관',
  CONSENT_CONFIRM_MESSAGE: '개인 정보 수집 및 이용',
  TERMS_REQUIRED_MESSAGE: '에 동의해주세요.',
  MAX_LENGTH_EXCEED_MESSAGE: '자 이내로 입력해주세요.',
};
export const MIN_BIRTH_YEAR = 1920;
export const MIN_AGE = 14;
export const PASSWORD_MAX_LENGTH = 256;
export const NAME_MAX_LENGTH = 256;
export const MAX_BIRTH_YEAR_LENGTH = 4;

const isValidBirthYear = (birthYear: string) => {
  if (!birthYear.length) {
    return true;
  }
  const parsedBirthYear = Number(birthYear);
  const thisYear = new Date().getFullYear();

  return Number.isInteger(parsedBirthYear) && parsedBirthYear >= MIN_BIRTH_YEAR && parsedBirthYear <= thisYear;
};

export const signupFormSelector = createSelector(
  (state: State) => state.account,
  (state: AccountState) => state.signupForm,
);

export const isSignupReadySelector = createSelector(signupFormSelector, (state: SignupFormState) => {
  const inputs = Object.values(state.inputs) as SignupFormInput[];
  return inputs.every(input => input.isValid);
});

export const isParentalConsentReadySelector = createSelector(signupFormSelector, (state: SignupFormState) => {
  const { birthYear, ...exceptBirthYear } = state.inputs;
  const inputs = Object.values(exceptBirthYear) as SignupFormInput[];
  return inputs.every(input => input.isValid) && isValidBirthYear(birthYear.value);
});

export const isValidatingSelector = createSelector(signupFormSelector, (state: SignupFormState) => {
  const inputs = Object.values(state.inputs) as SignupFormInput[];
  return inputs.some(input => input.isFetching);
});

export const isSubmittingSelector = createSelector(signupFormSelector, (state: SignupFormState) => state.isSubmitting);

export const requiredInputKeysSelector = createSelector(signupFormSelector, (state: SignupFormState) => {
  const keys = Object.keys(state.inputs) as (keyof SignupFormState['inputs'])[];
  return keys.filter(key => state.inputs[key].isRequired);
});

const createSignupFormInput = (): SignupFormInput => ({
  value: '',
  isValid: false,
  isFetching: false,
  isDirty: false,
  isRequired: true,
  validationMessages: [],
});

const initialState: SignupFormState = {
  inputs: {
    userId: { ...createSignupFormInput() },
    password: { ...createSignupFormInput() },
    passwordConfirm: { ...createSignupFormInput() },
    email: { ...createSignupFormInput() },
    name: { ...createSignupFormInput() },
    birthYear: { ...createSignupFormInput(), isValid: true, isRequired: false },
    gender: { ...createSignupFormInput(), value: '', isValid: true, isRequired: false },
    terms: {
      ...createSignupFormInput(),
      value: {
        useConfirm: false,
        marketingPushConfirm: false,
        provideGenderAndBirthConfirm: false,
        consentConfirm: false,
      },
      isRequired: false,
    },
  },
  isSubmitting: false,
};

export const validatePasswordConfirmAction = createAction<undefined>(
  'account/signupForm/validatePasswordConfirmAction',
);

export const validateUserIdAction = createAsyncThunk<
  AccountValidation,
  undefined,
  {
    state: RootState;
    rejectValue: { message: string };
  }
>(
  'account/signupForm/validateUserIdAction',
  async (_, { getState, rejectWithValue }) => {
    const userId = getState().account.signupForm.inputs.userId.value;

    if (!userId) {
      return {
        success: true,
        message: null,
        data: {
          valid: false,
          messages: [SIGNUP_ERROR_MESSAGES.ID_REQUIRED_MESSAGE],
        },
      };
    }

    const [error, model] = await accountValidationId({ body: { user_id: userId } });

    if (error) {
      return rejectWithValue({
        message: error.response?.data.message || SIGNUP_ERROR_MESSAGES.NETWORK_ERROR_MESSAGE,
      });
    }

    return model.Data;
  },
  {
    condition(_, { getState }) {
      const signupFormState = getState().account.signupForm;

      return !signupFormState.inputs.userId.isValid;
    },
  },
);

export const validatePasswordAction = createAsyncThunk<
  AccountValidation,
  { force: boolean } | undefined,
  {
    state: RootState;
    rejectValue: { message: string };
  }
>(
  'account/signupForm/validatePasswordAction',
  async (_, { getState, rejectWithValue }) => {
    const signupFormInputs = getState().account.signupForm.inputs;
    const userId = signupFormInputs.userId.value;
    const password = signupFormInputs.password.value;

    if (!password) {
      return {
        success: true,
        message: null,
        data: {
          valid: false,
          messages: [SIGNUP_ERROR_MESSAGES.PASSWORD_REQUIRED_MESSAGE],
        },
      };
    }

    if (password.length > PASSWORD_MAX_LENGTH) {
      return {
        success: true,
        message: null,
        data: {
          valid: false,
          messages: [`${PASSWORD_MAX_LENGTH}${SIGNUP_ERROR_MESSAGES.MAX_LENGTH_EXCEED_MESSAGE}`],
        },
      };
    }

    const [error, model] = await accountValidationPassword({ body: { user_id: userId, password } });

    if (error) {
      return rejectWithValue({
        message: error.response?.data.message || SIGNUP_ERROR_MESSAGES.NETWORK_ERROR_MESSAGE,
      });
    }

    return model.Data;
  },
  {
    // eslint-disable-next-line @typescript-eslint/default-param-last
    condition({ force } = { force: false }, { getState }) {
      const signupFormState = getState().account.signupForm;

      return force || !signupFormState.inputs.password.isValid;
    },
  },
);

export const validateEmailAction = createAsyncThunk<
  AccountValidation,
  undefined,
  { state: RootState; rejectValue: { message: string } }
>(
  'account/signupForm/validateEmailAction',
  async (_, { getState, rejectWithValue }) => {
    const email = getState().account.signupForm.inputs.email.value;

    if (!email) {
      return {
        success: true,
        message: null,
        data: {
          valid: false,
          messages: [SIGNUP_ERROR_MESSAGES.EMAIL_REQUIRED_MESSAGE],
        },
      };
    }

    if (!isValidEmail(email)) {
      return {
        success: true,
        message: null,
        data: {
          valid: false,
          messages: [SIGNUP_ERROR_MESSAGES.EMAIL_FORMAT_NOT_VALID_MESSAGE],
        },
      };
    }

    const [error, model] = await accountValidationEmail({ body: { email } });

    if (error) {
      return rejectWithValue({
        message: error.response?.data.message || SIGNUP_ERROR_MESSAGES.NETWORK_ERROR_MESSAGE,
      });
    }

    return model.Data;
  },
  {
    condition(_, { getState }) {
      const signupFormState = getState().account.signupForm;

      return !signupFormState.inputs.email.isValid;
    },
  },
);

export const validateNameAction = createAsyncThunk<
  AccountValidation,
  undefined,
  { state: RootState; rejectValue: { message: string } }
>(
  'account/signupForm/validateNameAction',
  async (_, { getState, rejectWithValue }) => {
    const name = getState().account.signupForm.inputs.name.value;

    if (!name) {
      return {
        success: true,
        message: null,
        data: {
          valid: false,
          messages: [SIGNUP_ERROR_MESSAGES.NAME_REQUIRED_MESSAGE],
        },
      };
    }

    if (name.length > NAME_MAX_LENGTH) {
      return {
        success: true,
        message: null,
        data: {
          valid: false,
          messages: [`${NAME_MAX_LENGTH}${SIGNUP_ERROR_MESSAGES.MAX_LENGTH_EXCEED_MESSAGE}`],
        },
      };
    }

    const [error, model] = await accountValidationName({ body: { name } });

    if (error) {
      return rejectWithValue({
        message: error.response?.data.message || SIGNUP_ERROR_MESSAGES.NETWORK_ERROR_MESSAGE,
      });
    }

    return model.Data;
  },
  {
    condition(_, { getState }) {
      const signupFormState = getState().account.signupForm;

      return !signupFormState.inputs.name.isValid;
    },
  },
);

export const validateAllAction = createAsyncThunk<void, undefined, { state: RootState; dispatch: AppDispatch }>(
  'account/signupForm/validateAllAction',
  (_, { getState, dispatch }) => {
    const { userId, password, passwordConfirm, email, name } = getState().account.signupForm.inputs;

    if (!userId.isValid) {
      dispatch(validateUserIdAction());
    }
    if (!password.isValid) {
      dispatch(validatePasswordAction());
    }
    if (!passwordConfirm.isValid) {
      dispatch(validatePasswordConfirmAction());
    }
    if (!email.isValid) {
      dispatch(validateEmailAction());
    }
    if (!name.isValid) {
      dispatch(validateNameAction());
    }
  },
);

export const createUserAction = createAsyncThunk<
  AccountSignupCreateAccountSuccess,
  { form: SignupFormState; encodeData?: string },
  {
    state: RootState;
    rejectValue: { message: string };
  }
>('account/signupForm/createUserAction', async ({ form, encodeData }, { rejectWithValue }) => {
  const { userId, password, passwordConfirm, email, name, birthYear, gender, terms } = form.inputs;
  const [error, model] = await accountSignupCreateAccount({
    body: {
      user_id: userId.value,
      password: password.value,
      password_reenter: passwordConfirm.value,
      email: email.value,
      name: name.value,
      birth_year: terms.value.provideGenderAndBirthConfirm ? birthYear.value : '',
      gender: terms.value.provideGenderAndBirthConfirm ? gender.value : '',
      use_confirm: terms.value.useConfirm,
      consent_confirm: terms.value.consentConfirm,
      provide_gender_and_birth_confirm: terms.value.provideGenderAndBirthConfirm,
      marketing_agreement_push_confirm: terms.value.marketingPushConfirm,
      encode_data: encodeData,
    },
  });

  if (error) {
    return rejectWithValue({
      message: error.response?.data.message || SIGNUP_ERROR_MESSAGES.NETWORK_ERROR_MESSAGE,
    });
  }

  const signupResponse = model.Data;

  if (signupResponse.data.valid === false) {
    return rejectWithValue({
      message: signupResponse.data.messages.join(', '),
    });
  }

  return signupResponse as AccountSignupCreateAccountSuccess;
});

const makeValidateInputPendingReducer = (key: keyof SignupFormState['inputs']) => (state: SignupFormState) => {
  log(`## validate${key}Action.pending`, state);
  state.inputs[key].isFetching = true;
};

const makeValidateInputFulfilledReducer =
  (key: keyof SignupFormState['inputs']) => (state: SignupFormState, action: PayloadAction<AccountValidation>) => {
    log(`## validate${key}Action.fulfilled`, state, action);
    const { valid, messages } = action.payload.data;

    if (valid) {
      state.inputs[key].validationMessages = [];
    } else {
      state.inputs[key].validationMessages = messages;
    }

    state.inputs[key].isDirty = true;
    state.inputs[key].isValid = valid;
    state.inputs[key].isFetching = false;
  };

const makeValidateInputRejectedReducer =
  (key: keyof SignupFormState['inputs']) =>
  (state: SignupFormState, action: PayloadAction<{ message: string } | undefined>) => {
    log(`## validate${key}Action.rejected`, state, action);

    const message = action.payload?.message || '';

    state.inputs[key].validationMessages = [message];
    state.inputs[key].isDirty = true;
    state.inputs[key].isValid = false;
    state.inputs[key].isFetching = false;
  };

export const signupFormSlice = createSlice({
  name: 'account/signupForm',
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
    setInputValueAction(
      state,
      action: PayloadAction<{ key: keyof SignupFormState['inputs']; value: SignupFormInput['value'] }>,
    ) {
      const { key, value } = action.payload;

      if (key !== 'gender') {
        state.inputs[key].isValid = false;
      }

      if (key === 'birthYear' && value.length > MAX_BIRTH_YEAR_LENGTH) {
        return;
      }

      state.inputs[key].value = value;
    },
    setTermsValueAction(state, action: PayloadAction<{ key: keyof TermsValue; value: boolean }>) {
      const { key, value } = action.payload;
      state.inputs.terms.value[key] = value;
      state.inputs.terms.isValid = false;
      state.inputs.terms.validationMessages = [];
    },
    initializeOptionalInputAction(state, action: PayloadAction<{ key: keyof SignupFormState['inputs'] }>) {
      const { key } = action.payload;
      state.inputs[key].isValid = true;
      state.inputs[key].isDirty = false;
      state.inputs[key].validationMessages = [];
    },
    validateBirthYearAction(state) {
      const { birthYear } = state.inputs;

      if (birthYear.isValid || !birthYear.value) {
        return;
      }

      birthYear.isDirty = true;

      const parsedBirthYear = Number(birthYear.value);
      const thisYear = new Date().getFullYear();
      const age = thisYear - parsedBirthYear;

      if (!Number.isInteger(parsedBirthYear) || parsedBirthYear < MIN_BIRTH_YEAR || parsedBirthYear > thisYear) {
        birthYear.isValid = false;
        birthYear.validationMessages = [SIGNUP_ERROR_MESSAGES.BIRTH_YEAR_INVALID_MESSAGE];
      } else if (age < MIN_AGE) {
        birthYear.isValid = false;
        birthYear.validationMessages = [SIGNUP_ERROR_MESSAGES.PARENTAL_CONSENT_NEED_MESSAGE];
      } else {
        birthYear.isValid = true;
        birthYear.validationMessages = [];
      }
    },
    validateTermsAction(state) {
      const { useConfirm, consentConfirm } = state.inputs.terms.value;

      const messages = [
        !useConfirm && SIGNUP_ERROR_MESSAGES.USE_CONFIRM_MESSAGE,
        !consentConfirm && SIGNUP_ERROR_MESSAGES.CONSENT_CONFIRM_MESSAGE,
      ].filter(str => str);

      if (messages.length) {
        state.inputs.terms.validationMessages = [
          messages.join(', ').concat(SIGNUP_ERROR_MESSAGES.TERMS_REQUIRED_MESSAGE),
        ];
      } else {
        state.inputs.terms.isValid = true;
        state.inputs.terms.validationMessages = [];
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(validateUserIdAction.pending, makeValidateInputPendingReducer('userId'))
      .addCase(validateUserIdAction.fulfilled, makeValidateInputFulfilledReducer('userId'))
      .addCase(validateUserIdAction.rejected, makeValidateInputRejectedReducer('userId'))
      .addCase(validatePasswordAction.pending, makeValidateInputPendingReducer('password'))
      .addCase(validatePasswordAction.fulfilled, makeValidateInputFulfilledReducer('password'))
      .addCase(validatePasswordAction.rejected, makeValidateInputRejectedReducer('password'))
      .addCase(validateEmailAction.pending, makeValidateInputPendingReducer('email'))
      .addCase(validateEmailAction.fulfilled, makeValidateInputFulfilledReducer('email'))
      .addCase(validateEmailAction.rejected, makeValidateInputRejectedReducer('email'))
      .addCase(validateNameAction.pending, makeValidateInputPendingReducer('name'))
      .addCase(validateNameAction.fulfilled, makeValidateInputFulfilledReducer('name'))
      .addCase(validateNameAction.rejected, makeValidateInputRejectedReducer('name'))
      .addCase(validatePasswordConfirmAction, state => {
        const { password, passwordConfirm } = state.inputs;

        password.isDirty = true;
        passwordConfirm.isDirty = true;

        if (password.validationMessages.length) {
          passwordConfirm.isValid = false;
          return;
        }

        if (!password.value) {
          passwordConfirm.isValid = false;
          password.validationMessages = [SIGNUP_ERROR_MESSAGES.PASSWORD_REQUIRED_MESSAGE];
        } else if (!passwordConfirm.value) {
          passwordConfirm.isValid = false;
          passwordConfirm.validationMessages = [SIGNUP_ERROR_MESSAGES.PASSWORD_CONFIRM_REQUIRED_MESSAGE];
        } else if (passwordConfirm.value !== password.value) {
          passwordConfirm.isValid = false;
          passwordConfirm.validationMessages = [SIGNUP_ERROR_MESSAGES.PASSWORD_NOT_SAME_MESSAGE];
        } else {
          passwordConfirm.isValid = true;
          passwordConfirm.validationMessages = [];
        }
      })
      .addCase(createUserAction.pending, state => {
        log('## createUserAction.pending', state);
        state.isSubmitting = true;
      })
      .addCase(createUserAction.fulfilled, (state, action) => {
        log('## createUserAction.fulfilled', state, action);
        state.isSubmitting = false;
      })
      .addCase(createUserAction.rejected, state => {
        log('## createUserAction.rejected', state);
        state.isSubmitting = false;
      });
  },
});

export type SignupFormReducerType = ReturnType<typeof signupFormSlice.reducer>;
export const {
  reset,
  setInputValueAction,
  setTermsValueAction,
  initializeOptionalInputAction,
  validateBirthYearAction,
  validateTermsAction,
} = signupFormSlice.actions;
