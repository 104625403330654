import { BaseModel } from '@/models/BaseModel';

import {
  PaymentRouteGetFreePassCouponAvailableBooks,
  RPaymentRouteGetFreePassCouponAvailableBooks,
} from './PaymentRouteGetFreePassCouponAvailableBooksType';

export class PaymentRouteGetFreePassCouponAvailableBooksModel extends BaseModel<PaymentRouteGetFreePassCouponAvailableBooks> {
  constructor(data: PaymentRouteGetFreePassCouponAvailableBooks) {
    super(data, RPaymentRouteGetFreePassCouponAvailableBooks);
  }
}
