import { captureException, withScope } from '@sentry/nextjs';
import router from 'next/router';

import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary';

export class GlobalErrorBoundary extends ErrorBoundary {
  componentDidCatch(error: Error): void {
    if (error instanceof Error) {
      withScope(scope => {
        scope.setTag('path', router.asPath ?? 'undefined');
        scope.setExtra('NEXT_JS_ERROR', String(error));
        scope.setExtra('query', router.query);

        // 에러 트레이싱 시, Sentry에서 자체적으로 수집하는 에러와의 구분을 위해 추가
        scope.setTag('type', 'GlobalErrorBoundary');

        captureException(error);
        throw error;
      });
    }
  }
}
