import { css, SerializedStyles } from '@emotion/react';
import { lineClamp } from '@ridi-web/design-system/Styles/cjs';

import { BreakPoint, orBelow, touchDevice } from './media';

export enum ZIndexLayer {
  LEVEL_TOASTMESSAGE = 10000,
  LEVEL_MODAL = 9000,
  LEVEL_OVERLAY = 8000,
  LEVEL_HEADER = 7000,
  LEVEL_TOOLTIP = 6000,
  LEVEL_DROPDOWN = 5000,
  LEVEL_BASE = 0,
  LEVEL_BELOW = -1,
}

export const flexCenter = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const flexRowStart = css`
  display: flex;
  flex-direction: row;
  justify-content: start;
`;
export const flexColumnStart = css`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
`;

export { lineClamp };
export const lineClampWithPreLine = (limit: number, lineHeight: string): SerializedStyles => css`
  ${lineClamp(limit, lineHeight)};
  white-space: pre-wrap;
`;

export const scrollBarHidden = css`
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 0;
    height: 0;
    display: none !important; // 윈도우 크롬 등
  }
  & {
    overflow: -moz-scrollbars-none;
  }
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* Firefox 64 */
`;

export const displayNoneForTouchDevice = touchDevice(css`
  display: none;
`);

export const defaultHoverStyle = (hoverStyle?: SerializedStyles, hoverNoneStyle?: SerializedStyles) => css`
  :hover {
    ${hoverStyle || 'background: rgba(0, 0, 0, 0.05)'};
  }
  @media (hover: none) {
    :hover {
      ${hoverNoneStyle || 'background: inherit'};
    }
  }
`;

export const underlineHoverStyle = css`
  &:hover {
    text-decoration: underline;
  }

  @media (hover: none) {
    &:hover {
      text-decoration: none;
    }
  }
`;

export const pageContainerStyle = css`
  max-width: 1170px;
  padding-left: 30px;
  padding-right: 30px;
  margin: 0 auto;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      padding-left: 16px;
      padding-right: 16px;
    `,
  )};
`;

export const pageFooterStyle = css`
  padding-top: 100px;
  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      padding-top: 80px;
    `,
  )};
`;

export * as fonts from './fonts';
export * as media from './media';
export * as reset from './reset';
export * as themes from './themes';
