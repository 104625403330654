import { css, SerializedStyles } from '@emotion/react';

export { between, greaterThan, orBelow, touchDevice } from '@ridi-web/design-system/Styles/cjs';

/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
export enum BreakPoint {
  ExtraSmall = 299,
  Small = 374,
  Mobile = 432,
  Medium = 833,
  Large = 999,
  ExtraLarge = 1279,

  MobileSmall = 319,
  MobileDefault = Small,
  DesktopSmall = 767,
  DesktopMedium = 1169,
  DesktopDefault = ExtraLarge,
  DesktopLarge = 1439,

  GridLarge = DesktopMedium,
  GridMedium = 999,
  GridSmall = 599,
}
/* eslint-enable @typescript-eslint/no-duplicate-enum-values */

export const nonTouchDevice = (style: SerializedStyles): SerializedStyles => css`
  @media (hover: hover), (pointer: fine) {
    ${style};
  }
`;

/**
 * books-backend 과정에서 작품홈은 적응형이기 때문에 isMobile 여부에 따라 Desktop 과
 * Mobile 사이에서 @emotion/cache 의 stylis plugin 을 이용해 스타일을 강제되도록
 * 하는 임시 반응형 미디어 쿼리
 * AdaptiveEmotionCacheProvider로 isMobile를 전달해야 해당 미디어 쿼리가 적용됨
 */
export const orBelowWithForceMedia = (width: number, style: SerializedStyles): SerializedStyles => css`
  @media (force-max-width: ${width}px) {
    ${style};
  }
`;
