import debugModule from 'debug';

import { NAMESPACE } from '@/base/constants';

import { getStorageItemJSON, LOCAL_STORAGE_KEYS } from '../storage';

const SHOULD_USE_DEBUG_VIEWER =
  typeof window !== 'undefined' && getStorageItemJSON('local', LOCAL_STORAGE_KEYS.DEBUG_VIEWER_ENABLED);

let debugViewerElement: HTMLDivElement | null = null;
if (SHOULD_USE_DEBUG_VIEWER) {
  debugViewerElement = document.createElement('div');
  Object.assign(debugViewerElement.style, {
    position: 'fixed',
    bottom: '0',
    right: '0',
    width: '100vw',
    maxWidth: '500px',
    zIndex: '20000',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    color: 'white',
    pointerEvents: 'none',
  });

  document.body.appendChild(debugViewerElement);
}

export const debug = (...args: string[]): debugModule.Debugger => {
  const webDebugger = debugModule([NAMESPACE, ...args].join('/'));
  if (debugViewerElement) {
    const elem = debugViewerElement;

    webDebugger.log = (...debugContent: Any[]) => {
      const logItem = document.createElement('pre');
      Object.assign(logItem.style, {
        background: 'rgba(0, 0, 0, .7)',
        whiteSpace: 'pre-wrap',
        overflowWrap: 'break-word',
      });

      while (debugContent.length > 0) {
        let contentItem = document.createElement('code');
        const content: unknown = debugContent.shift();

        if (typeof content !== 'string') {
          contentItem.textContent = JSON.stringify(content);
          logItem.appendChild(contentItem);
        } else {
          const styleItems = content.split('%c');
          [contentItem.textContent] = styleItems;
          logItem.appendChild(contentItem);

          for (let styleIndex = 0; styleIndex < styleItems.length - 1; styleIndex += 1) {
            if (typeof debugContent[0] !== 'string') {
              debugContent.unshift(...styleItems.slice(styleIndex));
              break;
            }

            contentItem = document.createElement('code');
            contentItem.setAttribute('style', debugContent.shift() as string);
            contentItem.textContent = styleItems[styleIndex + 1];
            logItem.appendChild(contentItem);
          }
        }
      }

      while (elem.childNodes.length > 150) {
        elem.removeChild(elem.childNodes.item(0));
      }

      elem.appendChild(logItem);
    };
  }

  return webDebugger;
};
