import { createSlice } from '@reduxjs/toolkit';

import { hydrateAction } from '@/features/hydrate';
import { debug } from '@/utils/debug';

import {
  addPurchaseFinishedBooksAction,
  setCheckoutInfoAction,
  setIsInTransitionAction,
  setRenderStateAction,
} from './actions';
import { actionTypes, thunkTypes } from './transitions';
import {
  CheckoutInfo,
  CheckoutPurchaseFinishedBook,
  CheckoutRenderStates,
  CheckoutRenderStateTypes,
  CheckoutStates,
  CheckoutStateTypes,
} from './types';

export interface CheckoutStateState {
  currentState: CheckoutStates;
  renderState: CheckoutRenderStates;
  purchaseFinishedBooks: CheckoutPurchaseFinishedBook[];
  info?: CheckoutInfo | null;
  isInTransition: boolean;
}

const log = debug('checkoutStateSlice');

const initialState: CheckoutStateState = {
  currentState: {
    state: CheckoutStateTypes.INITIAL,
  },
  renderState: { state: CheckoutRenderStateTypes.CLOSED },
  purchaseFinishedBooks: [],
  info: null,
  isInTransition: false,
};

const handleTransitionFulfill = (state: CheckoutStateState, action: { payload: CheckoutStates | null }) => {
  log(`currentState: ${state.currentState.state}, nextState: ${action.payload?.state ?? '(not changed)'}`);

  if (action.payload) {
    state.currentState = action.payload;
    state.isInTransition = false;
  }
};

const handleTransitionReject = (state: CheckoutStateState) => {
  state.currentState = { state: CheckoutStateTypes.FAILURE, reason: null };
};

export const checkoutStateSlice = createSlice({
  name: 'inapp/checkout/checkoutState',
  initialState,
  reducers: {},
  extraReducers: builder => {
    actionTypes.forEach(actionType => {
      builder.addCase(actionType, (state, action) => {
        if (state.isInTransition) {
          return;
        }

        handleTransitionFulfill(state, action);
      });
    });

    thunkTypes.forEach(thunkType => {
      builder.addCase(thunkType.fulfilled, handleTransitionFulfill);
      builder.addCase(thunkType.rejected, handleTransitionReject);
    });

    builder.addCase(setRenderStateAction, (state, action) => {
      state.renderState = action.payload.newRenderState;
    });

    builder.addCase(setCheckoutInfoAction, (state, action) => {
      state.info = action.payload.newCheckoutInfo;
    });

    builder.addCase(addPurchaseFinishedBooksAction, (state, action) => {
      state.purchaseFinishedBooks = [...state.purchaseFinishedBooks, ...action.payload.books];
    });

    builder.addCase(setIsInTransitionAction, (state, action) => {
      state.isInTransition = action.payload.isInTransition;
    });

    builder.addCase(hydrateAction, (state, action) => {
      const { checkoutState } = action.payload.inapp.checkout;
      return { ...state, ...checkoutState };
    });
  },
});

export * from './actions';
export * from './selectors';
