import {
  Array as RArray,
  Boolean as RBoolean,
  Number as RNumber,
  Record as RRecord,
  Static as RStatic,
  String as RString,
} from 'runtypes';

import { RBackendsBook, RBackendsSerial } from '../../BackendsBookType';

export const RNewReleasesSerial = RBackendsSerial.pick(
  'serial_id',
  'title',
  'cover',
  'total',
  'episode',
  'completion',
  'wait_for_free',
  'free',
  'header_image',
  'purchase',
  'rental',
);
export type NewReleasesSerial = RStatic<typeof RNewReleasesSerial>;

export const RNewReleasesBook = RBackendsBook.pick(
  'book_id',
  'title',
  'cover',
  'authors',
  'adults_only',
  'open',
  'publication_date',
  'publisher',
  'purchase',
  'rental',
  'categories',
  'ratings',
).extend({
  description: RString,
  file_size: RNumber,
  serial: RNewReleasesSerial.nullable(),
});
export type NewReleasesBook = RStatic<typeof RNewReleasesBook>;

export const RNewReleasesResponse = RRecord({
  success: RBoolean,
  message: RString.nullable(),
  data: RRecord({
    new_releases: RArray(RNewReleasesBook),
    pagination: RRecord({
      next_page: RString.nullable(),
      prev_page: RString.nullable(),
    }),
  }),
});
export type NewReleasesResponse = RStatic<typeof RNewReleasesResponse>;
