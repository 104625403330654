import {
  Literal as RLiteral,
  Number as RNumber,
  Optional as ROptional,
  Record as RRecord,
  Static as RStatic,
  String as RString,
  Union as RUnion,
} from 'runtypes';

export const RAuthorRole = RUnion(
  RLiteral('author'),
  RLiteral('story_writer'),
  RLiteral('translator'),
  RLiteral('illustrator'),
  RLiteral('original_author'),
  RLiteral('author_photo'),
  RLiteral('planner'),
  RLiteral('bibliographical_introduction'),
  RLiteral('compiler'),
  RLiteral('commentator'),
  RLiteral('editor'),
  RLiteral('supervise'),
  RLiteral('performer'),
  RLiteral('original_illustrator'),
  RLiteral('comic_author'),
);

export type AuthorRole = RStatic<typeof RAuthorRole>;

export const RAuthor = RRecord({
  id: ROptional(RNumber),
  role: RAuthorRole,
  name: ROptional(RString),
});

export type Author = RStatic<typeof RAuthor>;
