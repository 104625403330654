import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { ActionRequest, RootState } from '@/features/store';
import {
  Oauth2Token,
  Oauth2TokenAction,
  Oauth2TokenActionNeedToChangePassword,
  Oauth2TokenActionNeedToVerifyEmail,
} from '@/models/account/Oauth2Token/Oauth2TokenType';
import { oauth2Token, Oauth2TokenError } from '@/services/account/oauth2Token/oauth2TokenService';

import type { AccountState } from '..';

export const isNeedToChangePassword = (action: Oauth2TokenAction): action is Oauth2TokenActionNeedToChangePassword =>
  !!(action as Oauth2TokenActionNeedToChangePassword)?.need_to_change_password;

export const isNeedToVerifyEmail = (action: Oauth2TokenAction): action is Oauth2TokenActionNeedToVerifyEmail =>
  !!(action as Oauth2TokenActionNeedToVerifyEmail)?.need_to_verify_email;

export const loginSelector = createSelector(
  (state: RootState) => state.account,
  (state: AccountState) => state.login,
);

export interface LoginState {
  username: string;
  password: string;
  checked: boolean;
  isLoading: boolean;
  message: string;
}

const initialState: LoginState = {
  username: '',
  password: '',
  checked: true,
  isLoading: false,
  message: '',
};

export const loginAction = createAsyncThunk<
  Oauth2Token,
  ActionRequest<{ body: { username: string; password: string; auto_login?: boolean } }>,
  {
    rejectValue: Oauth2TokenError;
    state: RootState;
  }
>('account/loginAction', async ({ reqParams }, thunkAPI) => {
  const [error, model] = await oauth2Token({
    body: {
      username: reqParams.body.username,
      password: reqParams.body.password,
      grant_type: 'password' as const,
      client_id: process.env.NEXT_PUBLIC_RIDI_OAUTH2_CLIENT_ID,
      auto_login: reqParams.body.auto_login ?? false,
    },
  });

  if (error) {
    return thunkAPI.rejectWithValue({
      error: error.response?.data.error ?? error.name,
      message: error.response?.data.message ?? error.message,
      actions: error.response?.data.actions ?? [],
    });
  }

  return model.Data;
});

export const loginSlice = createSlice({
  name: 'account/login',
  initialState,
  reducers: {
    onChangeUsername: (state, action: PayloadAction<string>) => {
      state.username = action.payload;
    },
    onChangePassword: (state, action: PayloadAction<string>) => {
      state.password = action.payload;
    },
    onClickCheckBox: state => {
      state.checked = !state.checked;
    },
    setMessage: (state, action: PayloadAction<string>) => {
      state.message = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(loginAction.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(loginAction.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(loginAction.rejected, state => {
      state.isLoading = false;
    });
  },
});
