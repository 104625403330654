import { GroupSectionsItemsResponse } from '@/models/backendsApi/v2/GroupSections/Items/GroupSectionsItemsType';
import { SectionItems } from '@/models/backendsApi/v2/SectionItems/SectionItemsType';
import {
  AiRecommendations,
  BestSellers,
  SectionItemsResource,
} from '@/models/backendsApi/v2/SectionItemsResource/SectionItemsResourceType';
import {
  AiRecommendationSection,
  BestSellerSection,
  EventHorizontalSection,
  EventVerticalSection,
  GroupSection,
  ImageGradientBannerSection,
  KeywordSection,
  NewReleaseSection,
  OnePickSection,
  QuickMenuSection,
  ReadingBookSection,
  Section,
  SectionBase,
  SectionItemBase,
  SectionItemContentsBookBase,
  SectionLayoutType,
  SelectionCarouselItem,
  SelectionCarouselSection,
  SelectionCoverSection,
  SelectionHookingSentenceSection,
  SelectionMultilineSection,
  SelectionOriginalSection,
  SelectionSection,
  TopCarouselBannerSection,
  TopCarouselBookSection,
  TopCarouselCoverSection,
} from '@/models/backendsApi/v2/Views/ViewsType';
import { backendsBookDataUtils } from '@/utils/bookData';
import { groupSections } from '@/utils/bookData/backendsSlotData';
import { BookRenderData } from '@/utils/bookData/types';

export type ViewSectionItemsDefaultLoaderSpec = { resourceUrl: string; needLogin: boolean };
export type ViewSectionItems<I> = {
  items: I | null;
  defaultLoaderSpec: ViewSectionItemsDefaultLoaderSpec | null;
  hasItemsToFetch: boolean;
};

export type ViewSectionFrameBase = Pick<SectionBase, 'id' | 'title' | 'layout' | 'type' | 'more_details'>;
export type ViewSection<I = unknown, C = unknown, F = Record<string, unknown>> = {
  frame: ViewSectionFrameBase & F;
  contents: C;
  items: ViewSectionItems<I>;
};

export type SectionResource = SectionItemsResource | GroupSavedSectionResource;
export type ReduceSectionResourcePayload = Record<string, unknown> & GroupPayload;

/*
 * Default Section
 */
export type DefaultViewSection<T extends SectionBase> = ViewSection<
  T['items'],
  T['contents'],
  Omit<T, 'contents' | 'items'>
>;

export type MapDefaultSection<T extends SectionBase> = (section: T) => DefaultViewSection<T>;
export const mapDefaultSection = <T extends SectionBase>(section: T): DefaultViewSection<T> => {
  const { contents, items, ...frame } = section;
  if (items?.length) {
    return { frame, contents, items: { items, defaultLoaderSpec: null, hasItemsToFetch: false } };
  }

  const loaderSpec = section.items_async?.resource_url
    ? { resourceUrl: section.items_async.resource_url, needLogin: section.items_async.need_login }
    : null;

  return { frame, contents, items: { items: [], defaultLoaderSpec: loaderSpec, hasItemsToFetch: !!loaderSpec } };
};

export type ReduceDefaultSectionResource<T extends SectionBase> = (
  section: DefaultViewSection<T>,
  sectionResource: SectionResource,
) => DefaultViewSection<T>;

export const reduceDefaultSectionResource = <T extends SectionBase>(
  section: DefaultViewSection<T>,
  sectionResource: SectionResource,
): Omit<DefaultViewSection<T>, 'frame'> => {
  const { data } = sectionResource as SectionItems;
  return { ...section, items: { ...section.items, items: data.section_items } };
};

/*
 * Book Section
 */
export type BookSectionBase = Omit<SectionBase, 'items'> & {
  items: (Omit<SectionItemBase, 'contents'> & { contents: SectionItemContentsBookBase })[];
};

export type BookViewItem<T extends SectionItemBase> = Omit<T, 'contents'> & {
  contents: Omit<T['contents'], keyof SectionItemContentsBookBase> & { book: BookRenderData };
};

export type BookViewSection<T extends BookSectionBase> = ViewSection<
  BookViewItem<T['items'][number]>[],
  T['contents'],
  Omit<T, 'contents' | 'items'>
>;

export const mapBookItem = <T extends BookSectionBase>(item: T['items'][number]): BookViewItem<T['items'][number]> => {
  const { book, badges, ...itemContents } = item.contents;

  // WARN undefined is not serializable!!
  const bookData = backendsBookDataUtils.convertToSerialRenderData({ book, badges });
  bookData.metadata = { ...bookData.metadata, extra: { ...bookData.metadata?.extra, description: '' } };

  return { ...item, contents: { ...itemContents, book: bookData } } as BookViewItem<T['items'][number]>;
};

export type MapBookSection<T extends BookSectionBase> = (section: T) => BookViewSection<T>;
export const mapBookSection = <T extends BookSectionBase>(section: T): BookViewSection<T> => {
  const defaultViewSection = mapDefaultSection(section);
  const items = defaultViewSection.items.items?.map(mapBookItem) ?? null;

  return { ...defaultViewSection, items: { ...defaultViewSection.items, items } };
};

export type ReduceBookSectionResource<T extends BookSectionBase> = (
  section: BookViewSection<T>,
  sectionResource: SectionResource,
) => Omit<BookViewSection<T>, 'frame'>;

export const reduceBookSectionResource = <T extends BookSectionBase>(
  section: BookViewSection<T>,
  sectionResource: SectionResource,
): Omit<BookViewSection<T>, 'frame'> => {
  const { data } = sectionResource as AiRecommendations | BestSellers | SectionItems;
  if ('section_items' in data) {
    const items = (data.section_items as T['items']).map(mapBookItem);
    return { ...section, items: { ...section.items, items } };
  }

  const items = data.items
    .map((item, itemIndex) => ({ id: itemIndex, layout: 'Unknown', contents: item }))
    .map(mapBookItem);

  return { ...section, items: { ...section.items, items } };
};

/*
 * AiRecommendation Section
 */
const mapAiRecommendationSection = mapBookSection as MapBookSection<AiRecommendationSection>;
export const reduceAiRecommendationSectionResource = (
  section: BookViewSection<AiRecommendationSection>,
  sectionResource: SectionResource,
): Omit<BookViewSection<AiRecommendationSection>, 'frame'> => {
  const nextSection = reduceBookSectionResource(section, sectionResource);

  const { data } = sectionResource as AiRecommendations;
  return { ...nextSection, contents: { ...nextSection.contents, title: data.title } };
};

/*
 * Group Section
 */
export type GroupViewItem = { [unitId: number]: BookRenderData[] };
export type GroupViewSection = ViewSection<
  GroupViewItem,
  GroupSection['contents'],
  Omit<GroupSection, 'contents' | 'items'>
>;

export type GroupPayload = {
  unitId: number;
};

export type GroupSavedSectionResource = {
  groupSavedItems: BookRenderData[];
};

export const mapGroupSection = ({ contents, ...frame }: GroupSection): GroupViewSection => ({
  contents,
  frame,
  items: { items: {}, defaultLoaderSpec: null, hasItemsToFetch: true },
});

export const reduceGroupSectionResource = (
  section: GroupViewSection,
  sectionResource: SectionResource,
  payload?: ReduceSectionResourcePayload,
): Omit<GroupViewSection, 'frame'> => {
  const unitId = payload?.unitId;
  if (!unitId) {
    return section;
  }

  const res = sectionResource as GroupSectionsItemsResponse | GroupSavedSectionResource;
  const items =
    'groupSavedItems' in res
      ? res.groupSavedItems
      : res.data.items.map(({ slot, extras }) => groupSections.convertToRenderData({ slot, extras }));

  return {
    ...section,
    items: {
      ...section.items,
      items: { ...section.items.items, [unitId]: items },
    },
  };
};

/*
 * SelectionCarousel Section
 */
export type SelectionCarouselViewItem = Omit<SelectionCarouselItem, 'contents'> & {
  contents: Omit<SelectionCarouselItem['contents'], 'book'> & { book: BookRenderData };
};

export type SelectionCarouselViewSection = ViewSection<
  SelectionCarouselViewItem[],
  SelectionCarouselSection['contents'],
  Omit<SelectionCarouselSection, 'contents' | 'items'>
>;

export const mapSelectionCarouselItem = (item: SelectionCarouselItem): SelectionCarouselViewItem => {
  const { book, ...itemContents } = item.contents;
  const { bookId, title, link, cover } = backendsBookDataUtils.convertToSerialRenderData({ book });
  const { thumbnail, isAdultOnly } = cover ?? {};

  const viewBook = { bookId, title, link, cover: { thumbnail, isAdultOnly } };
  return { ...item, contents: { ...itemContents, book: viewBook } };
};

export const mapSelectionCarouselSection = (section: SelectionCarouselSection): SelectionCarouselViewSection => {
  const defaultViewSection = mapDefaultSection(section);
  const items = defaultViewSection.items.items?.map(mapSelectionCarouselItem) ?? null;

  return { ...defaultViewSection, items: { ...defaultViewSection.items, items } };
};

export const mapImageGradientBannerSection = <T extends SectionBase>(section: T): DefaultViewSection<T> => {
  const { contents, items, ...frame } = section;

  /**
   * items에 배열을 할당하여 SSR 시 withRenderer가 컴포넌트를 렌더링하게 만들고,
   * null을 요소로 추가해 CSR시 useDefaultLoader에 의해 items가 null이 되지 않도록 함.
   * TODO: ImageGradientBanner처럼 items가 없는 섹션은 SSR 결과와 CSR 결과이 일치될 수 있도록 수정 필요.
   */
  return { frame, contents, items: { items: [null], defaultLoaderSpec: null, hasItemsToFetch: false } };
};

export const reduceSelectionCarouselSectionResource = (
  section: SelectionCarouselViewSection,
  sectionResource: SectionResource,
): Omit<SelectionCarouselViewSection, 'frame'> => {
  const { data } = sectionResource as SectionItems;
  const items = (data.section_items as SelectionCarouselItem[]).map(mapSelectionCarouselItem);

  return { ...section, items: { ...section.items, items } };
};

/*
 * All Sections
 */
export const SECTION_MAPPERS = {
  [SectionLayoutType.AiRecommendation]: mapAiRecommendationSection,
  [SectionLayoutType.BestSeller]: mapBookSection as MapBookSection<BestSellerSection>,
  [SectionLayoutType.EventHorizontal]: mapDefaultSection as MapDefaultSection<EventHorizontalSection>,
  [SectionLayoutType.EventVertical]: mapDefaultSection as MapDefaultSection<EventVerticalSection>,
  [SectionLayoutType.Group]: mapGroupSection,
  [SectionLayoutType.ImageGradientBanner]: mapImageGradientBannerSection<ImageGradientBannerSection>,
  [SectionLayoutType.Keyword]: mapDefaultSection as MapDefaultSection<KeywordSection>,
  [SectionLayoutType.NewRelease]: mapBookSection as MapBookSection<NewReleaseSection>,
  [SectionLayoutType.OnePick]: mapDefaultSection as MapDefaultSection<OnePickSection>,
  [SectionLayoutType.QuickMenu]: mapDefaultSection as MapDefaultSection<QuickMenuSection>,
  [SectionLayoutType.ReadingBook]: mapBookSection as MapBookSection<ReadingBookSection>,
  [SectionLayoutType.Selection]: mapBookSection as MapBookSection<SelectionSection>,
  [SectionLayoutType.SelectionCarousel]: mapSelectionCarouselSection,
  [SectionLayoutType.SelectionCover]: mapDefaultSection as MapDefaultSection<SelectionCoverSection>,
  [SectionLayoutType.SelectionHookingSentence]: mapBookSection as MapBookSection<SelectionHookingSentenceSection>,
  [SectionLayoutType.SelectionOriginal]: mapDefaultSection as MapDefaultSection<SelectionOriginalSection>,
  [SectionLayoutType.SelectionMultiline]: mapBookSection as MapBookSection<SelectionMultilineSection>,
  [SectionLayoutType.TopCarouselBanner]: mapDefaultSection as MapDefaultSection<TopCarouselBannerSection>,
  [SectionLayoutType.TopCarouselBook]: mapBookSection as MapBookSection<TopCarouselBookSection>,
  [SectionLayoutType.TopCarouselCover]: mapDefaultSection as MapDefaultSection<TopCarouselCoverSection>,
} as const;

export type MapSection = (section: Section) => ViewSection;

// prettier-ignore
export const SECTION_RESOURCE_REDUCERS = {
  [SectionLayoutType.AiRecommendation]: reduceAiRecommendationSectionResource,
  [SectionLayoutType.BestSeller]: reduceBookSectionResource as ReduceBookSectionResource<BestSellerSection>,
  [SectionLayoutType.EventHorizontal]: reduceDefaultSectionResource as ReduceDefaultSectionResource<EventHorizontalSection>,
  [SectionLayoutType.EventVertical]: reduceDefaultSectionResource as ReduceDefaultSectionResource<EventVerticalSection>,
  [SectionLayoutType.Group]: reduceGroupSectionResource,
  [SectionLayoutType.ImageGradientBanner]: reduceDefaultSectionResource,
  [SectionLayoutType.Keyword]: reduceDefaultSectionResource as ReduceDefaultSectionResource<KeywordSection>,
  [SectionLayoutType.NewRelease]: reduceBookSectionResource as ReduceBookSectionResource<NewReleaseSection>,
  [SectionLayoutType.OnePick]: reduceDefaultSectionResource as ReduceDefaultSectionResource<OnePickSection>,
  [SectionLayoutType.QuickMenu]: reduceDefaultSectionResource as ReduceDefaultSectionResource<QuickMenuSection>,
  [SectionLayoutType.ReadingBook]: reduceBookSectionResource as ReduceBookSectionResource<ReadingBookSection>,
  [SectionLayoutType.Selection]: reduceBookSectionResource as ReduceBookSectionResource<SelectionSection>,
  [SectionLayoutType.SelectionCarousel]: reduceSelectionCarouselSectionResource,
  [SectionLayoutType.SelectionCover]: reduceDefaultSectionResource as ReduceDefaultSectionResource<SelectionCoverSection>,
  [SectionLayoutType.SelectionHookingSentence]: reduceBookSectionResource as ReduceBookSectionResource<SelectionHookingSentenceSection>,
  [SectionLayoutType.SelectionOriginal]: reduceDefaultSectionResource as ReduceDefaultSectionResource<SelectionOriginalSection>,
  [SectionLayoutType.SelectionMultiline]: reduceBookSectionResource as ReduceBookSectionResource<SelectionMultilineSection>,
  [SectionLayoutType.TopCarouselBanner]: reduceDefaultSectionResource as ReduceDefaultSectionResource<TopCarouselBannerSection>,
  [SectionLayoutType.TopCarouselBook]: reduceBookSectionResource as ReduceBookSectionResource<TopCarouselBookSection>,
  [SectionLayoutType.TopCarouselCover]: reduceDefaultSectionResource as ReduceDefaultSectionResource<TopCarouselCoverSection>,
};

export type ReduceSectionResource = (
  section: ViewSection,
  sectionResource: SectionResource,
  payload?: ReduceSectionResourcePayload,
) => ViewSection;

// prettier-ignore
export type SectionMerged<S extends ViewSection = ViewSection> =
  & S['frame']
  & Omit<S, 'frame' | 'items'>
  & { items: Exclude<S['items']['items'], null> };

export type SectionByLayout<L extends SectionLayoutType> = SectionMerged<ReturnType<(typeof SECTION_MAPPERS)[L]>>;
