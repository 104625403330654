import { AnyAction, combineReducers } from '@reduxjs/toolkit';

import { checkoutRootReducer, CheckoutRootState } from './checkout';

export interface InAppRootState {
  checkout: CheckoutRootState;
}

export const inappRootReducer = (state: InAppRootState | undefined, action: AnyAction): InAppRootState => {
  const combinedReducers = combineReducers({
    checkout: checkoutRootReducer,
  });

  return combinedReducers(state, action);
};

export type InAppRootReducerType = ReturnType<typeof inappRootReducer>;
