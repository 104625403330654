import qs from 'qs';

import { PaymentRouteMethodModel } from '@/models/booksBackend/PaymentRouteMethod/PaymentRouteMethodModel';
import { ServiceMethod, stringifyRequestParams } from '@/services/baseService';
import { getFeatureFlagValueByKey } from '@/utils/featureFlag';

import { makeCallAPIMethod } from '../booksBackendService';
import { PaymentRouteMethodRequest } from './interfaces/PaymentRouteMethodRequest';

export const paymentRouteMethod: ServiceMethod<PaymentRouteMethodRequest, PaymentRouteMethodModel, void> = async (
  reqParams,
  req,
) => {
  const { method, endpoint, params } = reqParams.body;
  const apiURL = new URL(endpoint);

  const parsedParams = qs.parse(params);
  const methodParams =
    method === 'GET'
      ? {
          query: parsedParams,
        }
      : {
          body: parsedParams,
        };

  const call = makeCallAPIMethod(
    !!getFeatureFlagValueByKey({
      featureFlag: req?.FeatureFlag || reqParams.featureFlag,
      featureFlagKey: 'web-internal-books-backendsapi-request-20240813',
    }),
  )(PaymentRouteMethodModel);

  return call<PaymentRouteMethodRequest, void, PaymentRouteMethodModel>(
    {
      method,
      pathname: apiURL.pathname.replace(/^(?:\/api\/payment)?/, '/api/payment'),
      params: methodParams,
      transformRequest: [stringifyRequestParams],
      paramsSerializer: stringifyRequestParams,

      through: {
        method: 'POST',
        pathname: '/api/payment/route/method',
        params: reqParams,
      },
    },
    req,
  );
};
