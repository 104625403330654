import { BaseModel } from '@/models/BaseModel';

import { AccountSignupCreateAccount, RAccountSignupCreateAccount } from './AccountSignupCreateAccountType';

export class AccountSignupCreateAccountModel extends BaseModel<AccountSignupCreateAccount> {
  private headers: Record<string, string | undefined>;

  constructor(data: AccountSignupCreateAccount, { headers }: { headers: Record<string, string | undefined> }) {
    super(data, RAccountSignupCreateAccount);
    this.headers = headers;
  }

  get Headers(): Record<string, string | undefined> {
    return this.headers;
  }
}
