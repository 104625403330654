import { Literal as RLiteral, Null as RNull, Record as RRecord, Static as RStatic, String as RString } from 'runtypes';

export const RAccountParentalConsentRequiredData = RRecord({
  success: RLiteral(true),
  message: RNull,
  data: RRecord({
    request_data: RString,
  }),
});

export type AccountParentalConsentRequiredData = RStatic<typeof RAccountParentalConsentRequiredData>;
