import { PaymentRequestModel } from '@/models/booksBackend/PaymentRequest/PaymentRequestModel';
import { PaymentRequestFailure } from '@/models/booksBackend/PaymentRequest/PaymentRequestType';
import { ServiceMethod, stringifyRequestParams } from '@/services/baseService';
import { getFeatureFlagValueByKey } from '@/utils/featureFlag';

import { makeCallAPIMethod } from '../booksBackendService';
import { PaymentRequestRequest } from './interfaces/PaymentRequestRequest';

export const paymentRequest: ServiceMethod<PaymentRequestRequest, PaymentRequestModel, PaymentRequestFailure> = async (
  reqParams,
  req,
) => {
  const call = makeCallAPIMethod(
    !!getFeatureFlagValueByKey({
      featureFlag: req?.FeatureFlag || reqParams.featureFlag,
      featureFlagKey: 'web-internal-books-backendsapi-request-20240813',
    }),
  )(PaymentRequestModel);
  return call<PaymentRequestRequest, PaymentRequestFailure, PaymentRequestModel>(
    {
      method: 'POST',
      pathname: '/api/payment/request',
      params: { body: reqParams.body },
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      transformRequest: [stringifyRequestParams],
      through: {
        method: 'POST',
        pathname: '/api/payment/request',
        params: { body: reqParams.body },
      },
    },
    req,
  );
};
