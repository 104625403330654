import { captureException, withScope } from '@sentry/nextjs';
import { Component, ReactNode } from 'react';

interface ErrorBoundaryProps {
  children?: ReactNode;
  fallback?: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error) {
    withScope(scope => {
      // 에러 트레이싱 시, Sentry에서 자체적으로 수집하는 에러와의 구분을 위해 추가
      scope.setTag('type', 'ErrorBoundary');

      captureException(error, {
        level: 'error',
      });
    });
  }

  render() {
    const { hasError } = this.state;
    const { fallback, children } = this.props;

    if (hasError) {
      return fallback || null;
    }

    return children;
  }
}
