export interface BaseErrorProps {
  code?: string;
  data?: Record<string, Any>;
  extra?: Record<string, Any>;
  message?: string;
  name?: string;
  status?: number;
}

export class BaseError extends Error {
  code?: string;

  status?: number;

  data?: BaseErrorProps['data'];

  date = new Date();

  extra?: Record<string, Any>;

  constructor(props: BaseErrorProps) {
    super(props.message);
    this.name = 'BaseError';

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, BaseError);
    }

    this.code = props.code;
    this.status = props.status;
    this.data = props.data;
    this.extra = props.extra;
  }
}
