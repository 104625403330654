import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { State } from '@/features/store';
import { debug } from '@/utils/debug';

const log = debug('toastSlice');

export interface ToastState {
  success: boolean;
  message: string;
  link: {
    url: string;
    message: string;
  } | null;
  desiredVisibility: boolean;
}

export const toastSelector = (state: State): ToastState => state.global.toast;

const initialState: ToastState = {
  success: false,
  message: '',
  link: null,
  desiredVisibility: false,
};

interface PayloadActionProp {
  message: string;
  success: boolean;
  link: {
    url: string;
    message: string;
  } | null;
}

export const toastSlice = createSlice({
  name: 'global/toast',
  initialState,
  reducers: {
    showMessage: (state: ToastState, action: PayloadAction<PayloadActionProp>) => {
      log('showMessage');
      state.message = action.payload.message;
      state.link = action.payload.link || null;
      state.success = action.payload.success;
      state.desiredVisibility = true;
    },
    hideMessage: (state: ToastState) => {
      log('hideMessage');
      state.desiredVisibility = false;
      state.link = null;
    },
  },
});
