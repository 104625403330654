import { css, SerializedStyles } from '@emotion/react';

export const inappStyle: SerializedStyles = css`
  * {
    -webkit-tap-highlight-color: transparent;
  }

  html {
    /* ridi-roboto 제거하고 시스템 서체로만 사용하게끔 */
    font-family: -apple-system, system-ui, BlinkMacSystemFont, Roboto, Helvetica, Arial, sans-serif;
  }

  * {
    user-select: none;
  }

  input,
  textarea {
    user-select: auto;
  }

  h1,
  h2,
  h3,
  h4,
  button,
  input,
  p,
  span {
    letter-spacing: -0.01em;
  }
`;
