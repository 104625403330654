import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { type GlobalState } from '@/features/global';
import { hydrateAction } from '@/features/hydrate';
import { type State } from '@/features/store';
import { type FeatureFlag, type FeatureFlagKey, getFeatureFlagValueByKey } from '@/utils/featureFlag';

export type FeatureFlagState = FeatureFlag;

export const featureFlagStateSelector = createSelector(
  (state: State) => state.global,
  (state: GlobalState) => state.featureFlag,
);

export const featureFlagSelector = (flagKey: FeatureFlagKey) =>
  createSelector(featureFlagStateSelector, (state: FeatureFlagState) =>
    getFeatureFlagValueByKey({ featureFlag: state, featureFlagKey: flagKey }),
  );

const initialState = [] as FeatureFlagState;

export const featureFlagSlice = createSlice({
  name: 'global/featureFlag',
  initialState,
  reducers: {
    setFeatureFlagAction: (state: FeatureFlagState, action: PayloadAction<FeatureFlag>) => {
      state.push(...action.payload);
    },
  },
  extraReducers(builder) {
    builder.addCase(hydrateAction, (state, action) => {
      if (action.payload.global.featureFlag && action.payload.global.featureFlag.length > 0) {
        return action.payload.global.featureFlag;
      }
      return state;
    });
  },
});

export const { setFeatureFlagAction } = featureFlagSlice.actions;
export type FeatureFlagReducerType = ReturnType<typeof featureFlagSlice.reducer>;
