import { CheckoutRequestInfo } from '../checkout/checkoutSlice';

export enum CheckoutRenderStateTypes {
  CLOSED = 0,
  BOTTOMSHEET_OPEN = 1,
  DIALOG_ADULTCHECK_OPEN = 2,
  DIALOG_FREECOUPON_OPEN = 3,
}

export enum CheckoutBottomSheetRenderTypes {
  BOTTOMSHEET_CHECKOUT_OPEN = 0,
  BOTTOMSHEET_FREECOUPON_OPEN = 1,
}

export type CheckoutRenderStates =
  | { state: CheckoutRenderStateTypes.CLOSED }
  | { state: CheckoutRenderStateTypes.BOTTOMSHEET_OPEN; type: CheckoutBottomSheetRenderTypes }
  | { state: CheckoutRenderStateTypes.DIALOG_ADULTCHECK_OPEN }
  | { state: CheckoutRenderStateTypes.DIALOG_FREECOUPON_OPEN };

export type CheckoutPurchaseFinishedBook = { bookId: string; isFreeBook: boolean; setBookId: null | string };

export enum FetchCheckoutContinuationTypes {
  ADULT_CHECK,
  CHECKOUT,
  PAYMENTROUTE_ERROR,
}

export type FetchCheckoutContinuation =
  | { continuation: FetchCheckoutContinuationTypes.ADULT_CHECK }
  | { continuation: FetchCheckoutContinuationTypes.CHECKOUT }
  | { continuation: FetchCheckoutContinuationTypes.PAYMENTROUTE_ERROR; reason: string | null };

export enum FreeCouponsSuccessContinuation {
  SUCCESS,
  CHECKOUT,
}

export enum CheckoutStateTypes {
  INITIAL = 0,
  FETCH_START = 1,
  FETCH_CHECKOUT_WAITING = 2,
  FETCH_PAYMENTROUTEBOOK_WAITING = 3,
  FETCH_PAYMENTROUTEEPISODE_WAITING = 4,

  ADULT_CHECK = 10,
  ADULT_CHECK_SUCCESS = 11,
  FREECOUPONS = 20,
  EPISODE_FREECOUPONS = 21,
  FREECOUPONS_SUCCESS = 22,
  CHECKOUT = 30,
  CHECKOUT_CHARGE_RIDICASH = 31,
  FREE_PAYMENT = 40,

  // Successful
  SUCCESS = 1000,

  // Failure
  FAILURE = -1,
  FAILURE_UNAUTHORIZED = -2,
  FAILURE_ABORTED = -3,
  FAILURE_ADULT_VERIFY = -4,
}

export type CheckoutInfo = {
  requestInfo: CheckoutRequestInfo;
  orderType: 'purchase' | 'rental';
  isFreePayment: boolean;
  isPointback: boolean;
};

export type InitialState = {
  state: CheckoutStateTypes.INITIAL;
};

export type FetchStartState = {
  state: CheckoutStateTypes.FETCH_START;
};

export type FetchCheckoutState = {
  state: CheckoutStateTypes.FETCH_CHECKOUT_WAITING;
  continuation: FetchCheckoutContinuation;
};

export type FetchPaymentRouteBookState = {
  state: CheckoutStateTypes.FETCH_PAYMENTROUTEBOOK_WAITING;
};

export type FetchPaymentRouteEpisodeState = {
  state: CheckoutStateTypes.FETCH_PAYMENTROUTEEPISODE_WAITING;
};

export type FreeCouponsState = {
  state: CheckoutStateTypes.FREECOUPONS;
  hasFreeCoupons: boolean;
};

export type AdultCheckState = {
  state: CheckoutStateTypes.ADULT_CHECK;
  hasAdultItems: boolean;
  isAllAdultItems: boolean;
};

export type AdultCheckSuccessState = {
  state: CheckoutStateTypes.ADULT_CHECK_SUCCESS;
};

export type EpisodeFreeCouponsState = {
  state: CheckoutStateTypes.EPISODE_FREECOUPONS;
  hasFreeCoupons: boolean;
};

export type FreeCouponsSuccessState = {
  state: CheckoutStateTypes.FREECOUPONS_SUCCESS;
  continuation: FreeCouponsSuccessContinuation;
  isWaitFree: boolean;
  transactionId: string;
};

export type CheckoutState = {
  state: CheckoutStateTypes.CHECKOUT;
};

export type CheckoutRidicashChargeState = {
  state: CheckoutStateTypes.CHECKOUT_CHARGE_RIDICASH;
};

export type FreePaymentState = {
  state: CheckoutStateTypes.FREE_PAYMENT;
};

export type SuccessState = {
  state: CheckoutStateTypes.SUCCESS;
  message: string;
  needsOpenBook: boolean;
  isWaitFree: boolean;
  eventType: 'free_coupons' | 'purchase' | 'rental' | 'abort';
  transactionId?: string;
};

export type FailureState = {
  state: CheckoutStateTypes.FAILURE;
  reason: string | null;
  code?: 'should-verify-adult';
};

export type FailureUnauthorizedState = {
  state: CheckoutStateTypes.FAILURE_UNAUTHORIZED;
};

export type FailureAbortedState = {
  state: CheckoutStateTypes.FAILURE_ABORTED;
};

export type FailureAdultVerifyState = {
  state: CheckoutStateTypes.FAILURE_ADULT_VERIFY;
};

export type CheckoutStates =
  | InitialState
  | FetchStartState
  | FetchCheckoutState
  | FetchPaymentRouteBookState
  | FetchPaymentRouteEpisodeState
  | FreeCouponsState
  | AdultCheckState
  | AdultCheckSuccessState
  | EpisodeFreeCouponsState
  | FreeCouponsSuccessState
  | CheckoutState
  | CheckoutRidicashChargeState
  | FreePaymentState
  | SuccessState
  | FailureState
  | FailureUnauthorizedState
  | FailureAbortedState
  | FailureAdultVerifyState;
