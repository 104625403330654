import {
  Array as RArray,
  Boolean as RBoolean,
  Literal as RLiteral,
  Null as RNull,
  Number as RNumber,
  Record as RRecord,
  Static as RStatic,
  String as RString,
} from 'runtypes';

import { RAuthor } from '@/base/interfaces/Author';
import { RBackendsBadge } from '@/models/backendsApi/BackendsBadgeType';
import { RBackendsBookCover } from '@/models/backendsApi/BackendsBookType';
import { RSectionItemSlot, RSectionItemSlotExtras } from '@/models/backendsApi/v2/Views/types/ViewsBaseType';

export const RGroupSectionsItem = RRecord({
  slot: RSectionItemSlot.extend({
    book_id: RString,
    title: RString,
    authors: RArray(RAuthor).optional(),
    adults_only: RBoolean,
    cover: RBackendsBookCover,
    ratings: RRecord({
      total_count: RNumber,
      rating_average: RNumber,
    }),
  }),
  extras: RSectionItemSlotExtras.extend({
    badges: RArray(RBackendsBadge),
    schedule: RRecord({
      dates: RArray(RNumber),
      text: RString,
    }).nullable(),
  }),
});
export type GroupSectionsItem = RStatic<typeof RGroupSectionsItem>;

export const RGroupSectionsItemsResponse = RRecord({
  success: RLiteral(true),
  message: RNull,
  data: RRecord({
    items: RArray(RGroupSectionsItem),
    pagination: RRecord({
      prev_page: RString.nullable(),
      next_page: RString.nullable(),
    }),
  }),
});

export type GroupSectionsItemsResponse = RStatic<typeof RGroupSectionsItemsResponse>;
