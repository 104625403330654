import { BaseModel } from '@/models/BaseModel';

import { AccountsMe, RAccountsMe } from './AccountsMeType';

export class AccountsMeModel extends BaseModel<AccountsMe> {
  private headers: Record<string, unknown> = {};

  constructor(data: AccountsMe, { headers = {} }: { headers?: Record<string, unknown> }) {
    super(data, RAccountsMe);
    this.headers = headers;
  }

  get Headers(): Record<string, unknown> {
    return this.headers;
  }
}
