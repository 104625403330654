import {
  Array as RArray,
  Boolean as RBoolean,
  Literal as RLiteral,
  Null as RNull,
  Number as RNumber,
  Record as RRecord,
  Static as RStatic,
  String as RString,
  Union as RUnion,
} from 'runtypes';

import { RBackendsBook, RBackendsBookPointback, RBackendsSerial } from '../../BackendsBookType';

export const RPointback = RBackendsBookPointback;
export type PointBack = RStatic<typeof RPointback>;

export const ROrderType = RUnion(RLiteral('purchase'), RLiteral('rental'));
export type OrderType = RStatic<typeof ROrderType>;

export const RCheckoutOrderItem = RBackendsBook.pick(
  'book_id',
  'title',
  'categories',
  'purchase',
  'rental',
  'adults_only',
  'set',
).extend({
  // 이벤트에서 사용할 최종 결제 금액
  payment_amount: RNumber,
});
export type CheckoutOrderItem = RStatic<typeof RCheckoutOrderItem>;

export const RCheckoutOrderSerial = RBackendsSerial.pick('serial_id', 'title', 'unit');
export type CheckoutOrderSerial = RStatic<typeof RCheckoutOrderSerial>;

export enum CheckoutCouponType {
  Discount = 'discount',
  PointGiftCard = 'point_gift_card',
}

export const RCheckoutCouponType = RUnion(
  RLiteral(CheckoutCouponType.Discount),
  RLiteral(CheckoutCouponType.PointGiftCard),
);

export const RCheckoutCouponItem = RRecord({
  id: RString,
  coupon_category: RNumber,
  title: RString,
  issued_date: RString,
  expiration_date: RString.nullable(),
  used_date: RString.nullable(),
  benefit: RString,
  use_condition: RString,
  // 쿠폰 그룹
  // discount: 일반 할인쿠폰
  // point_gift_card: 포인트 상품권
  group: RCheckoutCouponType,
  is_usable: RBoolean,
  is_applied: RBoolean,
  discount_amount: RNumber,
});
export type CheckoutCouponItem = RStatic<typeof RCheckoutCouponItem>;

const RCheckoutOrder = RRecord({
  type: ROrderType,
  items: RArray(RCheckoutOrderItem),
  unavailable_adults_only_items: RArray(RCheckoutOrderItem).optional(),
  serial: RCheckoutOrderSerial.optional(),
  is_entire_episode: RBoolean.optional(),
  total_price: RNumber,
  total_sale_price: RNumber,
  total_full_price: RNumber,
});
export type CheckoutOrder = RStatic<typeof RCheckoutOrder>;

const RCheckoutCoupon = RRecord({
  coupons: RArray(RCheckoutCouponItem),
  // 최대혜택적용 쿠폰id 조합
  // 조합 자체가 여러개일 수 있음
  most_beneficial_combinations: RArray(RArray(RString)),
});

const RCheckoutData = RRecord({
  available_order_types: RArray(ROrderType),
  order: RCheckoutOrder,
  coupon: RCheckoutCoupon,
  ridicash: RRecord({
    balance: RNumber,
  }),
  ridipoint: RRecord({
    balance: RNumber,
  }),
  payment: RRecord({
    amount: RNumber,
    is_possible: RBoolean,
  }),
  notices: RArray(
    RRecord({
      message: RString,
      level: RNumber,
    }),
  ),
});

export const RCheckout = RRecord({
  success: RLiteral(true),
  message: RNull,
  data: RRecord({
    checkout: RCheckoutData,
    csrf_token: RString,
  }),
});
export type Checkout = RStatic<typeof RCheckout>;
