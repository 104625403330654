import { Array as RArray, Number as RNumber, Record as RRecord, Static as RStatic, String as RString } from 'runtypes';

export const RUserDevice = RRecord({
  id: RNumber,
  user_idx: RNumber,
  device_id: RString,
  device_code: RString,
  device_ver: RString,
  device_nick: RString,
  status: RString,
  last_used: RString.nullable(),
  created: RString,
  last_modified: RString,
});
export type UserDevice = RStatic<typeof RUserDevice>;

export const RUserDeviceResponse = RRecord({
  user_device: RUserDevice,
});
export type UserDeviceResponse = RStatic<typeof RUserDeviceResponse>;

export const RUserDevicesResponse = RRecord({
  user_devices: RArray(RUserDevice),
});
export type UserDevicesResponse = RStatic<typeof RUserDevicesResponse>;
