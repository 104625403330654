import { AnyAction, combineReducers } from '@reduxjs/toolkit';

import { changePasswordSlice, ChangePasswordState } from './changePassword/changePasswordSlice';
import { emailSendSlice, EmailSendState } from './emailSend/emailSendSlice';
import { findPasswordSlice, FindPasswordState } from './findPassword/findPasswordSlice';
import { loginSlice, LoginState } from './login/loginSlice';
import { parentalConsentSlice, ParentalConsentState } from './parentalConsent/parentalConsentSlice';
import { signupFormSlice, SignupFormState } from './signupForm/signupFormSlice';
import { userDevicesSlice, UserDevicesState } from './userDevices/userDevicesSlice';

export interface AccountState {
  changePassword: ChangePasswordState;
  emailSend: EmailSendState;
  findPassword: FindPasswordState;
  login: LoginState;
  parentalConsent: ParentalConsentState;
  signupForm: SignupFormState;
  userDevices: UserDevicesState;
}

export const accountReducer = (state: AccountState | undefined, action: AnyAction): AccountState => {
  const combinedReducers = combineReducers({
    changePassword: changePasswordSlice.reducer,
    emailSend: emailSendSlice.reducer,
    findPassword: findPasswordSlice.reducer,
    login: loginSlice.reducer,
    parentalConsent: parentalConsentSlice.reducer,
    signupForm: signupFormSlice.reducer,
    userDevices: userDevicesSlice.reducer,
  });

  return combinedReducers(state, action);
};

export type AccountReducerType = ReturnType<typeof accountReducer>;
