import { AxiosRequestConfig } from 'axios';
import { Query } from 'express-serve-static-core';
import { join } from 'path';
import qs from 'qs';

import type { ServerRequest } from '@/base/interfaces/ServerRequest';
import type {
  FallbackServerRequestParameters,
  ServerRequestParameters,
} from '@/base/interfaces/ServerRequestParameters';
import { BaseModel } from '@/models/BaseModel';
import { isBrowser } from '@/utils/isBrowser';
import { Method, request, RequestConfig, RequestError, RequestResult } from '@/utils/request';

export interface ReqParams {
  query?: Query;
  body?: Record<string, unknown>;
}

export interface ReqConfig
  extends Pick<
    AxiosRequestConfig,
    'transformRequest' | 'transformResponse' | 'maxRedirects' | 'validateStatus' | 'httpAgent' | 'httpsAgent'
  > {
  method: Method;
  pathname: string;
  host?: string;
  params?: ReqParams;
  paramsSerializer?: (params: Query) => string;
  routePrefix?: string;
  headers?: Record<string, unknown>;
  timeout?: number;
  withCredentials?: boolean;

  // Proxy-related configs
  through?: Omit<ReqConfig, 'through' | 'preferNonProxied'>;
  preferNonProxied?: boolean;
}

export type ServiceMethodSuccess<T> = [never, T];
export type ServiceMethodFailure<E> = [E, never];
export type ServiceMethodResult<E, T> = XOR<ServiceMethodFailure<E>, ServiceMethodSuccess<T>>;
export type ServiceMethodOptions<Params> = {
  cache?: {
    getKey?: ({ reqParams }: { reqParams: Params }) => string;
    seconds: number;
  };
};

export interface ServiceMethod<RequestParameters, ReturnModel, ErrorType = Error> {
  (
    ...reqParams: RequestParameters extends ServerRequestParameters
      ? [
          RequestParameters,
          (ServerRequest<RequestParameters> | ServerRequest)?,
          ServiceMethodOptions<RequestParameters>?,
        ]
      : [void?, ServerRequest?, ServiceMethodOptions<void>?]
  ): Promise<ServiceMethodResult<RequestError<ErrorType>, ReturnModel>>;
}

export interface GetServiceParams {
  path?: string;
  host?: string;
}

export interface GetService {
  (params: GetServiceParams): ServiceConfig;
}

export type ServiceConfig = Pick<
  ReqConfig,
  | 'host'
  | 'routePrefix'
  | 'paramsSerializer'
  | 'timeout'
  | 'withCredentials'
  | 'preferNonProxied'
  | 'httpAgent'
  | 'httpsAgent'
> & {
  through?: ServiceConfig;
};

export type CacheDomain = 'storeApi' | 'bookApi' | 'backendsApi';
export const CACHE_PREFIX = 'cache';
export const REDIS_KEY_PREFIX = 'BOOKSFE:';

export const isServer = (): boolean => !isBrowser();
export const isInternalServerToServerRequest = (): boolean => isServer() && process.env.NODE_ENV === 'production';

export const shouldProxy = (): boolean =>
  process.env.NEXT_PUBLIC_PROXY_REQUESTS === 'proxy' ||
  (process.env.NODE_ENV !== 'production' && process.env.NEXT_PUBLIC_PROXY_REQUESTS !== 'no-proxy');

export const getCacheKey = <Params>(domain: CacheDomain, serviceMethod: string, params: Params): string => {
  const runMode = process.env.RUN_MODE || 'production';
  return `${REDIS_KEY_PREFIX}@@/${runMode}/${CACHE_PREFIX}/${domain}/${serviceMethod}/${JSON.stringify(params)}`;
};

export const getHost = (host: string | undefined): string | null => host ?? null;

export const getPrefix =
  (prefix?: string) =>
  (pathname: string): string => {
    if (prefix) {
      return join(prefix, pathname);
    }

    return pathname;
  };

export const getURL = ({ host, pathname }: { pathname: string; host?: string | null }): string => {
  if (host) {
    return new URL(pathname, host).toString();
  }

  return pathname;
};

export const getBaseHeaders = (): Record<string, unknown> => ({});

export const getHeaders = (headers?: Record<string, unknown>): Record<string, unknown> => ({
  ...(headers || {}),
  ...getBaseHeaders(),
});

export const escapeInvalidHeaders = (headers: Record<string, unknown>): Record<string, unknown> => {
  const headerKeys = Object.keys(headers);
  return headerKeys.reduce(
    (acc, key) => {
      const newAcc = { ...acc };
      if (headers[key] !== null && headers[key] !== undefined) {
        newAcc[key] = headers[key];
      }
      return newAcc;
    },
    {} as Record<string, unknown>,
  );
};

export const fromReq =
  <R extends ServerRequestParameters>(req?: ServerRequest<R> | ServerRequest) =>
  (reqConfig: Omit<ReqConfig, 'host' | 'routePrefix'>): Omit<ReqConfig, 'host' | 'routePrefix'> => {
    if (!req) {
      return reqConfig;
    }

    const originUserAgent = req.headers?.['user-agent'];
    const originCookie = req.headers?.cookie;
    const originClientIp = req.ip;

    const headers = escapeInvalidHeaders({
      ...reqConfig.headers,
      cookie: originCookie,
      'User-Agent': originUserAgent,
      'x-sent-through': `books-frontend/${process.env.RELEASE_ID}`,
      referer: req.headers?.referer,
      ...(process.env.NODE_ENV === 'production' && { 'X-Forwarded-For': originClientIp }),
    });

    return {
      ...reqConfig,
      headers,
    };
  };

export const replaceHost = <R extends ServerRequestParameters>(
  reqConfig: RequestConfig,
  req?: ServerRequest<R> | ServerRequest,
): RequestConfig => {
  if (reqConfig.url) {
    if ((isBrowser() && window.location.hostname === 'ridi.com') || req?.hostname === 'ridi.com') {
      return {
        ...reqConfig,
        url: reqConfig.url.replace(/^(https:\/\/[a-z0-9.-]+\.)ridibooks\.com/, '$1ridi.com'),
      };
    }
  }

  return reqConfig;
};

export const makeRequestConfig = (reqConfig: Omit<ReqConfig, 'through' | 'preferNonProxied'>): RequestConfig => {
  const host = getHost(reqConfig.host);
  const pathname = getPrefix(reqConfig.routePrefix)(reqConfig.pathname);
  const url = getURL({ host, pathname });
  const headers = getHeaders(reqConfig.headers);

  return {
    ...reqConfig,
    url,
    headers,
    method: reqConfig.method,
    data: reqConfig.params?.body,
    params: reqConfig.params?.query,
    paramsSerializer: reqConfig.paramsSerializer,
    withCredentials: reqConfig.withCredentials ?? true,
    timeout: reqConfig.timeout ?? 45000,
  };
};

export const callAPI =
  ({ through: serviceThrough, ...serviceConfig }: ServiceConfig) =>
  <E, T>(
    { through: reqThrough, ...reqConfig }: Omit<ReqConfig, 'host' | 'routePrefix'>,
    req?: ServerRequest,
  ): Promise<RequestResult<E, T>> => {
    const { preferNonProxied, ...mergedConfig } = { ...serviceConfig, ...reqConfig };
    const config = makeRequestConfig(mergedConfig);

    if (isBrowser() && (shouldProxy() || !preferNonProxied)) {
      const proxiedConfig =
        (reqThrough || serviceThrough) &&
        makeRequestConfig({
          ...(reqThrough || reqConfig),
          ...(serviceThrough || serviceConfig),
        });

      if (proxiedConfig) {
        return request(proxiedConfig);
      }
    }

    return request(replaceHost(config, req));
  };

interface Class<T> {
  new (...args: Any[]): T;
}

const makeModel = <T>(TheClass: Class<T>, ...args: unknown[]): T => new TheClass(...args);

export const isServiceFailure = <E, T>(
  result: ServiceMethodResult<RequestError<E>, T>,
): result is ServiceMethodFailure<RequestError<E>> => !!result[0] && !result[1];
export const isServiceSuccess = <E, T>(result: ServiceMethodResult<E, T>): result is ServiceMethodSuccess<T> =>
  !result[0] && !!result[1];

export type MakeServiceMethodReturnType = <Model>(
  Klass: Class<Model>,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
) => <R extends ServerRequestParameters | void, E, T = never>(
  reqConfig: Omit<ReqConfig, 'host' | 'routePrefix'>,
  req?: ServerRequest<FallbackServerRequestParameters<R>> | ServerRequest,
) => Promise<ServiceMethodResult<RequestError<E>, Model>>;

export const makeServiceMethod = (
  getService: GetService,
  serviceParams: GetServiceParams = {},
): MakeServiceMethodReturnType => {
  const call = callAPI(getService(serviceParams));

  return <Model>(Klass: Class<Model>) =>
    async <R extends ServerRequestParameters | void, E, T>(
      reqConfig: Omit<ReqConfig, 'host' | 'routePrefix'>,
      req?: ServerRequest<FallbackServerRequestParameters<R>> | ServerRequest,
    ) => {
      const makeConfig = fromReq<FallbackServerRequestParameters<R>>(req);
      const [error, data] = await call<E, T>(makeConfig(reqConfig), req);

      if (error) {
        return [error, undefined as unknown] as ServiceMethodFailure<RequestError<E>>;
      }

      return [
        undefined,
        makeModel(Klass, data.data, {
          headers: data.headers as Record<string, unknown>,
        }),
      ] as ServiceMethodSuccess<Model>;
    };
};

export const stringifyRequestParams = (data: ReqParams): string => qs.stringify(data);

export const makeServiceAction =
  <R extends ServerRequestParameters | void, E, Model extends BaseModel<Any>>(
    callMethod: ReturnType<MakeServiceMethodReturnType>,
    getReqConfig: (params: R) => Omit<ReqConfig, 'host' | 'routePrefix'>,
  ) =>
  (Klass: Class<Model>, makeCacheKey?: (reqParams: R) => string) =>
  async (
    reqParams: R,
    req?: ServerRequest<FallbackServerRequestParameters<R>> | ServerRequest,
    { cache }: ServiceMethodOptions<R> = {},
  ): Promise<ServiceMethodResult<RequestError<E>, Model>> => {
    const className = Klass.name;
    const addFinishMetric = req?.addMetric?.(`serviceAction__${className}`);

    const key = cache?.getKey?.({ reqParams }) || makeCacheKey?.(reqParams);

    if (req && key && cache?.seconds) {
      const cachedResult = await req.CacheRedis?.get(key);
      if (cachedResult) {
        addFinishMetric?.(`serviceAction__${className}__cached`);
        return [undefined as never, new Klass(JSON.parse(cachedResult))] as ServiceMethodSuccess<Model>;
      }
    }

    const result = await callMethod<R, E>(getReqConfig(reqParams), req);

    if (isServiceFailure(result)) {
      return result;
    }

    if (req && key && cache?.seconds) {
      await req.CacheRedis?.setex(key, cache.seconds, JSON.stringify((result[1] as BaseModel<Any>).Data));
    }

    addFinishMetric?.();
    return result as ServiceMethodSuccess<Model>;
  };
