import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';

import type { AccountState } from '@/features/account';
import type { State } from '@/features/store';
import { accountParentalConsentRequiredData } from '@/services/backendsApi/v1/accountParentalConsentRequiredData/accountParentalConsentRequiredDataService';
import { debug } from '@/utils/debug';

const log = debug('parentalConsent');

export interface ParentalConsentState {
  // Nice CheckPlus에 보낼 데이터
  requiredData: string;
}

const parentalConsentSelector = createSelector(
  (state: State) => state.account,
  (state: AccountState) => state.parentalConsent,
);

export const parentalConsentRequiredDataSelector = createSelector(
  parentalConsentSelector,
  (state: ParentalConsentState) => state.requiredData,
);

const initialState: ParentalConsentState = {
  requiredData: '',
};

export const fetchParentalConsentRequiredDataAction = createAsyncThunk(
  'account/parentalConsent/fetchParentalConsentRequiredDataAction',
  async (_, thunkAPI) => {
    const [error, model] = await accountParentalConsentRequiredData();

    if (error) {
      return thunkAPI.rejectWithValue({
        message: error.message,
        data: error.response?.data,
      });
    }

    return model.Data;
  },
);

export const parentalConsentSlice = createSlice({
  name: 'account/parentalConsent',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchParentalConsentRequiredDataAction.fulfilled, (state, action) => {
      log('## fetchParentalConsentRequiredDataAction.fulfilled', state, action);
      state.requiredData = action.payload.data.request_data;
    });
  },
});

export type ParentalConsentReducerType = ReturnType<typeof parentalConsentSlice.reducer>;
