import { Global, ThemeProvider } from '@emotion/react';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';

import { partialResetStyles, resetStyles } from '@/components/styles/reset';
import { darkTheme, defaultTheme, lightTheme } from '@/components/styles/themes';
import { isRidiAppSelector } from '@/features/global/variables/variablesSlice';

import { inappStyle } from './InAppStyles.styles';

export type ThemeStringType = 'dark' | 'light';

type AppThemeProviderProps = {
  children: React.ReactNode;
  themeString?: ThemeStringType;
  forceDark?: boolean;
};

// 다크 테마 QA 가 적용되지 않은 라우트 리스트
// TODO: QA 가 적용되면 제거
const excludeRouteList = [
  '/keyword-finder/', // 키워드 파인더
  '/notification', // 알림
  '/account/secede', // 회원탈퇴
  '/account/leave', // 회원탈퇴
  '/order/checkout/cash', // 일반 캐시 충전
  '/order/checkout/periodic-cash', // 자동충전
];

export const AppThemeProvider = ({
  children,
  themeString,
  forceDark = false,
}: AppThemeProviderProps): ReactJSX.Element => {
  const isInApp = useSelector(isRidiAppSelector);
  const router = useRouter();

  let theme = defaultTheme;

  if (isInApp) {
    const regexp = new RegExp(`^(${excludeRouteList.join('|')})`);

    if (themeString === 'dark') {
      theme = darkTheme;
    } else if (themeString === 'light') {
      theme = lightTheme;
    }

    // 다크 테마 적용을 제외할 라우트 대상이 있고, 현재 페이지의 pathname이 제외할 대상 라우트에 포함되어 있으면 기본 테마로 변경
    if (excludeRouteList.length > 0 && regexp.test(router.pathname)) {
      theme = defaultTheme;
    }
  }

  if (forceDark) {
    theme = darkTheme;
  }

  const isPartials = router.pathname.startsWith('/partials');

  return (
    <ThemeProvider theme={theme}>
      <Global styles={isPartials ? partialResetStyles : resetStyles} />
      {isInApp && <Global styles={inappStyle} />}
      {children}
    </ThemeProvider>
  );
};
