import { reconstate } from '@ridi-web/reconstate';
import debug from 'debug';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { hydrateAuthVariablesAction } from '@/features/global/actions';
import { refreshAuthAction } from '@/features/global/auth/authSlice';
import { isHydrateNeededSelector } from '@/features/global/isHydrateNeeded';
import { fetchUnreadCountAction } from '@/features/notification';
import { initializeByLanding } from '@/utils/deauthWatchdog';
import { type FeatureFlag, getFeatureFlagValueByKey } from '@/utils/featureFlag';

import { useAppDispatch } from './useAppDispatch';
import { useFingerprint } from './useFingerprint';
import { useLoggedUser } from './useLoggedUser';

const useInitializeApp = ({ featureFlag }: { featureFlag?: FeatureFlag }) => {
  const dispatch = useAppDispatch();
  const isHydrateNeeded = useSelector(isHydrateNeededSelector);
  const uidx = useLoggedUser()?.idx;
  const deauthWatchDogActive = getFeatureFlagValueByKey({
    featureFlag,
    featureFlagKey: 'deauth-watchdog',
  });
  useFingerprint();

  useEffect(() => {
    if (!isHydrateNeeded && deauthWatchDogActive) {
      initializeByLanding({ uidx });
    }
  }, [deauthWatchDogActive, isHydrateNeeded, uidx]);

  useEffect(() => {
    if (isHydrateNeeded) {
      dispatch(hydrateAuthVariablesAction());
    }

    // safari 에서 뒤로가기 bfcache 이슈로 인해 추가
    // TODO: ios 13 safari 에서는 동작하지 않음
    const isSafari = /^((?!chrome|android).)*safari/i.test(window.navigator.userAgent);
    const onVisibilityChange = () => {
      if (window.document.visibilityState === 'visible') {
        dispatch(hydrateAuthVariablesAction());
      }
    };

    if (isSafari) {
      window.addEventListener('visibilitychange', onVisibilityChange);
    }

    return () => {
      if (isSafari) {
        window.removeEventListener('visibilitychange', onVisibilityChange);
      }
    };
  }, [dispatch, isHydrateNeeded]);

  // 일반적인 상황에서 뒤로가기 bfcache 이슈로 인해 로그인 인증정보 상태가 반영안되던 이슈가 있어 추가
  // persisted가 동작안할 우려가 있어, performanceEntry 사용 (https://stackoverflow.com/a/42063482)
  useEffect(() => {
    window.addEventListener(
      'pageshow',
      event => {
        const performanceEntry = performance.getEntriesByType('navigation')[0];

        if (event.persisted || performanceEntry?.type === 'back_forward') {
          dispatch(refreshAuthAction({}));
          dispatch(fetchUnreadCountAction({}));
        }
      },
      false,
    );
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      try {
        if ('serviceWorker' in navigator && process.env.NODE_ENV === 'production') {
          await navigator.serviceWorker.register('/service-worker.js');
        }
      } catch (error) {
        debug('serviceWorker');
      }
    })();
  }, []);
};

const draft = reconstate(useInitializeApp);

export const InitializeAppProvider = draft.finalize();
