import { createAction, createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';

import { hydrateAction } from '@/features/hydrate';
import type { ActionRequest, RootState } from '@/features/store';
import { CheckoutCouponType } from '@/models/backendsApi/v1/Checkout/CheckoutType';
import { PaymentRequest } from '@/models/booksBackend/PaymentRequest/PaymentRequestType';
import {
  NonEntirePayObjectValue,
  PaymentRequestRequest,
  PaymentRequestRequestItem,
  PayObject,
} from '@/services/booksBackend/paymentRequest/interfaces/PaymentRequestRequest';
import { paymentRequest } from '@/services/booksBackend/paymentRequest/paymentRequestService';

import type { CheckoutRootState } from '..';

export interface PaymentRequestState {
  isLoading: boolean;
}

export const paymentRequestSelector = createSelector(
  (state: RootState) => state.inapp.checkout,
  (state: CheckoutRootState) => state.paymentRequest,
);

export const isPaymentRequestLoadingSelector = createSelector(
  paymentRequestSelector,
  (state: PaymentRequestState) => state.isLoading,
);

const startPaymentRequestAction = createAction<undefined>('inapp/checkout/startPaymentRequestAction');

export const paymentRequestAction = createAsyncThunk<
  { paymentFinished: false } | { paymentFinished: true; data: PaymentRequest },
  ActionRequest<
    ({ bookIds: string[] } | { seriesId: string }) & {
      orderType: 'purchase' | 'rental';
      checkout: {
        ridipointBalance: number;
        totalPrice: number;
        isPointback: boolean;
        coupons: Record<CheckoutCouponType, string | null>;
      };
      csrfToken: string;
    }
  >,
  {
    rejectValue: { message: string; isLoginFailure: boolean };
    state: RootState;
  }
>('inapp/checkout/paymentRequest/paymentRequestAction', async ({ req, reqParams }, thunkAPI) => {
  if (thunkAPI.getState().inapp.checkout.paymentRequest.isLoading) {
    return {
      paymentFinished: false,
    };
  }

  thunkAPI.dispatch(startPaymentRequestAction());

  const pointAmount = reqParams.checkout.isPointback
    ? 0
    : Math.min(reqParams.checkout.totalPrice, reqParams.checkout.ridipointBalance);

  const cashAmount = reqParams.checkout.totalPrice - pointAmount;

  let requestItem: PaymentRequestRequestItem;
  if ('seriesId' in reqParams) {
    requestItem = {
      series_id: reqParams.seriesId,
      object: reqParams.orderType === 'rental' ? PayObject.ENTIRE_RENTAL : PayObject.ENTIRE_PURCHASE,
    };
  } else {
    let payObject: NonEntirePayObjectValue = reqParams.orderType === 'rental' ? PayObject.RENTAL : PayObject.PURCHASE;
    if (reqParams.checkout.isPointback && reqParams.orderType === 'rental') {
      payObject = PayObject.POINTBACK_RENTAL;
    }

    requestItem = {
      b_ids: reqParams.bookIds,
      object: payObject,
    };
  }

  const body: PaymentRequestRequest['body'] = {
    ...requestItem,
    use_cash: cashAmount,
    use_point: pointAmount,
    _token: reqParams.csrfToken,
  };

  if (reqParams.checkout.coupons.discount) {
    body.discount_coupon_id = reqParams.checkout.coupons.discount;
  }

  if (reqParams.checkout.coupons.point_gift_card) {
    body.point_gift_card_coupon_id = reqParams.checkout.coupons.point_gift_card;
  }

  const [error, model] = await paymentRequest({ body }, req);

  if (error) {
    return thunkAPI.rejectWithValue({
      message: error.response?.data?.message ?? error.message,
      isLoginFailure: error.response?.status === 401,
    });
  }

  return {
    paymentFinished: true,
    data: model.Data,
  };
});

export const paymentRequestSlice = createSlice({
  name: 'inapp/checkout/paymentRequest',
  initialState: {
    isLoading: false,
  } as PaymentRequestState,

  reducers: {},
  extraReducers: builder => {
    builder.addCase(startPaymentRequestAction, state => {
      state.isLoading = true;
    });

    builder.addCase(paymentRequestAction.fulfilled, (state, action) => {
      if (action.payload.paymentFinished) {
        state.isLoading = false;
      }
    });

    builder.addCase(paymentRequestAction.rejected, state => {
      state.isLoading = false;
    });

    builder.addCase(hydrateAction, (state, action) => ({
      ...state,
      ...action.payload.inapp.checkout.paymentRequest,
    }));
  },
});
