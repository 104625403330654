import { useMutation } from 'react-query';

import type { ServerRequest } from '@/base/interfaces/ServerRequest';
import { fromReq } from '@/services/baseService';
import { requestWithRefreshAuth } from '@/utils/request';

export type CsrfTokenRequest = {
  req?: ServerRequest;
};

export type CsrfTokenResponse = {
  csrfToken: string;
};

export const postCsrfToken = async ({ req }: CsrfTokenRequest) => {
  const pathname = '/api/csrf-token';

  const config = fromReq(req)({
    method: 'POST',
    pathname,
  });

  const [error, response] = await requestWithRefreshAuth<Error, void>({
    ...config,
    host: process.env.NEXT_PUBLIC_BOOKS_BACKEND_API,
  });

  const csrfToken = (response?.headers as Record<string, string>)?.['x-csrf-token'];

  if (error || !csrfToken) {
    throw error || new Error('인증 토큰 발급에 실패했습니다.');
  }

  return { csrfToken };
};

export const useCsrfTokenMutation = () => {
  const { mutateAsync } = useMutation<CsrfTokenResponse, Error, CsrfTokenRequest>(postCsrfToken);

  return mutateAsync;
};
