import {
  Array as RArray,
  Literal as RLiteral,
  Number as RNumber,
  Record as RRecord,
  Static as RStatic,
  String as RString,
  Union as RUnion,
} from 'runtypes';

const ROauth2TokenActionNeedToChangePassword = RRecord({
  message: RString,
  need_to_change_password: RRecord({}),
});

const ROauth2TokenActionNeedToVerifyEmail = RRecord({
  message: RString,
  need_to_verify_email: RRecord({
    user_email: RString,
  }),
});

const ROauth2TokenAction = RUnion(ROauth2TokenActionNeedToChangePassword, ROauth2TokenActionNeedToVerifyEmail);

export const ROauth2Token = RRecord({
  access_token: RString,
  expires_in: RNumber,
  refresh_token: RString,
  refresh_token_expires_in: RNumber,
  scope: RString,
  token_type: RLiteral('Bearer'),
  actions: RArray(ROauth2TokenAction),
  user: RRecord({
    idx: RNumber,
  }),
});

export type Oauth2Token = RStatic<typeof ROauth2Token>;
export type Oauth2TokenAction = RStatic<typeof ROauth2TokenAction>;
export type Oauth2TokenActionNeedToChangePassword = RStatic<typeof ROauth2TokenActionNeedToChangePassword>;
export type Oauth2TokenActionNeedToVerifyEmail = RStatic<typeof ROauth2TokenActionNeedToVerifyEmail>;
