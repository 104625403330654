export const NAMESPACE = 'FIG';
export const ACCESS_TOKEN_KEY = 'ridi-at';
export const REFRESH_TOKEN_KEY = 'ridi-rt';
export const AUTO_LOGIN_KEY = 'ridi-al';
export const DEVICE_TYPE_KEY = 'user_device_type';
export const RIDI_APP_KEY = 'ridi_app';
export const RIDI_APP_VERSION_KEY = 'ridi_app_version';
export const RIDI_APP_THEME_KEY = 'ridi_app_theme';
export const RIDI_NAVIGATION_KEY = 'ridi_nav';
export const RIDI_AUTH_INFO_KEY = 'ridi_auth';
export const RIDI_FFID_KEY = 'ridi-ffid';
export const ADULT_EXCLUDE_KEY = 'adult_exclude';
export const ADULT_INCLUDE_KEY = 'adult_include';
export const PHP_SESSION_KEY = 'PHPSESSID';
export const LOGIN_TIME_KEY = 'login_time';
export const LOGOUT_TIME_KEY = 'logout_time';
export const DEAUTH_WATCHDOG_SERVER_TOKEN_KEY = 'deauth_watchdog_servertoken';
export const STORAGE_PREFIX = '__books__';
export const SERVER_METRICS = 'server_metrics';
export const RUID = 'ruid';
export const USE_GRID_BOOK_LISTING = 'use_grid';
export const FROM_ACCOUNT_MODIFY = 'from_account_modify'; // 탈퇴 페이지 (account/secede) 접근 시, 정보 변경 페이지애서 (account/modify) 접근한 경우만 방문을 허락하기 위한 쿠키 키
export const FINGERPRINT_KEY = 'fingerprint';
export const ADD_PWA_REQUESTED_KEY = 'add_pwa_requested';
export const REFERRAL_CODE = 'ridi-referral-event';
// book detail
export const INPAY = 'inpay';
export const RDT_INFO = '_rdt_info';

export const DEVICE_TYPE_IPOD = 'iPod';
export const DEVICE_TYPE_IPHONE = 'iPhone';
export const DEVICE_TYPE_IPAD = 'iPad';
export const DEVICE_TYPE_ANDROID = 'Android';
export const DEVICE_TYPE_ANDROID_TABLET = 'AndroidTab';
export const DEVICE_TYPE_PC = 'PC';
export const DEVICE_TYPE_WINDOWS_PHONE = 'WindowsPhone';
export const DEVICE_TYPE_WINDOWS_CE = 'WindowsCE';
export const DEVICE_TYPE_ETC_MOBILE = 'EtcMobileDevice';
export const DEVICE_TYPE_PAPER = 'PAPER';
export const DEVICE_TYPE_PAPER_LITE = 'PAPER Lite';
export const DEVICE_TYPE_PAPER_PRO = 'PAPER PRO';
export const DEVICE_TYPE_RIDIPAPER = 'RIDIPAPER';
export const DEVICE_TYPE_RIDIPAPER4 = 'RIDIPAPER 4';
