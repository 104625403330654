import {
  Array as RArray,
  Boolean as RBoolean,
  Dictionary as RDictionary,
  Literal as RLiteral,
  Number as RNumber,
  Record as RRecord,
  Static as RStatic,
  String as RString,
  Union as RUnion,
  Unknown as RUnknown,
} from 'runtypes';

export type StorageType = 'session' | 'local';

export const RInstantSearchHistory = RArray(RString);
export const RDeauthWatchdog = RRecord({
  version: RNumber,
  streams: RArray(RRecord({ key: RNumber, tokens: RArray(RString) })),
  table: RDictionary(RNumber, RString),
  userAgent: RString,
});
export const RInstantToastInfo = RRecord({
  success: RBoolean,
  message: RString,
  expireAt: RNumber.optional(),
  duration: RNumber.optional(),
});
export const RInstantAlertInfo = RRecord({
  message: RString,
  expireAt: RNumber.optional(),
});
export type DeauthWatchdog = RStatic<typeof RDeauthWatchdog>;
export const RScrollPositionTabItemsAB = RDictionary(RString, RString);

export const SESSION_STORAGE_KEYS = {
  SAVED_GROUP_SECTION_TAB_INFO_MAP: 'savedGroupSectionTabInfoMapSession',
  DEAUTH_WATCHDOG_SESSION: 'deauthWatchdogSession',
  SIGNUP_FORM_DATA: 'signupFormData',
  SIGNUP_SOCIAL_FORM_DATA: 'signupSocialFormData',
  NOTIFY_PASSWORD_CHANGE: 'notifyPasswordChange',
  SCROLL_POSITION_TAB_ITEMS_AB: 'scrollPositionTabItemsAB',
} as const;

export const LOCAL_STORAGE_KEYS = {
  SAVED_GROUP_SECTION_TAB_INFO_MAP: 'savedGroupSectionTabInfoMapLocal',
  CASHCHARGE_TOOLTIP_DISMISSED_AT: 'cashChargeTooltipDismissedAt',
  DEBUG_VIEWER_ENABLED: 'debugViewer',
  INSTANT_SEARCH_HISTORY: 'instantSearchHistory',
  INSTANT_SEARCH_HISTORY_ENABLED: 'instantSearchHistoryOption',
  DEAUTH_WATCHDOG: 'deauthWatchdog',
  LAST_LOGIN_SOCIAL_PLATFORM: 'lastLoginSocialPlatform',
  INSTANT_TOAST_INFO: 'instantToastInfo',
  INSTANT_ALERT_INFO: 'instantAlertInfo',
} as const;

export type RIDIStorageKey =
  | (typeof SESSION_STORAGE_KEYS)[keyof typeof SESSION_STORAGE_KEYS]
  | (typeof LOCAL_STORAGE_KEYS)[keyof typeof LOCAL_STORAGE_KEYS];

export const RIDIStorageTypes = {
  [SESSION_STORAGE_KEYS.SAVED_GROUP_SECTION_TAB_INFO_MAP]: RUnknown,
  [SESSION_STORAGE_KEYS.DEAUTH_WATCHDOG_SESSION]: RUnknown,
  [SESSION_STORAGE_KEYS.SIGNUP_FORM_DATA]: RUnknown,
  [SESSION_STORAGE_KEYS.SIGNUP_SOCIAL_FORM_DATA]: RUnknown,
  [SESSION_STORAGE_KEYS.NOTIFY_PASSWORD_CHANGE]: RBoolean,
  [SESSION_STORAGE_KEYS.SCROLL_POSITION_TAB_ITEMS_AB]: RScrollPositionTabItemsAB,
  [LOCAL_STORAGE_KEYS.SAVED_GROUP_SECTION_TAB_INFO_MAP]: RUnknown,
  [LOCAL_STORAGE_KEYS.CASHCHARGE_TOOLTIP_DISMISSED_AT]: RNumber,
  [LOCAL_STORAGE_KEYS.DEBUG_VIEWER_ENABLED]: RBoolean,
  [LOCAL_STORAGE_KEYS.INSTANT_SEARCH_HISTORY]: RInstantSearchHistory,
  [LOCAL_STORAGE_KEYS.INSTANT_SEARCH_HISTORY_ENABLED]: RBoolean,
  [LOCAL_STORAGE_KEYS.DEAUTH_WATCHDOG]: RDeauthWatchdog,
  /**
   * LAST_LOGIN_SOCIAL_PLATFORM, INSTANT_TOAST_INFO, INSTANT_ALERT_INFO는
   * books-backend에서도 사용하고 있는 키이므로, 수정 시 books-backend와의 호환성을 고려해야 합니다.
   */
  [LOCAL_STORAGE_KEYS.LAST_LOGIN_SOCIAL_PLATFORM]: RUnion(
    RLiteral('kakao'),
    RLiteral('naver'),
    RLiteral('google'),
    RLiteral('apple'),
  ),
  [LOCAL_STORAGE_KEYS.INSTANT_TOAST_INFO]: RInstantToastInfo,
  [LOCAL_STORAGE_KEYS.INSTANT_ALERT_INFO]: RInstantAlertInfo,
} as const;
