import constate from 'constate';
import { ComponentProps, useState } from 'react';

const usePopupLayerContext = () => {
  const [popupLayerElement, setPopupLayerElement] = useState(<></>);

  return {
    popupLayerElement,
    setPopupLayerElement,
  };
};

export const [PopupLayerContextProvider, usePopupLayerElement, useSetPopupLayerElement] = constate(
  usePopupLayerContext,
  value => value.popupLayerElement,
  value => value.setPopupLayerElement,
);

const PopupLayerContent = () => {
  const popupLayerElement = usePopupLayerElement();

  return popupLayerElement;
};

export const PopupLayerProvider = ({ children }: ComponentProps<'div'>) => (
  <PopupLayerContextProvider>
    <PopupLayerContent />
    {children}
  </PopupLayerContextProvider>
);
