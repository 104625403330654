import { AccountValidationModel } from '@/models/backendsApi/v1/AccountValidation/AccountValidationModel';
import { ServiceMethod } from '@/services/baseService';
import { getFeatureFlagValueByKey } from '@/utils/featureFlag';

import { makeCallAPIMethod } from '../../backendsApiService';
import { AccountValidationError } from './interfaces/AccountValidationError';
import { AccountValidationIdRequest } from './interfaces/AccountValidationIdRequest';

export const accountValidationId: ServiceMethod<
  AccountValidationIdRequest,
  AccountValidationModel,
  AccountValidationError
> = async (reqParams, req) => {
  const userId = reqParams.body.user_id;
  const call = makeCallAPIMethod(
    !!getFeatureFlagValueByKey({
      featureFlag: req?.FeatureFlag || reqParams?.featureFlag,
      featureFlagKey: 'web-internal-backendsapi-request-20240710',
    }),
  )(AccountValidationModel);

  return call<AccountValidationIdRequest, AccountValidationError, AccountValidationModel>(
    {
      method: 'POST',
      pathname: '/v1/account/validation/id',
      params: {
        body: { user_id: userId },
      },
      preferNonProxied: true,
    },
    req,
  );
};
