import { CartCountModel } from '@/models/booksBackend/Cart/CartCountModel';
import { ServiceMethod } from '@/services/baseService';
import { getFeatureFlagValueByKey } from '@/utils/featureFlag';

import { makeCallAPIMethod } from '../booksBackendService';

export const cartCount: ServiceMethod<void, CartCountModel, void> = async (_, req) => {
  const call = makeCallAPIMethod(
    !!getFeatureFlagValueByKey({
      featureFlag: req?.FeatureFlag,
      featureFlagKey: 'web-internal-books-backendsapi-request-20240813',
    }),
  )(CartCountModel);
  return call<void, void, CartCountModel>(
    {
      method: 'GET',
      pathname: '/api/cart/count',
      preferNonProxied: true,
      timeout: 5000,
    },
    req,
  );
};
