import { useEffect as g } from "react";
/*! js-cookie v3.0.5 | MIT */
function u(n) {
  for (var o = 1; o < arguments.length; o++) {
    var r = arguments[o];
    for (var a in r)
      n[a] = r[a];
  }
  return n;
}
var v = {
  read: function(n) {
    return n[0] === '"' && (n = n.slice(1, -1)), n.replace(/(%[\dA-F]{2})+/gi, decodeURIComponent);
  },
  write: function(n) {
    return encodeURIComponent(n).replace(
      /%(2[346BF]|3[AC-F]|40|5[BDE]|60|7[BCD])/g,
      decodeURIComponent
    );
  }
};
function h(n, o) {
  function r(t, i, e) {
    if (!(typeof document > "u")) {
      e = u({}, o, e), typeof e.expires == "number" && (e.expires = new Date(Date.now() + e.expires * 864e5)), e.expires && (e.expires = e.expires.toUTCString()), t = encodeURIComponent(t).replace(/%(2[346B]|5E|60|7C)/g, decodeURIComponent).replace(/[()]/g, escape);
      var c = "";
      for (var s in e)
        e[s] && (c += "; " + s, e[s] !== !0 && (c += "=" + e[s].split(";")[0]));
      return document.cookie = t + "=" + n.write(i, t) + c;
    }
  }
  function a(t) {
    if (!(typeof document > "u" || arguments.length && !t)) {
      for (var i = document.cookie ? document.cookie.split("; ") : [], e = {}, c = 0; c < i.length; c++) {
        var s = i[c].split("="), m = s.slice(1).join("=");
        try {
          var d = decodeURIComponent(s[0]);
          if (e[d] = n.read(m, d), t === d)
            break;
        } catch {
        }
      }
      return t ? e[t] : e;
    }
  }
  return Object.create(
    {
      set: r,
      get: a,
      remove: function(t, i) {
        r(
          t,
          "",
          u({}, i, {
            expires: -1
          })
        );
      },
      withAttributes: function(t) {
        return h(this.converter, u({}, this.attributes, t));
      },
      withConverter: function(t) {
        return h(u({}, this.converter, t), this.attributes);
      }
    },
    {
      attributes: { value: Object.freeze(o) },
      converter: { value: Object.freeze(n) }
    }
  );
}
var l = h(v, { path: "/" });
const f = "referralCode", p = "ridi-referral-event", R = /^https:\/\/((next|view|preflight)\.local\.)?ridibooks\.com.*$/;
async function C({
  referralEventData: n
}) {
  if (!globalThis.location)
    return;
  const o = new URL(globalThis.location.href), r = Object.fromEntries(o.searchParams.entries());
  let a = !1;
  if (n) {
    const t = new Date(n.eventStartAt), i = new Date(n.eventEndAt), e = t.getTime(), c = i.getTime();
    if (!Number.isNaN(e) && !Number.isNaN(c)) {
      const s = Date.now();
      a = !(e <= s && s <= c);
    }
  }
  if (a) {
    if (r[f] || l.get(p)) {
      const { [f]: t, ...i } = r;
      l.remove(p, {
        domain: ".ridibooks.com",
        path: "/",
        secure: !0
      });
      const e = `${o.pathname}?${new URLSearchParams(
        i
      ).toString()}`;
      globalThis.history.replaceState({}, "", e);
    }
    return;
  }
  if (R.test(globalThis.location.href) && (f in r && r[f] && l.set(p, r[f], {
    path: "/",
    domain: ".ridibooks.com",
    secure: !0
  }), l.get(p) && !(f in r))) {
    const t = `${o.pathname}?${new URLSearchParams({
      ...r,
      [f]: l.get(p)
    }).toString()}`;
    globalThis.history.replaceState({}, "", t);
  }
}
const x = (n, o) => {
  g(() => {
    C(n);
  }, [n.referralEventData, ...o]);
};
export {
  C as syncReferralCode,
  x as useSyncReferralCode
};
