import { BaseModel } from '@/models/BaseModel';

import { Oauth2Token, ROauth2Token } from './Oauth2TokenType';

export class Oauth2TokenModel extends BaseModel<Oauth2Token> {
  private headers: Record<string, unknown> = {};

  constructor(data: Oauth2Token, { headers = {} }: { headers?: Record<string, unknown> }) {
    super(data, ROauth2Token);
    this.headers = headers;
  }

  get Headers(): Record<string, unknown> {
    return this.headers;
  }
}
