import {
  Boolean as RBoolean,
  Literal as RLiteral,
  Number as RNumber,
  Record as RRecord,
  Static as RStatic,
  String as RString,
  Union as RUnion,
} from 'runtypes';

export const RAccountsMeAdultVerification = RRecord({
  status: RUnion(RLiteral('VERIFIED'), RLiteral('EXPIRED'), RLiteral('NOT_VERIFIED')),
  message: RString.optional(),
});

export const RAccountsMe = RRecord({
  result: RRecord({
    email: RString,
    id: RString,
    idx: RNumber,
    is_verified_adult: RBoolean,
    adult_verification: RAccountsMeAdultVerification,
  }),
});

export type AccountsMe = RStatic<typeof RAccountsMe>;
