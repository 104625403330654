import {
  Boolean as RBoolean,
  Literal as RLiteral,
  Number as RNumber,
  Optional as ROptional,
  Partial as RPartial,
  Record as RRecord,
  Static as RStatic,
  String as RString,
  Union as RUnion,
} from 'runtypes';

export const RPaymentRouteItem = RRecord({
  method: RUnion(RLiteral('GET'), RLiteral('POST')),
  link: RString,
  parameters: RString,
  is_api: RBoolean,
});

const RBookPaymentRouteInformation = RRecord({
  total_price: ROptional(RNumber),
  user_ridi_point: ROptional(RNumber),
  user_ridi_cash: ROptional(RNumber),
  usable_ridi_point: ROptional(RNumber),
  usable_ridi_cash: ROptional(RNumber),
  csrf_token_expiration_time: RNumber,
  is_double_point_period: ROptional(RBoolean),
  rent_days: ROptional(RNumber),
  available_coupon_count: ROptional(RNumber),
  remain_available_coupon_days: ROptional(RNumber),
  coupon_title: ROptional(RString),
});

export const RBookPaymentRouteItem = RPaymentRouteItem.And(
  RRecord({
    information: RBookPaymentRouteInformation.optional(),
  }),
);

export const RPaymentRouteBook = RPartial({
  // 캐시포인트 결제
  payment_book_cash_and_point: RBookPaymentRouteItem,
  // 캐시충전후 결제
  charge_cash_and_payment_book: RBookPaymentRouteItem,
  // 결제
  payment_book: RBookPaymentRouteItem,
  // 기다리면무료 결제
  wait_free: RBookPaymentRouteItem,
  // 카티고리 무료대여권 사용
  free_rent_in_category_coupon: RBookPaymentRouteItem,
  // 시리즈 무료대여권 사용
  free_rent_in_series_coupon: RBookPaymentRouteItem,
});

export type PaymentRouteBook = RStatic<typeof RPaymentRouteBook>;
export type PaymentRouteItem = RStatic<typeof RPaymentRouteItem>;
export type BookPaymentRouteItem = RStatic<typeof RBookPaymentRouteItem>;
