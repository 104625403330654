import { SearchModel } from '@/models/searchApi/Search/SearchModel';
import { ServiceMethod } from '@/services/baseService';

import { makeCallAPIMethod } from '../searchApiService';
import { SearchRequest } from './interfaces/SearchRequest';

const call = makeCallAPIMethod(SearchModel);

export const MAX_SEARCH_KEYWORD_LENGTH = 64;

export type SearchError = {
  code: string;
  message: string;
};

export const search: ServiceMethod<SearchRequest, SearchModel, SearchError> = async (reqParams, req) =>
  call<SearchRequest, SearchError, SearchModel>(
    {
      method: 'GET',
      pathname: '/search',
      params: {
        query: {
          ...reqParams.query,
          keyword: reqParams.query.keyword.slice(0, MAX_SEARCH_KEYWORD_LENGTH),
          site: 'ridi-store',
        },
      },

      preferNonProxied: true,
    },
    req,
  );
